import React from "react"

import { Button, ButtonType } from "../../../components/button/Button.component"
import { useRefCollection } from "../../../hooks/useRefCollection.hook"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import {
    AccessTierBundles,
    ClusterBundles,
    NetagentInstanceBundle,
    SupportBundlesRequest as Request,
    SupportBundleInformation as BundleInformation,
} from "../../../v3/services/MomOrgManagement.service"
import styles from "./SupportBundlesTable.module.scss"

export interface Api {
    downloadAll(): void
}

interface Props {
    request: Request
    onSupportBundleDownload(bundleInformation: BundleInformation): Promise<void>
}

export const SupportBundlesTable = React.forwardRef<Api, Props>((props, ref) => {
    const localization = useServiceLocalization()

    const { getAllRefContents, getRef } = useRefCollection<ClusterBundlesRowApi>()

    React.useImperativeHandle(ref, () => ({
        downloadAll: () =>
            getAllRefContents().forEach((clusterBundlesRow) => clusterBundlesRow.downloadAll()),
    }))

    return (
        <table className={styles.table}>
            <thead>
                <tr>
                    <th rowSpan={2} />
                    <th colSpan={2}>{localization.getString("component")}</th>
                    <th colSpan={3}>{localization.getString("supportBundle")}</th>
                </tr>
                <tr>
                    <th>{localization.getString("accessTier")}</th>
                    <th>{localization.getString("netagentInstance")}</th>
                    <th>{localization.getString("fileSize")}</th>
                    <th>{localization.getString("lastModified")}</th>
                    {/* To prevent layout shifts when other download buttons are loading */}
                    <th className={styles.downloadCell}>
                        <DownloadButton className={styles.invisibleButton} isLoading />
                    </th>
                </tr>
            </thead>
            <tbody>
                {props.request.clusterBundles.map((clusterBundles) => (
                    <ClusterBundlesRow
                        key={clusterBundles.clusterName}
                        clusterBundles={clusterBundles}
                        requestedAt={props.request.requestedAt}
                        onSupportBundleDownload={props.onSupportBundleDownload}
                        ref={getRef(clusterBundles.clusterName)}
                    />
                ))}
            </tbody>
        </table>
    )
})

SupportBundlesTable.displayName = "SupportBundlesTable"

interface ClusterBundlesRowApi {
    downloadAll(): void
}

interface ClusterBundlesRowProps {
    clusterBundles: ClusterBundles
    requestedAt: Date
    onSupportBundleDownload(bundleInformation: BundleInformation): Promise<void>
}

const ClusterBundlesRow = React.forwardRef<ClusterBundlesRowApi, ClusterBundlesRowProps>(
    (props, ref) => {
        const localization = useServiceLocalization()

        const shieldBundleRef = React.useRef<BundleInformationCellApi>(null)
        const { getAllRefContents, getRef } = useRefCollection<AccessTierBundlesRowApi>()

        React.useImperativeHandle(ref, () => ({
            downloadAll: () => {
                shieldBundleRef.current?.download()
                getAllRefContents().forEach((accessTierBundleRow) =>
                    accessTierBundleRow.downloadAll()
                )
            },
        }))

        const shieldCount = 1
        const totalNetagentInstanceCount = props.clusterBundles.accessTierBundles.reduce(
            addNetagentCountToTotal,
            0
        )

        return (
            <React.Fragment>
                <tr>
                    <td rowSpan={shieldCount + totalNetagentInstanceCount}>
                        {props.clusterBundles.clusterName}
                    </td>
                    <td colSpan={2}>{localization.getString("shield")}</td>
                    <BundleInformationCell
                        bundleInformation={props.clusterBundles.shieldBundle}
                        requestedAt={props.requestedAt}
                        onSupportBundleDownload={props.onSupportBundleDownload}
                        ref={shieldBundleRef}
                    />
                </tr>
                {props.clusterBundles.accessTierBundles.map((accessTierBundles) => (
                    <AccessTierBundlesRow
                        key={accessTierBundles.accessTierName}
                        accessTierBundles={accessTierBundles}
                        requestedAt={props.requestedAt}
                        onSupportBundleDownload={props.onSupportBundleDownload}
                        ref={getRef(accessTierBundles.accessTierName)}
                    />
                ))}
            </React.Fragment>
        )
    }
)

ClusterBundlesRow.displayName = "ClusterBundlesRow"

function addNetagentCountToTotal(total: number, accessTierBundles: AccessTierBundles): number {
    return total + accessTierBundles.netagentInstanceBundles.length
}

interface AccessTierBundlesRowApi {
    downloadAll(): void
}

interface AccessTierBundlesRowProps {
    accessTierBundles: AccessTierBundles
    requestedAt: Date
    onSupportBundleDownload(bundleInformation: BundleInformation): Promise<void>
}

const AccessTierBundlesRow = React.forwardRef<AccessTierBundlesRowApi, AccessTierBundlesRowProps>(
    (props, ref) => {
        const { getAllRefContents, getRef } =
            useRefCollection<NetagentInstanceBundleRowContentApi>()

        React.useImperativeHandle(ref, () => ({
            downloadAll: () =>
                getAllRefContents().forEach((netagentInstanceBundleRowContent) =>
                    netagentInstanceBundleRowContent.download()
                ),
        }))

        const netagentInstanceCount = props.accessTierBundles.netagentInstanceBundles.length
        const [firstNetagentInstanceBundle, ...restNetagentInstanceBundles] =
            props.accessTierBundles.netagentInstanceBundles

        return (
            <React.Fragment>
                <tr>
                    <td rowSpan={netagentInstanceCount}>
                        {props.accessTierBundles.accessTierName}
                    </td>
                    <NetagentInstanceBundleRowContent
                        netagentInstanceBundle={firstNetagentInstanceBundle}
                        requestedAt={props.requestedAt}
                        onSupportBundleDownload={props.onSupportBundleDownload}
                        ref={getRef(firstNetagentInstanceBundle.netagentName)}
                    />
                </tr>
                {restNetagentInstanceBundles.map((netagentInstanceBundle) => (
                    <tr key={netagentInstanceBundle.netagentName}>
                        <NetagentInstanceBundleRowContent
                            netagentInstanceBundle={netagentInstanceBundle}
                            requestedAt={props.requestedAt}
                            onSupportBundleDownload={props.onSupportBundleDownload}
                            ref={getRef(netagentInstanceBundle.netagentName)}
                        />
                    </tr>
                ))}
            </React.Fragment>
        )
    }
)

AccessTierBundlesRow.displayName = "AccessTierBundlesRow"

interface NetagentInstanceBundleRowContentApi {
    download(): void
}

interface NetagentInstanceBundleRowContentProps {
    netagentInstanceBundle: NetagentInstanceBundle
    requestedAt: Date
    onSupportBundleDownload(bundleInformation: BundleInformation): Promise<void>
}

const NetagentInstanceBundleRowContent = React.forwardRef<
    NetagentInstanceBundleRowContentApi,
    NetagentInstanceBundleRowContentProps
>((props, ref) => {
    const bundleInformationCellRef = React.useRef<BundleInformationCellApi>(null)

    React.useImperativeHandle(ref, () => ({
        download: () => bundleInformationCellRef.current?.download(),
    }))

    return (
        <React.Fragment>
            <td>{props.netagentInstanceBundle.netagentName}</td>
            <BundleInformationCell
                bundleInformation={props.netagentInstanceBundle.supportBundleInformation}
                requestedAt={props.requestedAt}
                onSupportBundleDownload={props.onSupportBundleDownload}
                ref={bundleInformationCellRef}
            />
        </React.Fragment>
    )
})

NetagentInstanceBundleRowContent.displayName = "NetagentInstanceBundleRowContent"

interface BundleInformationCellApi {
    download(): void
}

interface BundleInformationCellProps {
    bundleInformation?: BundleInformation
    requestedAt: Date
    onSupportBundleDownload(bundleInformation: BundleInformation): Promise<void>
}

const BundleInformationCell = React.forwardRef<
    BundleInformationCellApi,
    BundleInformationCellProps
>((props, ref) => {
    const buttonRef = React.useRef<DownloadButtonApi>(null)

    const localization = useServiceLocalization()
    const locale = localization.getLocale()

    const [isDownloading, setIsDownloading] = React.useState(false)

    React.useImperativeHandle(ref, () => ({
        download: () => buttonRef.current?.download(),
    }))

    if (!props.bundleInformation) {
        return (
            <td colSpan={3}>
                {localization.getString(
                    "waitingOnSupportBundleRequestedAtSomething",
                    props.requestedAt.toLocaleString(locale)
                )}
            </td>
        )
    }

    const { bundleInformation } = props

    const onClick: React.MouseEventHandler = async (event) => {
        event.preventDefault()
        setIsDownloading(true)

        try {
            await props.onSupportBundleDownload(bundleInformation)
        } finally {
            setIsDownloading(false)
        }
    }

    return (
        <React.Fragment>
            <td>{props.bundleInformation.fileSize.toFormattedString(locale)}</td>
            <td>{props.bundleInformation.lastModifiedAt.toLocaleString(locale)}</td>
            <td className={styles.downloadCell}>
                <DownloadButton onClick={onClick} isLoading={isDownloading} ref={buttonRef} />
            </td>
        </React.Fragment>
    )
})

BundleInformationCell.displayName = "BundleInformationCell"

interface DownloadButtonApi {
    download(): void
}

interface DownloadButtonProps {
    isLoading: boolean
    className?: string
    onClick?: React.MouseEventHandler
}

const DownloadButton = React.forwardRef<DownloadButtonApi, DownloadButtonProps>((props, ref) => {
    const localization = useServiceLocalization()

    const buttonRef = React.useRef<HTMLButtonElement & HTMLAnchorElement>(null)

    React.useImperativeHandle(ref, () => ({
        download: () => buttonRef.current?.click(),
    }))

    return (
        <Button
            buttonType={ButtonType.SECONDARY_SOLID}
            onClick={props.onClick}
            loading={props.isLoading}
            ref={buttonRef}
            className={props.className}
        >
            {localization.getString("downloadSomething", localization.getString("supportBundle"))}
        </Button>
    )
})
