import { BaseApi, PaginatedResponse, PaginationOptions } from "./Base.api"

export class MomApi extends BaseApi {
    public static SUPPORT_BUNDLES_REQUEST_NOT_FOUND = "SUPPORT_BUNDLES_REQUEST_NOT_FOUND" as const

    public getOrgs(): Promise<OrgRes[]> {
        return this.get("/api/v2/mom/org", {
            default: this.localization.getString("errorCouldNotFetchOrgs"),
        })
    }

    public getOrgById(orgId: string): Promise<OrgRes> {
        return this.get(`/api/v2/mom/org/${orgId}`, {
            default: this.localization.getString("errorCouldNotFetchOrg"),
        })
    }

    public addOrg(orgBody: OrgBody): Promise<OrgRes> {
        return this.post("/api/v2/mom/org", orgBody, {
            default: this.localization.getString("errorCouldNotAddOrg"),
            4006: this.localization.getString(
                "somethingNamedAlreadyExists",
                this.localization.getString("organization"),
                orgBody.org_name
            ),
            4018: this.localization.getString("invalidCustomerId"),
        })
    }

    public updateOrg(orgBody: OrgBody): Promise<OrgRes> {
        return this.put("/api/v2/mom/org", orgBody, {
            default: this.localization.getString("errorCouldNotEditOrg"),
            4022: this.localization.getString("editionDowngradeIsNotSupported"),
            4017: this.localization.getString("orgIsProvisioningPleaseWait"),
            4018: this.localization.getString("invalidCustomerId"),
        })
    }

    public deleteOrg(orgId: string): Promise<void> {
        return this.delete(
            `/api/v2/mom/org/${encodeURIComponent(orgId)}`,
            {},
            {
                default: this.localization.getString("errorCouldNotDeleteOrg"),
            }
        )
    }

    public getDeletedOrgs(
        params: PaginationOptions
    ): Promise<PaginatedResponse<"deleted_orgs", DeletedOrgRes>> {
        const urlParams: URLSearchParams = new URLSearchParams(params as any)
        const url = `/api/v2/mom/deleted_orgs?${urlParams.toString()}`
        return this.get(url, {
            default: this.localization.getString("errorCouldNotFetchOrgs"),
        })
    }

    public getLicenseInformationByOrgId(orgId: string): Promise<LicenseInformationRes | null> {
        return this.get(`/api/v2/mom/org/${orgId}/licenses`, {
            default: this.localization.getString("failedToGetOrgLicense"),
        })
    }

    public updateLicenseInformationByOrgId(
        orgId: string,
        licenseInformation: UpdateLicenseInformationReq
    ): Promise<LicenseInformationRes> {
        return this.put(`/api/v2/mom/org/${orgId}/licenses`, licenseInformation, {
            default: this.localization.getString("failedToUpdateOrgLicense"),
        })
    }

    public updateOrgSettings(
        orgId: string,
        orgSettingsBody: OrgSettingsBody
    ): Promise<OrgSettingsRes> {
        return this.put(`/api/v2/mom/org/${orgId}/settings`, orgSettingsBody, {
            default: this.localization.getString("errorCouldNotEditOrg"),
        })
    }

    public getOrgSupportBundles(orgId: string, requestId: string): Promise<SupportBundlesRes> {
        return this.get(`/api/v2/mom/org/${orgId}/support_bundles/${requestId}`, {
            404: MomApi.SUPPORT_BUNDLES_REQUEST_NOT_FOUND,
            default: this.localization.getString(
                "failedToGetSomething",
                this.localization.getString("supportBundles")
            ),
        })
    }

    public async requestOrgSupportBundles(orgId: string): Promise<SupportBundlesRes> {
        return this.post(`/api/v2/mom/org/${orgId}/support_bundles`, undefined, {
            default: this.localization.getString(
                "failedToRequestSomething",
                this.localization.getString("supportBundles")
            ),
        })
    }

    public async getSupportBundleFileUrl(
        orgId: string,
        requestId: string,
        fileName: string
    ): Promise<SupportBundleFileUrlRes> {
        return this.get(`/api/v2/mom/org/${orgId}/support_bundles/${requestId}/files/${fileName}`, {
            default: this.localization.getString(
                "failedToGetSomething",
                this.localization.getString("fileName")
            ),
        })
    }

    public assignMspOrg(query: AttachMspOrgQuery): Promise<void> {
        const params = new URLSearchParams({ ...query })

        return this.post(`api/v2/mom/msp/child_org?${params.toString()}`, undefined, {
            default: this.localization.getString(
                "failedToAssignSomething",
                this.localization.getString("mspOrg")
            ),
        })
    }

    public removeMspOrgAssignment(query: DetachMspOrgQuery): Promise<void> {
        const params = new URLSearchParams({ ...query })

        return this.delete(`api/v2/mom/msp/child_org?${params.toString()}`, undefined, {
            default: this.localization.getString(
                "failedToRemoveSomething",
                this.localization.getString("mspOrgAssignment")
            ),
        })
    }
}

export interface OrgBody {
    org_name: string
    first_name?: string
    last_name?: string
    email?: string
    edition?: EditionRes
    type?: TypeRes
    customer_id?: string
    global_edge?: boolean
    private_edge?: boolean
    is_ai_assist_enabled: boolean
    is_appdiscovery_enabled: boolean
    archived: boolean
    banyan_idp?: boolean
    is_msp_org: boolean
    is_dns_filter_enabled: boolean
    is_urlfiltering_enabled: boolean
    internal_owner?: string | null
    archive_at?: number | null
}

export interface OrgRes {
    status?: StatusRes
    org_name: string
    org_id: string
    first_name: string
    last_name: string
    email: string
    edition: EditionRes
    type: TypeRes
    last_updated_at: number
    customer_id: string
    global_edge: boolean
    private_edge: boolean
    is_ai_assist_enabled: boolean
    is_dns_filter_enabled: boolean
    is_urlfiltering_enabled: boolean
    is_msp_org: boolean
    is_appdiscovery_enabled: boolean
    is_mysonicwall: boolean
    created_at: number
    created_by: string
    last_updated_by: string
    banyan_idp: boolean
    parent_org_id?: string
    provision_status: {
        access_tier?: AccessTierStatusRes
        shield?: ShieldStatusRes
        user_pool?: ProvisionStatusRes
        org_data?: ProvisionStatusRes
        registered_domain?: ProvisionStatusRes
        connector?: ProvisionStatusRes
        invite_code?: ProvisionStatusRes
        user_pool_domain?: ProvisionStatusRes
        user?: ProvisionStatusRes
        default_groups_user?: ProvisionStatusRes
        user_pool_client?: ProvisionStatusRes
        default_groups_user_pool?: ProvisionStatusRes
        update_user_pool?: ProvisionStatusRes
        invite_admin_user?: ProvisionStatusRes
        private_edge_shield?: ProvisionStatusRes
        [key: string]: ProvisionStatusRes | AccessTierStatusRes | undefined
    }
    internal_owner: string | null
    archive_at: number | null
}

export interface DeletedOrgRes {
    admin_details: string
    created_at: number
    customer_id: string
    deleted_at: number
    deleted_by: string
    deleted_reason: string
    edition: EditionRes
    id: string
    org_id: string
    org_name: string
    type: TypeRes
}

export type DeleteResonRes = "MOMDelete" | "AutoDelete" | "MSWDelete"
export type EditionRes = "Team" | "Enterprise" | "Unlimited"

export type TypeRes =
    | "Production"
    | "Trial"
    | "Staging"
    | "InternalTemporary"
    | "InternalPersistent"
    | string

export type StatusRes = "Success" | "PartialSuccess" | "InProgress" | "Failed"

export type ProvisionStatusRes = "Success" | "Failed" | "Archived" | "Pending" | "Deleted"

export type AccessTierStatusRes =
    | "Pending"
    | "Provisioning"
    | "ProvisioningFailed"
    | "DNSEntriesFailed"
    | "Success"
    | "Failed"
    | "Deleted"
    | "DeletionFailed"

export type ShieldStatusRes =
    | "Pending"
    | "Provisioning"
    | "ProvisioningFailed"
    | "Success"
    | "Failed"
    | "Deleted"
    | "DeletionFailed"

export interface LicenseInformationRes {
    spa: LicenseTypeRes
    sia: LicenseTypeRes
    spa_users: number
    sia_users: number
    spa_overage_count: number
    sia_overage_count: number
}

export interface UpdateLicenseInformationReq {
    spa: LicenseTypeRes
    sia: LicenseTypeRes
    spa_users: number
    sia_users: number
    spa_overage_count?: number
    sia_overage_count?: number
}

export type LicenseTypeRes = "None" | "Basic" | "Advanced"

export interface OrgSettingsBody {
    archive_at: number
}

export interface OrgSettingsRes {
    archive_at: number
}

export interface SupportBundlesRes {
    id: string
    status: SupportBundlesRequestStatusRes
    created_at: string
    bundles?: Record<string, SupportBundleRes>
}

export type SupportBundlesRequestStatusRes = "pending" | "partial" | "ready"

export interface SupportBundleRes {
    shield: SupportBundleFileRes | null
    access_tiers: Record<string, AccessTierBundlesRes>
}

export type AccessTierBundlesRes = Record<string, SupportBundleFileRes | null>

export interface SupportBundleFileRes {
    file: string
    last_modified: string
    size: number
}

interface SupportBundleFileUrlRes {
    url: string
}

export interface AttachMspOrgQuery {
    msp_org_id: string
    child_org_id: string
}

export interface DetachMspOrgQuery {
    msp_org_id: string
    child_org_id: string
}
