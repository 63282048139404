export const enUSLanguage = {
    "20Minutes": "20 Minutes",
    ALL: "ALL",
    DENY: "DENY",
    KeyExpectedValue: "Key & Expected Value",
    aBackendDNSOverrideIsRequiredForKubernetesServices:
        "A backend DNS override is required for Kubernetes Services",
    aBackendDomainNameIsRequired: "A Backend Domain Name or IP address is required.",
    aBackendPortIsRequired: "A Backend Port is required.",
    aCaPublicKeyIsRequiredForKubernetesServices:
        "A CA Public Key is required for Kubernetes services.",
    aConnectorIsRequired: "A connector is required",
    aCorsExemptionMustHaveAValidOriginDomain: "A CORS exemption must have a valid origin domain.",
    aCorsExemptionMustSpecifyMethods: "A CORS exemption must specify methods.",
    aDeletionWasRequestedForThisOrg: "A deletion was requested for “{0}”.",
    aDeviceCanOnlyBeAssignedOneTrustProfile:
        "A device can only be assigned one Trust Profile. Device Trust Profiles must be ordered by priority in case one device matches multiple Trust Profiles.",
    aDomainNameAndPortAreRequired: "A Domain name and port are required",
    aPolicyWithThatNameAlreadyExists: "A policy with that name already exists",
    aPropertyListFileIsUsedToConfigureSystemAndApplicationsSettings:
        "A Property List file (.plist) is used to configure system and applications settings, commonly used on macOS. Use a Property List factor to validate a system setting or an application configuration. This factor can be configured to check for the existence of a .plist key. It can also verify whether a key matches a specific value.",
    aRoleIsRequired: "A role is required.",
    aServiceTypeIsRequired: "A service type is required.",
    aServiceWithLetsEncryptEnabledCannotHaveWildcards:
        "A service with Let's Encrypt enabled cannot have wildcards.",
    aSiteAccessTierIsRequired: "A site (Access Tier) is required.",
    aTrustLevelIsRequired: "A trust level is required",
    about: "About",
    accessActivities: "Access Activities",
    accessActivity: "Access Activity",
    accessDeniedApiEndpointIsInvalid: "Access denied, Api Endpoint is invalid",
    accessDeniedApiKeyIsInvalid: "Access denied, Api Key is invalid",
    accessDeniedIdIsInvalid: "Access denied, Id is invalid",
    accessDeniedSecretIsInvalid: "Access denied, Secret is invalid",
    accessDetails: "Access Details",
    "accessGroup#": "Access Group #",
    accessGroupCidrPlaceholder: "ex: * or 100.100.100.100/24",
    accessGroupCidrPlaceholderNoWildcard: "ex: 100.100.100.100/24",
    accessGroupPortPlaceholder: "ex: * or 22-50",
    accessGroups: "Access Groups",
    accessKey: "Access Key",
    accessLog: "Access Log",
    accessPolicies: "Access Policies",
    accessTier: "Access Tier",
    accessTierConnectionText: "[ {0} ]({1}) ({2})",
    accessTierConnectionTextAndMore: "[ {0} ]({1}) ({2}) and more...",
    accessTierFullyOperationalAndReporting: "Access Tier is fully operational and reporting",
    accessTierGroup: "Access Tier Group",
    accessTierGroupInfoBanner:
        "Once you attach an Access Tier Group, the Access Tier will inherit network settings from the Access Tier group. These cannot be edited as they are inherited from the Group Level Network Settings.",
    accessTierGroups: "Access Tier Groups",
    accessTierIPAddress: "Access Tier IP Address",
    accessTierIsReachableOnSelectedIpAndPort: "Access Tier is reachable on selected IP and Port",
    accessTierName: "Access Tier Name",
    accessTierNameDesc:
        "We recommend you choose a name that identifies the network the Access Tier will be installed on.",
    accessTierOutOfDateDescription:
        "This feature requires the Access Tier to be on v1.37.0 or greater. Please upgrade your Access Tier(s) and try again.",
    accessTierSuccessfullyInstalledMessage:
        "The Access Tier status is successfully **Reporting**. You can hit Continue to proceed to the next step.",
    accessTierUpgradeDesc:
        "Your organization is using the SonicWall CSE Global Edge Network. Upgrade to deploy and configure Self-hosted Access Tiers. Click [here](https://docs.banyansecurity.io/docs/intro/concepts/what-is-banyan/#deployment-models) to learn about deployment models",
    accessTiers: "Access Tiers",
    accessTiersOrAccessTierGroup: "Access Tiers or Access Tier Group",
    account: "Account",
    accountNumber: "Account Number",
    action: "Action",
    "action(s)": "Action(s)",
    actions: "Actions",
    active: "Active",
    activeConnections: "Active Connections",
    activeLicense: "Active License",
    activeServiceTunnel: "Active Service Tunnel",
    activeThreatRemediationText:
        "{0} has evaluated this device is under an Active Threat. Please reach out to support.",
    activeUsers: "Active Users",
    activity: "Activity",
    add: "Add",
    addAConfigurationToAtLeastOneAvailablePlatform:
        "Add a Configuration to at least one Available Platform",
    addADataLossProtectionDesc:
        "Add a data loss protection policy to the resource to allow/block certain actions.",
    addAFileToEnableTheFileCheckTrustFactor: "Add a File to enable the File Check Trust Factor",
    addAPropertyListToEnableThePropertyListCheckTrustFactor:
        "Add a Property List to enable the Property List Check Trust Factor",
    addARegistryKey: "Add A Registry Key",
    addARegistryKeyToEnableTheRegistryCheckTrustFactor:
        "Add a Registry Key to enable the Registry Check Trust Factor",
    addAccessGroup: "Add Access Group",
    addAccessTiersToYourGroups: "Add Access Tiers to your groups",
    addAdmin: "Add Admin",
    addAdminLocalDescription:
        "Your organization has been configured for Single Sign On using SAML 2.0 - you should provision access for new admins using your Identity Provider dashboard. Use this form to create a LOCAL admin account for audit or automation purposes.",
    addAll: "Add all",
    addAnAdmin: "Add an Admin",
    addAnOrganization: "Add an Organization",
    addAnotherRow: "Add Another Row",
    addApiKey: "Add API Key",
    addApp: "Add App",
    addApplication: "Add Application",
    addApplicationCheck: "Add Application Check",
    addApplicationCheckDescription:
        "Devices running this Application get a Trust Level boost. Not running this Application lowers a Device's Trust Level and may result in restricted access.",
    addAtLeastOneExemptionRuleBeforeContinuing:
        "Add at least one exemption rule before continuing.",
    addBundle: "Add Bundle",
    addCluster: "Add Cluster",
    addClusterExplanation:
        "SonicWall CSE manages your organization's cluster(s). See our docs for more information.",
    addCorsExemption: "Add CORS Exemption",
    addDomain: "Add Domain",
    addEditExemption: "Add/Edit Exemption",
    addEmailAddress: "Add Email Address(es)",
    "addExceptions(": "Add exceptions (",
    addExemption: "Add Exemption",
    addExemptionRule: "Add Exemption Rule",
    addFile: "Add File",
    addFilter: "Add Filter",
    addGroups: "Add Groups",
    addHostedWebsite: "Add Hosted Website",
    addIntegration: "Add Integration",
    addMethod: "Add Method",
    addNetwork: "Add Network",
    addNetworkThatWillBeAccessibleViaThisTunnel:
        "Add a network that will be accessible via this Service Tunnel.",
    addNewValue: "Add New Value",
    addNotes: "Add Notes",
    addOrg: "Add Org",
    addPropertyList: "Add Property List",
    addRegisteredDomain: "Add Registered Domain",
    addRegisteredDomainDescription:
        "Register your domains to simplify service registration and to verify connectivity between your domains and the deployed Access Tiers.",
    addRegistryKey: "Add Registry Key",
    addRole: "Add Role",
    addRoleAttribute: "Add Role Attribute",
    addRootCaCertificate: "Add Root CA Certificate",
    addRuleDescription:
        "Create one or more exemption rules. To learn more read our [Help Docs]({0}).",
    addSerialNumber: "Add Serial Number",
    addServiceAccount: "Add Service Account",
    addServiceBundle: "Add Service Bundle",
    addServiceTunnel: "Add Service Tunnel",
    addSomething: "Add {0}",
    addTemplate: 'Add "{0}"',
    addTheseDNSRecordsForYourDomain: "Add these DNS records for your domain.",
    addTrustFactor: "Add Trust Factor",
    addTrustFactors: "Add Trust Factors",
    addTrustFactorsDescription:
        "Trust Factors added to a Trust Profile will be used to determine the security posture of registered devices. Added Trust Factors (and their Effect) will only apply to this Trust Profile.",
    addUser: "Add User",
    additionalConfigurationsOptional: "Additional Configurations (Optional)",
    address: "Address",
    addresses: "Addresses",
    admin: "Admin",
    adminAccessToServerDesc:
        "Admin access to a server located on your internet-facing DMZ with a public IP address (so that it can be reached from anywhere on the internet).",
    adminConsole: "Admin Console",
    adminCreated: "Admin created.",
    adminEmail: "Admin Email",
    adminManagedBypassDomains: "Admin Managed Bypass Domains",
    adminOfAction: "Admin of the action",
    adminRolePermissions: "Admin Role Permissions",
    adminSettings: "Admin Settings",
    adminSignOn: "Admin Sign-on",
    admins: "Admins",
    advanced: "Advanced",
    advancedConfigurationOptional: "Advanced Configuration (optional)",
    advancedSettings: "Advanced Settings",
    advancedSettingsDescription:
        "SonicWall CSE recommends default settings for the Access Tier. To override the default settings, select the Custom option. Please check [this document]({0}) for more details.",
    afterReviewingConfigurationClickFinishMessage:
        "After reviewing configuration, click 'Finish'. Once deployment status completes in console, power on the VM and launch the remote console, then press Enter to boot.",
    aiAssistedSmartSearch: "AI Assisted Smart Search",
    all: "All",
    allAccessTiers: "All Access Tiers",
    allApplications: "All Applications",
    allConnectors: "All Connectors",
    allDevices: "All Devices",
    allFilters: "All Filters",
    allPolicies: "All Policies",
    allServices: "All Services",
    allSources: "All Sources",
    allStatuses: "All Statuses",
    allUsersDescription:
        'Note: This user will be automatically placed in the "AllUsers" role as well as any roles selected below.',
    allow: "Allow",
    allowList: "Allow List",
    allowOnlyDevicesWithACrowdStrikeZtaScoreOf65OrHigher:
        "Allow only devices with a CrowdStrike ZTA Score of 65 or higher",
    allowOnlyDevicesWithACrowdStrikeZtaScoreOf75OrHigher:
        "Allow only devices with a CrowdStrike ZTA Score of 75 or higher",
    allowOrganizationsOtherSecurityDesc:
        "Allow an organization’s other security tools to be checked while evaluating a device’s Trust Level.",
    allowUnregisteredDevicesToAccessServices: "Allow Unregistered Devices to Access Services",
    allowUnregisteredDevicesToAccessServicesDescription:
        "Connections from these CIDR ranges will be accepted and forwarded to the Identity Provider for user authentication. The generated Trust Token will not have any device claims.",
    allowUnregisteredDevicesToReceiveAnHttpResponse:
        "Allow Unregistered Devices to Receive an HTTP Response",
    allowUnregisteredDevicesToReceiveAnHttpResponseDescription:
        "Connections from these CIDR ranges will be accepted and receive an HTTP response.",
    allowedBackendCidrRange: "Allowed Backend CIDR range (ex. 10.10.34.0/24)",
    allowedBackendHostname: "Allowed Backend Hostname (ex. backend.example.com)",
    almostDone: "Almost Done",
    alwaysAllow: "Always Allow",
    alwaysDeny: "Always Deny",
    alwaysDenyDescription:
        "If this Factor is not satisfied, the device will be denied authorization into all SonicWall CSE services.",
    anErrorOccurredPleaseTryAgainLater: "An error occurred. Please try again later.",
    anErrorOccurredWhileResolvingAnAccessTierHost:
        "An error occurred while resolving an Access Tier host",
    anErrorOccurredWhileSendingAnHttpRequest: "An error occurred while sending an HTTP request",
    anIntegrationWithThisPartnerAlreadyExistsUpdateOrDeleteThatIntegration:
        "An integration with this partner already exists. Update or delete that integration.",
    anOptionalFieldShownInTheWebConsoleToAdminUsersOnlyForYourOwnInternalNotes:
        "An optional field shown in the web console to Admin users only, for your own internal notes.",
    android: "Android",
    androidOsVersionFormat: "Android OS version format: major.minor.build",
    androidOsVersionPlaceholder: "e.g., 12",
    any: "Any",
    anyTrustLevelExceptAlwaysDeny: "Any Trust Level except 'Always Deny'",
    apiCredentials: "API Credentials",
    apiEndpoint: "API Endpoint",
    apiEndpointPlaceholder: "ex. https://api.crowdstrike.com",
    apiHostUrl: "API Host URL",
    apiKey: "API Key",
    apiKeyCreated: "API Key created.",
    apiKeyDesc:
        "It seems you have multiple API Keys with the access_tier scope. Please select the key you want to use for this installation.",
    apiKeyExpiration: "API Key Expiration",
    apiKeyName: "API Key Name",
    apiKeyNotFound: "API Key not found.",
    apiKeyScopeFound: "API Key Scope not found",
    apiKeySecret: "API Key Secret",
    apiKeySettings: "API Key Settings",
    apiKeyUpdateSuccessful: "API Key update successful.",
    apiKeys: "API Keys",
    apiToken: "API Token",
    apiTokenCreatedAt: "Api Token(s) Created At",
    apiTokenDesc: "Copy API Token and paste into IDP config",
    app: "App",
    appClientForExternalServices: "App Client for External Services",
    appClientForPasswordlessAuthentication: "App Client for Passwordless Authentication",
    appClientName: "App Client Name",
    appClientNameCSEDescription:
        "Enter the CSE app client name you configured with your Identity Provider (e.g. CSE TrustProvider)",
    appClientNameDeviceDescription:
        "Enter the CSE app client name you configured with your Identity Provider (e.g. CSE DeviceRegistrationProvider)",
    appDeployment: "App Deployment",
    appDiscovery: "App Discovery",
    appFiltering: "App Filtering",
    appMessaging: "App Messaging",
    appName: "App Name",
    appNotFound: "App not found.",
    appSupportLink: "App Support Link",
    appSupportLinkDescription: "A URL for the “Get Help” link in the CSE App",
    appSupportLinkTitle: "App Support Link Title",
    appSupportLinkTitleDescription:
        "A custom message that endusers can see in the CSE App when they hover on the link.",
    appSupportMessage: "App Support Message",
    appSupportMessageDescription:
        "A custom message that endusers can see in the CSE App when they need help.",
    appVersion: "App Version",
    appendRdpFileProperties: "Append RDP File Properties (optional)",
    applicableOperatingSystems: "Applicable Operating Systems",
    applicablePlatform: "Applicable Platform",
    application: "Application",
    applicationCheck: "Application Check",
    applicationCheckDescription: "Satisfied if the applications are running on the device",
    applicationCheckFactorDescription:
        "The apps added below will trigger assigned devices to look for the running process of the app. If the process is left blank for an operating system, that operating system will not check for the app.",
    applicationCheckFactorMandatoryDescription:
        "Applying Mandatory overrides the Trust Level of the device and sets it to ‘Always Deny’ when the app is not found on the device.",
    applicationCheckFactorProcessNamesDescription:
        "Process names can be matched using regex if the input starts and ends with a forward slash (ex. /regex/). Process name matching is case-insensitive.",
    applicationCheckProcessDescription:
        "The **Application Check** factor looks for a running process on each configured operating system. Optionally, process names can be matched using regex if the input starts and ends with a forward slash (ex. /regex/). Process name matching is case-insensitive.",
    applicationCheckRemediationText: "Please install and run the applications listed above. {0}",
    applicationDetails: "Application Details",
    applicationExceptions: "Application Exceptions",
    applicationFiltering: "Application Filtering",
    applicationName: "Application Name",
    appliedPlatform: "Applied Platform",
    apply: "Apply",
    applyThisTrustFactorToTheFollowingDeviceCategories:
        "Apply this Trust Factor to the following Device categories:",
    apps: "Apps",
    architecture: "Architecture",
    archivalDate: "Archival Date",
    archivalDateCannotBeIncrementedBy365Days:
        "Archival date cannot be incremented by more than 365 days",
    archive: "Archive",
    archiveOrg: "Archive Org",
    archived: "Archived",
    areYouSureToConvertServiceToJson:
        "Are you sure you want to convert this service to Custom JSON?",
    areYouSureYouWantToArchiveThisOrg:
        "Archiving an org de-provisions all Shields and managed Access Tiers. Are you sure you want to archive this org?",
    areYouSureYouWantToConfigureSomething:
        "Are you sure you want to configure {0}?\n\nWarning: This is a one way change from SonicWall CSE local user management.\nAll existing admins will be converted to local administrators.",
    areYouSureYouWantToDeleteSomething: 'Are you sure you want to delete "{0}"?',
    areYouSureYouWantToDeleteTheApplication:
        "Are you sure you want to delete the application: {0}?",
    areYouSureYouWantToDeleteTheFollowingDomain:
        "Are you sure you want to delete the following domain?",
    areYouSureYouWantToDeleteThisApplicationCheck:
        "Are you sure you want to delete this Application Check?",
    areYouSureYouWantToDeleteThisIntegration: "Are you sure you want to delete this integration?",
    areYouSureYouWantToDeleteThisItpPolicy: "Are you sure you want to delete this ITP policy?",
    areYouSureYouWantToDeleteThisOrg: "Are you sure you want to delete this org?",
    areYouSureYouWantToDeleteThisRole: "Are you sure you want to delete this role?",
    areYouSureYouWantToDeleteThisToken:
        "Are you sure you want to delete this token? You cannot undo this action.",
    areYouSureYouWantToPerformThisAction: "Are you sure you want to perform this action?",
    areYouSureYouWantToRemoveSomethingFromYourNetwork:
        'Are you sure you want to remove "{0}" from your network settings?',
    areYouSureYouWantToRemoveThisDevice:
        "Are you sure you want to remove this Device? You cannot undo this action.",
    askSomething: 'Ask "{0}"',
    assignToAnMsp: "Assign to an MSP",
    assignToMspConfirmationMessage: "Are you sure you want to assign this Organization to {0}?",
    assignedListenPortDescription:
        "Assigned Listen Port (if port is in use or left blank, a random port will be used)",
    assignedOrgs: "Assigned Orgs",
    assignment: "Assignment",
    assignmentSettings: "Assignment Settings",
    attachAPolicy: "Attach a Policy",
    attachAPolicyOptional: "Attach a policy (optional):",
    attachAPolicyRequired: "Attach a policy (required):",
    attachARole: "Attach a Role",
    attachAccessTiers: "Attach Access Tiers",
    attachAnAccessTier: "Attach an Access Tier",
    attachPolicy: "Attach Policy",
    attachServiceAccount: "Attach Service Account",
    attachToAnAccessTierGroup: "Attach to an Access Tier Group",
    attachToAnAccessTierGroupDescription:
        "Network and Advanced Settings fields are inherited and need to be edited at the Access Tier Group level when selected.",
    attachToGroup: "Attach to Group",
    attachedOn: "Attached On",
    attributeDoesNotExist: "Attribute {0} does not exist.",
    audience: "Audience",
    audienceUriDescription: "Audience URI (Service Provider Entity ID)",
    authDomain: "Auth Domain",
    authProtocol: "Auth Protocol",
    authenticationFederation: "Authentication Federation",
    authenticationTokensAndCertificates: "Authentication Tokens and Certificates",
    authenticationTokensAndCertificatesDesc:
        "Configure how often the CSE App re-authenticates end-user session.",
    authorizationEndpoint: "Authorization Endpoint",
    authorizationHeader: "Authorization Header",
    authorizedAccess: "Authorized Access",
    autoUpdate: "Auto Update",
    autoUpdateDescription: "Satisfied if enabled on the device",
    autoUpdateLinuxRemediationText:
        "Please follow the steps to enable automatic updates for your Linux device. {0}",
    autoUpdateMacOsRemediationText:
        "Please follow the steps to enable automatic updates for your macOS device. {0}",
    autoUpdateWindowsRemediationText:
        "Please follow the steps to enable automatic updates for your Windows device. {0}",
    available: "Available",
    availableAccessTiers: "Available Access Tiers",
    aws: "AWS",
    azure: "Azure",
    back: "Back",
    backToParentConsole: "Back to {0} Console",
    backend: "Backend",
    backendAllowedHostnamesAndCIDRRanges: "Backend Allowed Hostnames and CIDR Ranges (optional)",
    backendCidrs: "Backend CIDRs",
    backendDNSOverrideForServiceDomainName: "Backend DNS Override for Service Domain Name",
    backendDNSOverrideForServiceDomainNameOptional:
        "Backend DNS Override for Service Domain Name (optional)",
    backendDomain: "Backend Domain",
    backendDomainExplanation:
        "The backend domain represents where this service is hosted. All accepted incoming requests will be proxied to this domain.",
    backendDomainName: "Backend Domain Name",
    backendDomainPort: "Backend Domain Port",
    backendHostAndPortReachableFromAccessTier:
        "Backend host and port are reachable from Access Tier",
    backendHostnameCIDRExplanation:
        "Restrict backend connectivity to the specified hostnames or CIDR ranges",
    backendHostnameOrIpIsResolvable: "Backend Hostname or IP is resolvable",
    banned: "Banned",
    bannedStatus: "Banned Status",
    baseUrl: "Base URL",
    baseUrlDesc: "Copy Base URL and paste into IDP config",
    baseUrlDescription:
        "This URL will be configured on your Identity Provider as the connector base URL",
    basic: "Basic",
    basicAuthorizationPolicyForWorkloads: "Basic Authorization Policy for Workloads",
    beginMigration: "Begin Migration",
    block: "Block",
    blockPageExperience: "Block Page Experience",
    blockedAttempt: "Blocked Attempt",
    blockedAttempts: "Blocked Attempt",
    brandingAndMessageCustomization: "Branding and Message Customization",
    browser: "Browser",
    browserErrorMessaging: "Browser Error Messaging",
    browserPreview: "Browser Preview",
    bulkConnect: "Bulk Connect",
    bundleName: "Bundle Name",
    bundles: "Bundles",
    by: "by",
    byConnectingTheIaasProvidersDesc:
        "By connecting the IaaS provider/s with SonicWall CSE, we can analyze which resources are available to protect and provide them to the admins in SonicWall CSE.",
    byDeviceManagement: "By Device Management",
    byDeviceManagementDescription:
        "Only include devices with the CSE App that are managed by an MDM.",
    byDeviceOwnership: "By Device Ownership",
    byDeviceOwnershipDescription: "Only include devices with the following ownership:",
    byDeviceRegistration: "By Device Registration",
    byDeviceRegistrationDescription: "Only include devices that are registered with SonicWall CSE.",
    byEmail: "By Email",
    byEmailDescription: "Assign a role to a user by email address.",
    byGroup: "By Group",
    byGroupDescription: "Assign a role to a user by group membership.",
    byMandatoryHeaders: "By Mandatory Headers",
    byMethods: "By Methods",
    byOperatingSystem: "by Operating System",
    byOriginHeader: "By Origin Header",
    byPaths: "By Paths",
    byPlatform: "By Platform",
    byPlatformDescription: "Only include devices with the following Operating Systems:",
    bySerialNumber: "By Serial Number(s)",
    bySerialNumberDescription: "Assign a role to devices with the following serial number(s):",
    byServiceAccount: "By Service Account",
    byServiceAccountDescription: "Assign a role to a Service Account for programmatic access.",
    bySourceCidrs: "By Source CIDRs",
    byStatus: "by Status",
    byTargetDomain: "By Target Domain",
    byTrustLevel: "by Trust Level",
    caCertificate: "CA Certificate",
    caData: "IDP CA Certificate",
    caDataPlaceholder: "-----BEGIN CERTIFICATE-----\n\n-----END CERTIFICATE-----",
    cancel: "Cancel",
    cannotAddLocalAdminsDescription:
        "This organization has been configured for SSO using SAML 2.0. Add new SAML Admins through your Identity Provider. Only Owners and Local Admins can add Local Admins here.",
    cannotDeleteAccessTierMsg: "Please terminate the Access Tier before deleting it.",
    cannotDeleteConnectorMessage: "Please terminate the Connector before deleting it.",
    cannotUpdateAConnectorWithoutAnId: "Cannot update a connector without an id.",
    cannotUpdateAnItpPolicyWithoutAnId: "Cannot update an itp policy without an id.",
    category: "Category",
    categoryFiltering: "Category Filtering",
    certCommonName: "Cert - Common Name",
    certRootCa: "Cert - Root CA",
    certificateFilePath: "Certificate File Path",
    certificateFilePathPlaceholder: "e.g., /path/to/certificate.pem",
    certificateFileSupportedFormats: "Supported formats: .cer, .cert",
    certificates: "Certificates",
    changeIcon: "Change Icon",
    changePassword: "Change Password",
    chatInOurForum: "Chat in our Forum",
    chatWithOurSalesTeam: "Chat with our Sales Team",
    checkOutOurDocs: "Check out our Docs",
    checkYourEmail: "Check your email",
    chooseAnEnforcementMode: "Choose an enforcement mode:",
    chooseAtLeastOneAssignment: "Choose at least one assignment: {0}",
    chooseInstallationLocation: "Choose installation location (or leave as default).",
    chooseSomething: "Choose {0}",
    chrome: "Chrome",
    chromeBrowserFactorRequiresThatAllPlatformsBeConfigured:
        "Chrome Browser Version factor requires that chrome be configured.",
    chromeBrowserVersion: "Chrome Browser Version",
    chromeBrowserVersionDesc: "Satisfied if version is running on device",
    chromeBrowserVersionFormat: "Chrome Browser version format: major",
    chromeBrowserVersionPlaceholder: "e.g., 115",
    chromeBrowserVersionRemediationText:
        "Please follow the steps to update the chrome browser version. {0}",
    chromeOs: "ChromeOS",
    chromeOsVersionFormat: "Chrome OS version format: major.minor.build.patch",
    chromeOsVersionPlaceholder: "e.g., 103.0.5060.132",
    cidrAddressExample: "Ex. 10.0.0.0/24",
    cidrOrIp: "CIDR or IP",
    cidrPlaceholder: "Enter address in CIDR format (ex. 10.0.0.0/24)",
    cidrRange: "CIDR Range",
    cidrRangeDescription: "This can be a CIDR Range or an IP address in CIDR format.",
    cidrRangeToolTipDesc:
        "Custom CIDR: provide a subset of the RFC 1918 addresses that maps to your network. \n\n RFC 1918 Addresses: All CIDR ranges listed in RFC 1918 specification for private address space.",
    cidrRanges: "CIDR Ranges",
    cidrs: "CIDRs",
    clearSelection: "Clear Selection",
    clickFinish:
        "Click 'Finish'.\n\n *Note: DO NOT UNCHECK 'Start SonicWall CSE Connector service' BOX*.",
    clickHereToViewConnectorStatusDescription:
        "Click here to view [Connector Status Descriptions]({0})",
    clickInstall: "Click 'Install'.",
    cliendSpecifiedUsingHttpConnect: "Client specifies using HTTP Connect",
    clientCertificate: "Client Certificate",
    clientCertificateDescription: "Indicates the backend requires the Netagent TLS certificate",
    clientId: "Client ID",
    clientIdDescription: "Enter the client ID provided by your Identity Provider",
    clientSecret: "Client Secret",
    clientSecretDescription: "Enter the client secret provided by your Identity Provider",
    cloneService: "Clone Service",
    close: "Close",
    cloudFormation: "CloudFormation",
    cloudProvider: "Cloud Provider",
    cloudResourceNotFound: "Cloud Resource Not Found",
    cluster: "Cluster",
    clusterName: "Cluster Name",
    clusterNotFound: "Cluster not found",
    clusters: "Clusters",
    cname: "CNAME",
    codeSent: "Code sent",
    collapseAll: "Collapse All",
    collapseNav: "Collapse Nav",
    commaSeparatedFqdns: "internal.acme.com, business.acme.com",
    commaSeparatedIpRanges: "192.168.0.0/24, 10.0.4.5",
    commaSeparatedPorts: "22, 50-250",
    commaSeparatedSomething: "Comma separated ex: {0}",
    commandCtr: "Command Center",
    communityForum: "Community Forum",
    communityForumDesc:
        "Connect with fellow users, ask questions, and learn from an entire community.",
    communitySupport: "Community Support",
    compareAllPlans: "Compare All Plans",
    completeMigrationInstruction:
        "To complete migration, ensure the **Effect** for each Trust Factor is correct. To edit, select the **Effect**. After review, select **Migrate** to move to SonicWall CSE's new TrustScoring model.",
    completed: "Completed",
    component: "Component",
    comprehensiveAccessControlsForSaasApps: "Comprehensive Access Controls for SaaS Apps",
    comprehensiveAccessControlsForSaasAppsDescription:
        "Leverage Device Trust Verification and IP allowlisting to provide greater access controls to SaaS Apps.",
    conditions: "Conditions",
    configuration: "Configuration",
    configurationInformationForYourSaasAppClient:
        "Configuration Information for your SaaS App Client",
    configure: "Configure",
    configureAccessGroup: "Configure Access Group",
    configureAccessGroupDesc:
        "Set trust level, role(s) and configure your allow list and exception list for this Access Group.",
    configureAssignmentCriteriaAndAddApplicableTrustFactors:
        "Configure assignment criteria and add applicable Trust Factors.",
    configureSomething: "Configure {0}",
    configureWhichIPAddressesUnregisteredDevices:
        "Configure which IP addresses unregistered devices must have in order to access a SonicWall CSE-protected resource.",
    confirm: "Confirm",
    confirmAccessTierInstanceDeletion: "Confirm Access Tier Instance Deletion",
    confirmClusterDeletion: "Confirm Cluster Deletion",
    confirmOrganizationAssignment: "Confirm Organization Assignment",
    confirmPassword: "Confirm Password",
    confirmRegisteredDomainDeletion: "Confirm Registered Domain Deletion",
    confirmRemovalOfMspOrganizationAssignment: "Confirm Removal of MSP Organization Assignment",
    confirmResourceServiceDeletion: "Confirm Deletion",
    confirmServiceBundleDeletion: "Confirm Service Bundle Deletion",
    confirmUserRemoval: "Confirm User Removal",
    confirmViewAsAdminExplanation:
        "View this organization as an {0} without creating a new account. To return to superadmin view, click on 'Back to MSP org'.",
    confirmViewAsSomething: "Confirm View as {0}",
    connectCloudSecureEdgeToYourOwnIdentityProviderDesc:
        "Connect Cloud Secure Edge (CSE) to your own Identity Provider for device registration. See step-by-step [instructions in our help docs]({0}).",
    connectOnLogin: "Connect on Login",
    connectOnLoginInfrastructureServiceDescription:
        "Enabling will automatically connect this Service when users log into their app. Users can still connect to other Services manually. Connect on Login settings are only evaluated during device registration.",
    connectOnLoginServiceTunnelDescription:
        "Enabling will automatically connect this Service Tunnel when users log into their app. Users can still connect to other Service Tunnels manually.",
    connectivity: "Connectivity",
    connectivityCheckAndInstallationInstructions:
        "Connectivity Check and Installation Instructions",
    connector: "Connector",
    connectorName: "Connector Name",
    connectorNameDescription:
        "Use a name that identifies the network the Connector is installed on. Spaces and special characters are prohibited in Connector names.",
    connectorNameValidationError:
        "This entry contains invalid characters. Please enter a different name.\n\nThe following characters are not allowed:\n<>\"'#%{}|\\^`[]@:/?&= and space",
    connectorNotFound: "Connector Not Found",
    connectorVersion: "Connector Version",
    connectors: "Connectors",
    connectorsOfTheGlobalEdge: "Connectors of the Global Edge",
    connectsOnLogin: "Connects on Login",
    contactOurSupport: "Contact our Support",
    contactSales: "Contact Sales",
    contactSalesToUpgrade: "Contact Sales to Upgrade",
    contactSupport: "Contact Support",
    contactUsToEnable: "Contact Us to Enable",
    contentCategories: "Content Categories",
    contentFiltering: "Content Filtering",
    continue: "Continue",
    continueAnyway: "Continue Anyway",
    continueToLogin: "Continue to login",
    continueWhenAccessTierIsReportingMessage:
        "You can continue once you've run the Installation Steps and the Access Tier status reads **Reporting**.",
    convertServiceToCustomJson: "Convert Service to Custom JSON",
    copy: "Copy",
    copyAnExistingTrustProfile: "Copy an existing Trust Profile",
    copyApiKey:
        "Copy the API key and paste this value into its respective field in the Windows executable.",
    copyConnectorName:
        "Copy the Connector name and paste this value into its respective field in the Windows executable.",
    copyCseCommandCenterFqdn:
        "Copy the CSE Command Center FQDN and paste this value into its respective field in the Windows executable.",
    copyOfSomething: "Copy of {0}",
    copyPasteTheApiKeyIntoRespectiveField: "Copy/paste the API Key into the respective field.",
    copyPasteTheConnectorNameIntoRespectiveField:
        "Copy/paste the Connector name into the respective field.",
    corporateDedicated: "Corporate Dedicated",
    corporateShared: "Corporate Shared",
    corsExemptionHash: "CORS Exemption #",
    corsExemptions: "CORS Exemptions",
    couldNotDeleteDevices: "Could not delete Devices.",
    couldNotFetchData: "Could not fetch data.",
    couldNotFetchDevices: "Could not fetch Devices.",
    couldNotFetchRoles: "Could not fetch Roles.",
    couldNotFetchUsers: "Could not fetch users",
    couldNotFindServiceResource: "Could not find service resource",
    couldNotParseExistingDeviceManagerConfig: "Could not parse existing device manager config.",
    couldNotParseExistingIdentityProviderConfig:
        "Could not parse existing identity provider config.",
    couldNotUnregisterUser: "Could not unregister User.",
    couldNotUpdateDevice: "Could not update Device.",
    count: "Count",
    countDevices_one: "{0} Device",
    countDevices_other: "{0} Devices",
    create: "Create",
    createABlockPageWithYourOwnMessagingAndPreviewIt:
        "Create a block page with your own messaging and preview it.",
    createAConnector: "Create a Connector",
    createAConnectorDescription:
        "The Connector is your entry point into your private network. Providing details about your network will allow the Connector to provide services on that network to end users. Once configured, we'll generate the install commands to be used in the next step.",
    createACustomApp: "Create a Custom App",
    createAFile: "Create a File",
    createANewRole: "Create a New Role",
    createAPropertyList: "Create a Property List",
    createASecureChannelDesc:
        "Create a secure channel to the security tool, SonicWall CSE can query the ID of the device or user in the tool for specific Trust Factors",
    createAccessTier: "Create Access Tier",
    createAccessTierGroup: "Create Access Tier Group",
    createAnAccessTierDesc:
        "Install a SonicWall CSE Access Tier in your network. The SonicWall CSE Access Tier is an identity-aware proxy that mediates access between entities on the internet and your internal services.",
    createAnAddtionalAppClientDesc:
        "Create an additional App Client in your Identity Provider for authenticated end users to register their devices using the CSE desktop or mobile app. \n**Important: this setup is mandatory for Passwordless Authentication workflows.**",
    createAndManageServiceAccountsThatCanProvideProgrammaticAccessToSonicWallCseServices:
        "Create and manage Service Accounts that can provide programmatic access to SonicWall CSE services.",
    createApiKey: "Create Api Key",
    createApp: "Create App",
    createConnector: "Create Connector",
    createCustomAppError: "Error creating custom application.",
    createInfrastructureServicesDesc:
        "Create infrastructure services that end users registered with SonicWall CSE can access via a zero-trust Policy. ",
    createItpPolicy: "Create ITP Policy",
    createNewPolicy: "Create New Policy",
    createPolicy: "Create Policy",
    createProfile: "Create Profile",
    createRole: "Create Role",
    createServiceAccount: "Create Service Account",
    createSomething: "Create {0}",
    createTrustProfile: "Create Trust Profile",
    createTrustProfileDescription:
        "Trust Profiles allow Admins to assign configurable Trust Factors to registered Devices.",
    createWebServicesDesc:
        "Create web services (accessed via HTTP/HTTPs) that SonicWall CSE registered users can access via a zero-trust policy. ",
    createYourFirstAccessTier: "Create Your First Access Tier",
    createYourFirstAccessTierDesc:
        "The SonicWall CSE Access Tier is the entry to your private network and a policy enforcement point.",
    createYourFirstConnector: "Create Your First Connector",
    createYourFirstConnectorDescription:
        "The SonicWall CSE Connector is a dial-out connector that establishes a secure tunnel with the SonicWall CSE Global Edge Network. The Connector can be deployed in any location that has connectivity to your internal services. Each SonicWall CSE Connector only connects outbound and does not need any inbound open ports to operate correctly.",
    createYourFirstRole: "Create Your First Role",
    createYourFirstRoleDescription:
        "Roles help to manage the different levels of access that groups of entities have to a particular service.\n Read our Help Docs to [Learn More]({0}).",
    createdAt: "Created At",
    createdBy: "Created By",
    createsFederationBetweenSaasAppDesc:
        "Creates a federation between a SaaS application and SonicWall CSE which SonicWall CSE-registered end users can access via a zero-trust policy.",
    crossOriginResourceSharing: "Cross-origin Resource Sharing (CORS)",
    crowdstrike: "CrowdStrike",
    cseAppPreview: "CSE App Preview",
    cseAppSettingsOptional: "CSE App Settings (Optional)",
    cseAppVersion: "CSE App Version",
    cseAppVersionDescription: "Satisfied if version is running on the device",
    cseAppVersionRemediationText:
        "Please follow the steps to update the CSE App version on your device.",
    cseDesktopAndMobileApps: "CSE Desktop & Mobile Apps",
    cseDeterminesWhetherADeviceIsMdmManagedThroughZeroTouchSettings:
        "SonicWall CSE determines whether a device is MDM-managed through zero-touch settings.",
    cseFederated: "CSE Federated",
    cseFederatedDescription:
        "Directly federate a SaaS application to SonicWall CSE using SAML or OIDC to define Zero Trust policies at an application level. Authentication will be routed to your organizations Identity Provider.",
    cseStatusDesc:
        "View our current system operational status page for up-to-date reports, or subscribe for updates.",
    cseTrustProvider: "CSE TrustProvider",
    currentPassword: "Current Password",
    currentPlan: "Current Plan",
    currentStatus: "Current Status",
    currentlyCloudSecureEdgeIsConfiguredToAuthenticateDesc:
        "Currently Cloud Secure Edge is configured to authenticate end users using its own method. Once you configure a new identity provider, CSE's local user management will no longer be available as an authentication method.",
    currentlyThereAreXAvailableAccessTiersToAdd:
        "Currently there are **{0} available Access Tiers** to add. ",
    currentlyThereIs1AvailableAccessTierToAdd:
        "Currently there is **1 available Access Tier** to add. ",
    currentlyThereIsNoAvailableAccessTiersToAdd:
        "Currently there is **no available Access Tiers** to add. ",
    custom: "Custom",
    customAppDeleteItpPoliciesExplanation:
        "Deleting {0} will erase the app record and will automatically remove the app from the list of policies below. The app cannot be re-added to services or policies after deletion.",
    customAppDeleteTunnelsExplanation:
        "To delete {0}, ensure that you remove the associated Service Tunnel (listed below) from {0} first. To remove the associated Service Tunnel, click into the relevant Service Tunnel and select Remove.",
    customAttributesMustBeValidJSON: "Custom attributes must be valid JSON.",
    customBranding: "Custom Branding",
    customCertificateDescription:
        "SonicWall CSE provides options to specify the TLS server certificate used for your Hosted Websites. [Learn More]({0})",
    customCertificateLabel: "What Certificate would you like to use?",
    customCidr: "Custom CIDR",
    customHTTPHeadersToSendToBackend: "Custom HTTP Headers to Send to Backend",
    customHTTPHeadersToSendToBackendOptional: "Custom HTTP Headers to Send to Backend (optional)",
    customHeader: "Custom Header",
    customHttpHeadersToSendToBackend: "Custom HTTP headers to send to backend",
    customIntegration: "Custom Integration",
    customJSON: "Custom JSON",
    customJSONDescription: "For advanced use cases.",
    customMetadataMustBeValidJSON: "Custom metadata must be valid JSON.",
    customPolicyJson: "Custom Policy (JSON)",
    customPolicyJsonDescription: "For advanced use cases, define a policy in JSON.",
    customServiceJson: "Custom Service (JSON)",
    customSomething: "Custom {0}",
    customerId: "Customer ID",
    customization: "Customization",
    customizationSuccessful: "Customization Successful",
    dashboard: "Dashboard",
    dataLostPrevention: "Data Loss Prevention",
    database: "Database",
    databaseService: "Database Service",
    databaseServiceDescription:
        "Secure access to an individual database or a collection of databases using MTLS and short-lived certificates. Supports certificate-based end user authentication.",
    date: "Date",
    dateOfAction: "Date of the action",
    days: "Days",
    debianPackage: "Debian Package",
    debianUbuntu: "Debian / Ubuntu",
    default: "Default",
    defaultGroupsUser: "Default Groups User",
    defaultGroupsUserPool: "Default Groups User Pool",
    defaultSomething: "Default {0}",
    defaultViewOfEndUserExperiences:
        "Default view of end user experiences. You may customize brand and messaging and preview here.",
    delete: "Delete",
    "deleteAccessGroup#": "Delete Access Group #",
    deleteAccessPolicy: "Delete Access Policy",
    deleteAccessTierDescription:
        "Are you sure you want to delete this Access Tier? You cannot undo this action.",
    deleteAccessTierGroupExplanation:
        "Are you sure you want to delete this Access Tier Group? You cannot undo this action.",
    deleteAccessTierInstanceExplanation:
        "Are you sure you want to delete this Access Tier Instance? You cannot undo this action.",
    deleteAdmin: "Delete Admin",
    deleteAdminDescription:
        "Are you sure you want to delete this user? All user data will be deleted. You cannot undo this action.",
    deleteApiKey: "Delete API Key",
    deleteApiKeyDescription:
        "Are you sure you want to delete this API key? You cannot undo this action.",
    deleteApiToken: "Delete API Token",
    deleteApplicationCheck: "Delete Application Check",
    deleteAssociation: "Delete Association",
    deleteCertificate: "Delete Certificate",
    deleteCluster: "Delete Cluster",
    deleteClusterExplanation:
        "Are you sure you want to delete this cluster? You cannot undo this action.",
    deleteConnector: "Delete Connector",
    deleteConnectorDescription:
        "Are you sure you want to delete this Connector? You cannot undo this action.",
    deleteDevice: "Delete Device",
    deleteDeviceConfig: "Delete Device Config",
    deleteDeviceRegistrationProviderConfig: "Delete Device Registration Provider Config",
    deleteFile: "Delete File",
    deleteIntegration: "Delete Integration",
    deleteIssuedCertificateExplanation: "Are you sure you want to delete this certificate?",
    deleteLastDeviceExplanation:
        "Are you sure you would like to delete the current configuration for the device registration? You cannot undo this action.",
    deleteMdmDeploymentKey: "Delete MDM Deployment Key",
    deleteNetagentBeforeDeletingAccessTier:
        "Access Tier has associated Netagent instances which must be deleted before proceeding",
    deleteOneUnregisteredDeviceExplanation:
        "Are you sure you want to delete this device? You cannot undo this action.",
    deleteOrg: "Delete Org",
    deletePasswordless: "Delete Passwordless",
    deletePasswordlessDescription:
        "Are you sure you want to delete the App Client for Passwordless Authentication? If you have configured your Identity Provider for Passwordless, you need to update it as well once you delete this App Client.",
    deletePolicy: "Delete Policy",
    deletePolicyAttachedServiceDesc:
        "**{0}** Policy is currently used by following Service Tunnel(s). To delete this policy, it must be detached from all services.",
    deletePolicyDescription:
        "Once deleted, all relevant data about this policy will be removed from SonicWall CSE and is not recoverable. Are you sure you want to delete this policy?",
    deletePropertyList: "Delete Property List",
    deleteRegisteredDomain: "Delete Registered Domain",
    deleteRegisteredDomainExplanation:
        "Are you sure you want to delete this Registered Domain? You cannot undo this action.",
    deleteRole: "Delete Role",
    deleteRoleDescription_one:
        "This role is attached to {0} policy, are you sure you want to delete this role? Once deleted, the policy will be detached and all relevant data about this role will be permanently removed from SonicWall CSE and will be non-recoverable.",
    deleteRoleDescription_other:
        "This role is attached to {0} policies, are you sure you want to delete this role? Once deleted, the policies will be detached and all relevant data about this role will be permanently removed from SonicWall CSE and will be non-recoverable.",
    "deleteRule#": "Delete Rule #",
    deleteService: "Delete Service",
    deleteServiceAccount: "Delete Service Account",
    deleteServiceAccountExplanation:
        "Are you sure you want to delete this service account? This cannot be undone.",
    deleteServiceBundleExplanation:
        "Are you sure you want to delete this service bundle? You cannot undo this action.",
    deleteServiceDescription:
        "Once deleted, all relevant data about this service will be removed from SonicWall CSE and is not recoverable. To temporarily remove SonicWall CSE access controls, disable this service instead. Are you sure you want to delete this service?",
    deleteServiceTunnel: "Delete Service Tunnel",
    deleteServiceTunnelDescription: "Are you sure you want to delete this service tunnel?",
    deleteSomething: "Delete {0}",
    deleteTrustProfile: "Delete Trust Profile",
    deleteUnregisteredDeviceExplanation:
        "Are you sure you want to delete these {0} devices? You cannot undo this action.",
    deleteUser: "Delete User",
    deleted: "Deleted",
    deletedAt: "Deleted At",
    deletedBy: "Deleted By",
    deletedOrgs: "Deleted Orgs",
    deletedReason: "Deleted Reason",
    deletingAnMdmKeyCannotBeUndoneDescription:
        "Deleting an MDM deployment key cannot be undone. Users registering their device will no longer be exempt from OTP-based email validation.",
    deletionFailed: "Deletion Failed",
    deny: "Deny",
    deploySonicWallCseAccessTierOrLeverageSonicWallCseGlobalEdgeInfraDesc:
        "Deploy a SonicWall CSE Access Tier or leverage SonicWall CSE’s Global Edge in front of the infrastructure service, and configure an Infra Policy.",
    deploySonicWallCseAccessTierOrLeverageSonicWallCseGlobalEdgeWebServiceDesc:
        "Deploy a SonicWall CSE Access Tier or leverage SonicWall CSE’s Global Edge in front of the web service, and configure a Hosted Web Policy.",
    deployment: "Deployment",
    deploymentKeyRevoked: "Deployment key revoked.",
    deploymentType: "Deployment Type",
    describeThisAccessGroup: "Describe this Access Group",
    describeYourServiceAccount: "Describe your Service Account",
    description: "Description",
    descriptionShownToEndUsers: "Description (shown to end users)",
    desktopAndMobile: "Desktop & Mobile",
    detachAccessTiersBeforeDeletingAccessTierGroup:
        "Access Tier Group has attached Access Tiers which must be detached before proceeding",
    detailsAndAssignment: "Details and Assignment",
    device: "Device",
    deviceBannedWarning:
        "The SonicWall CSE certificate has been revoked, and access to all resources has been blocked. To unban the device, set the BANNED STATUS to “No” (on the Device Information tab) and re-register the device.",
    deviceBasedRole: "Device Based Role",
    deviceCertOnly: "Device Cert Only",
    deviceCertificateConfig: "Device Certificate Config",
    deviceClientSecret: "Device Client Secret",
    deviceCountDoesNotUpdateInRealTimeDesc:
        "Device count does not update in real time, instead devices are updated in a batch sync. To ensure an accurate device count, check the status and schedule. [Learn more]({0}) ",
    deviceCountLastUpdated: "Device Count Last Updated",
    deviceDetails: "Device Details",
    deviceExpiredWarning: "The Trust Level expired at {0}.",
    deviceGeoLocation: "Device Geolocation",
    deviceGeoLocationDescText: "Satisfied if the device is not in the selected countries",
    deviceGeoLocationRemediationText:
        "Device location is not approved. If your device is expected in this location, notify your org admin.",
    deviceIdentityProviderConfigurationUpdated: "Device Identity Provider configuration updated.",
    deviceIdpName: "Device IDP Name",
    deviceIdpProtocol: "Device IDP Protocol",
    deviceInformation: "Device Information",
    deviceManager: "Device Manager",
    deviceManagerApiConfig: "Device Manager API Config",
    deviceManagerConfigurationUpdated: "Device Manager configuration updated.",
    deviceManagerConfirmDescription:
        "Are you sure you want to change the Device Manager Config? Ensure your Device Manager has been set up correctly to match these configurations.",
    deviceManagerName: "Device Manager Name",
    deviceName: "Device Name",
    deviceNotFound: "Device not found",
    deviceOS: "Device OS",
    deviceOverriddenWarning:
        "The Trust Level has been overridden by an API. As a result, the Trust Level has been lowered.",
    deviceOwnership: "Device Ownership",
    devicePendingWarning:
        "We were unable to collect the Trust Factors during device registration. Please send device details; if the issue persists, try re-registering the device.",
    devicePlatforms: "Device Platforms",
    deviceRegistrationProviderConfig: "Device Registration Provider Config (optional)",
    deviceRegistrationProviderDescription:
        "Create an additional App Client in your Identity Provider so authenticated users can register their devices using the CSE Desktop App or Mobile App. **Note: this setup is mandatory for Passwordless Authentication workflows.**",
    deviceSerialNumber: "Device Serial Number",
    deviceSerialNumbersCanBeFoundOnTheDeviceDetailsPage:
        "Device Serial Numbers can be found on the Device Details page.",
    devices: "Devices",
    devicesAreBannedAndCannotBeRemovedUsingThisMethod_one:
        "This Device is banned and cannot be removed using this method:",
    devicesAreBannedAndCannotBeRemovedUsingThisMethod_other:
        "{0} Devices are banned and cannot be removed using this method:",
    devicesTemplate: "Devices ({0})",
    devicesWillBeRemovedFromYourSonicWallCseEnvironment_one:
        "{0} Device will be removed from your SonicWall CSE environment:",
    devicesWillBeRemovedFromYourSonicWallCseEnvironment_other:
        "{0} Devices will be removed from your SonicWall CSE environment:",
    directory: "Directory",
    disable: "Disable",
    disableOneClickConnectivityToAllServicesInBundle:
        "Disable one-click connectivity to all Services in this Bundle",
    disablePrivateDns: "Disable Private DNS Override",
    disablePrivateDnsDescription:
        "Private DNS override is used to resolve this service's domain name when a service tunnel is enabled.",
    disableScim: "Disable SCIM",
    disableService: "Disable Service",
    disableServiceDescription:
        "Disabling a service removes SonicWall CSE access control and activity logging. Are you sure you want to disable this service?",
    disabled: "Disabled",
    disabledSomething: "Disabled {0}",
    disablingScimDesc:
        "Disabling SCIM will prevent you from receiving any updates to end user information from your SCIM provisioner. Do you want to disable SCIM?",
    disconnectServiceTunnelWhenOnTrustedNetwork:
        "Disconnect Service Tunnels when on a Trusted Network",
    disconnectServiceTunnelWhenOnTrustedNetworkDesc:
        "Disconnect the Service Tunnel when the device is on a Trusted Network",
    discovered: "Discovered",
    discoveredOn: "Discovered On",
    discovery: "Discovery",
    diskEncryption: "Disk Encryption",
    diskEncryptionDescription: "Satisfied if enabled on the device",
    diskEncryptionLinuxRemediationText:
        "Please follow the steps to enable disk encryption for your Linux device. {0}",
    diskEncryptionMacOsRemediationText:
        "Please follow the steps to enable disk encryption for your macOS device. {0}",
    diskEncryptionWindowsRemediationText:
        "Please follow the steps to enable disk encryption for your Windows device. {0}",
    dlpPolicy: "DLP Policy",
    dnsEntriesFailed: "DNS Entries Failed",
    dnsOrIpFound: "DNS or IP found",
    dnsRegistrarHelpText:
        "For help adding records to your DNS registrar, please refer to [this documentation]({0}).",
    dnsSettings: "DNS Settings",
    doNotKnowHowToFrameYourQuestionTryWritingTheseStatements:
        "Don't know how to frame your question? Try writing these statements:",
    doYouWantToEnableScim: "Do you want to enable SCIM?",
    doYouWantToRegenerateTheApiTokenForScim: "Do you want to regenerate the API token for SCIM?",
    docker: "Docker",
    dockerChecks:
        "Confirm connectivity check results by copying the command. If the output display is 'Failure' please ensure your network is configured to allow connections as detailed in the [pre-requisite checklist]({0}), then re-run the command from Step 2.",
    dockerConnectivityDescription: "Set SonicWall CSE environment variables",
    dockerContainer: "Docker Container",
    dockerInstallDescription: "Run the container",
    documentation: "Documentation",
    documentationDesc:
        "Browse our documentation and find the answers to your questions about how to set up and use the SonicWall CSE platform in your org.",
    doesApplyToDevices: "{0} does apply to {1} Devices",
    domain: "Domain",
    domainBasedWebFiltering: "Domain Based Web Filtering",
    domainBasedWebFilteringDescription:
        "Protect the users in your organization by evaluating the domains requested by end users.",
    domainDesc: "Enter a domain to see its categorization for Threats and Content",
    domainExceptions: "Domain Exceptions",
    domainFieldError: "Please add a valid domain.",
    domainFiltering: "Domain Filtering",
    domainIsAlreadyInTheBypassDomainsList: '"{0}" is already in the Bypass Domains list',
    domainMustBeRegisteredWithYourOrganization:
        "Domain must be registered with your organization to generate a Let's Encrypt certificate.",
    domainName: "Domain Name",
    domainNameInternalPlaceholder: "Domain Name (ex. internal.example.com)",
    domainNamePlaceholder: "Domain Name (ex. website.example.com)",
    domainOrIpAddress: "Domain or IP address",
    domains: "Domains",
    domainsAppsIps: "Domains / Apps / IPs",
    domainsDescription: "Enter the domains that are associated with the app.",
    domainsGroupedTogetherInTermsOfTheContentTheyProvideToEndUsersSelectOneOrMore:
        "Domains grouped together in terms of the content they provide to end users. Select one or more.",
    domainsToProxy: "Domains to Proxy",
    domainsToProxyDescription:
        "If specified, banyanproxy will intercept requests to ONLY these domains and forward them to the Access Tier; all other requests will be sent directly to their intended destination.",
    domainsToProxyPlaceholder: "ex. www.example.com, *.co.uk, 127.0.0.1",
    done: "Done",
    dontHaveAnOrg: "Don't have an org?",
    dot: ".",
    download: "Download",
    downloadAll: "Download All",
    downloadAndExtractTheBinary: "Download and extract the binary",
    downloadAndInstallCseAppsOnDesktopAndMobile:
        "Download and install CSE app on desktop and mobile",
    downloadDeployScriptForMacOS: "Download Deploy Script for macOS",
    downloadDeployScriptForWindows: "Download Deploy Script for Windows",
    downloadDevices: "Download Devices",
    downloadInstallerPackage: "Download installer package.",
    downloadLogs: "Download Logs",
    downloadPolicySpec: "Download Policy Spec",
    downloadRoleSpec: "Download Role Spec",
    downloadServiceSpec: "Download Service Spec",
    downloadSomething: "Download {0}",
    downloadSyncLogs: "Download Sync Logs",
    downloadTheOvaFile: "Download the OVA file.",
    downloadUsers: "Download Users",
    dragItpPoliciesToDesiredPriorityOrder: "Drag ITP Policies to desired priority order",
    dragProfilesToDesiredPriorityOrder: "Drag profiles to desired priority order",
    duplicateMacAddressFound: "Duplicate MAC address found:",
    edit: "Edit",
    editADomain: "Edit a domain",
    editAnAdmin: "Edit an Admin",
    editApplicableDeviceOwnerships: "Edit Applicable Device Ownerships",
    editApplication: "Edit Application",
    editCseFederatedSaasService: "Edit CSE Federated SaaS Service",
    editCustomAppError: "Failed to update custom application",
    editDevice: "Edit Device",
    editEffect: "Edit Effect",
    editFactorSomething: "Edit Factor - {0}",
    editIdpRoutedService: "Edit IDP Routed Service",
    editIntegration: "Edit Integration",
    editLicenses: "Edit Licenses",
    editOrg: "Edit Org",
    editPolicy: "Edit Policy",
    editPropertyList: "Edit Property List",
    editRegistryCheck: "Edit Registry Check",
    editService: "Edit Service",
    editServiceBundle: "Edit Service Bundle",
    editSomething: "Edit {0}",
    editSomethingWithLabel: 'Edit "{0}" {1}',
    editUser: "Edit user",
    edition: "Edition",
    editionDowngradeIsNotSupported: "Edition downgrade is not supported.",
    effect: "Effect",
    egCseSecuredCluster: "e.g. cse-secured-cluster",
    egDomainExample: "e.g., domain.corp.example.com",
    egDomainNameDotCom: "E.g., domain-name.com",
    egIntenalDomainExample: "e.g., internal.example.com",
    egSubdomain: "e.g., subdomain",
    egWwwExampleDotCom: "E.g., www.example.com",
    email: "Email",
    emailAddress: "Email Address",
    emailAttr: "Email Attribute",
    emailProvided: "Email Provided: <0></0>",
    employeeOwned: "Employee Owned",
    emptyAppDeleteMessage: "Deleting {0} will erase the app record and all associated information.",
    emptySmartSearchHistoryResult:
        "You don't have any history of searches. Once you start using the Smart Search, your search history will show here. You can visit this page to find old searches and revisit the answers.",
    enable: "Enable",
    enableAiAssistedAdminSearch: "Enable AI Assisted Admin Search",
    enableApplicationDiscovery: "Enable Application Discovery",
    enableItp: "Enable ITP",
    enableOneClickConnectivityToAllServicesInBundle:
        "Enable one-click connectivity to all Services in this Bundle",
    enableOrgWideDeviceTrustVerification: "Enable Org Wide Device Trust Verification",
    enableOrgWideDeviceTrustVerificationDescription:
        "Device Trust Verification leverages the CSE App to establish device trust on (a) mobile devices and (b) authentication webviews that are unable to present a device certificate.",
    enablePrivateResourceDiscovery: "Enable Private Resource Discovery",
    enablePrivateResourceDiscoveryDescription:
        "If enabled, SonicWall CSE will compile and display a list of private resources commonly accessed via Service Tunnels.",
    enableScim: "Enable SCIM",
    enableService: "Enable Service",
    enableServiceAccountAccess: "Enable Service Account Access",
    enableServiceDescription:
        "Enabling a service restores SonicWall CSE access control and activity logging. Are you sure you want to enable this service?",
    enableStrictTransport: "Enable Strict Transport",
    enableStrictTransportDesc:
        "If enabled, SonicWall CSE will send the HTTP Strict-Transport-Security response header.",
    enableTotp: "Enable TOTP",
    enableUrlFiltering: "Enable URL Filtering",
    enabled: "Enabled",
    enabledSomething: "Enabled {0}",
    endUser: "End User",
    enduserManagement: "Enduser Management",
    enforceAcceptableUsePoliciesByBlockingSpecificCategoriesOfWebsites:
        "Enforce acceptable use policies by blocking specific categories of websites (e.g., gambling).",
    enforcementMode: "Enforcement Mode",
    enforcing: "Enforcing",
    enhancedManagementOfUsersAndDevices: "Enhanced Management of Users and Devices",
    enhancedManagementOfUsersAndDevicesDescription:
        "Leverage passwordless and clientless access as well as endpoint detection management tools.",
    enhancedVisibilityAndCompliance: "Enhanced Visibility and Compliance",
    enhancedVisibilityAndComplianceDescription:
        "Manage shadow IT in your organization using our discovery feature.",
    enterADomain: "Enter a domain",
    enterApiEndpoint: "Enter API Endpoint",
    enterAppSupportMessageToSeeCustomizedHelpPage:
        "Enter App Support Message to see customized Help Page.",
    enterAudienceUriDescription: "Enter audience URI (Service Provider Entity ID)",
    enterClientId: "Enter Client Id",
    enterClientSecret: "Enter Client Secret",
    enterDescription: "Enter Description",
    enterDeviceSerialNumber: "Enter device serial number(s).",
    enterDeviceSerialNumbers: "Enter Device Serial Numbers",
    enterDomainEGSalesforceCom: "Enter domain (e.g salesforce.com)",
    enterId: "Enter ID",
    enterIdpRoutedServiceName: "Enter IDP routed service name",
    enterIntegrationName: "Enter Integration Name",
    enterItpPolicyName: "Enter ITP Policy name (e.g. ITP for Corporate Devices)",
    enterOneTimePasscode: "Enter One-Time Passcode",
    enterOptionalDescription: "Enter optional description",
    enterRedirectUrl: "Enter redirect URL",
    enterRedirectUrlEgDescription: "Enter URL, e.g. https://login.salesforce.com/openid-config",
    enterSaasApplicationName: "Enter SaaS Application name",
    enterSecret: "Enter Secret",
    enterServiceDescription: "Enter service description",
    enterSomething: "Enter {0}",
    enterSpecificAppsThatBypassThisITPPolicy:
        "Enter specific applications that bypass this ITP policy.",
    enterSpecificAppsToBlockAccessTo: "Enter specific applications to block access to.",
    enterSpecificDomainsThatBypassThisITPPolicyIncludesAllSubdomains:
        "Enter specific domains that bypass this ITP policy. Includes all subdomains.",
    enterSpecificDomainsToBlockAccessTo:
        "Enter specific domains to block access to. Includes all subdomains (e.g gambling.com would include bets.gambling.com).",
    enterTrustProfileName: "Enter Trust Profile Name (e.g, Developers Austin)",
    enterUserGroups: "Enter User Groups",
    enterValidNameNoSpecialCharactersAllowed: "Enter valid name (no special characters allowed)",
    enterYourCustomAttributeSelector: "Enter your custom attribute selector",
    enterYourEmailAddress: "Enter your Email Address",
    enterYourOrgName: "Enter your Org Name",
    enterprise: "Enterprise",
    enterpriseDeviceManager: "Enterprise Device Manager",
    enterpriseDeviceManagerExplanation:
        "Connect SonicWall CSE to your Device Manager. See step-by-step [instructions in the docs]({0}).",
    enterpriseDeviceManagerUpgradeDescription:
        "Integrate SonicWall CSE with your Device Manager to distribute the CSE App, verify Device identity, and enhance TrustScoring.",
    entityIssuer: "Entity Issuer",
    entityIssuerIdentifierMustBeUniqueForYourIdp:
        "Entity issuer identifier must be unique for your IDP",
    entraId: "Entra ID",
    equalTo: "=",
    error: "Error",
    errorAProcessNameContainsAnInvalidRegex: "Error: A Process Name includes an invalid regex.",
    errorCouldNotAddOrg: "Error: Could not add Org.",
    errorCouldNotCreateItpPolicy: "Error: Could not create ITP Policy.",
    errorCouldNotCreateLicenseUsage: "Error: Could not create license usage",
    errorCouldNotCreateManagedBypassDomain: "Error: Could not create managed bypass domain",
    errorCouldNotCreateMspAdmin: "Error: Could not Add MSP admin",
    errorCouldNotCreateOrg: "Error: Could not create org",
    errorCouldNotCreateRole: "Error: Could not create Role.",
    errorCouldNotDeleteItpPolicy: "Error: Could not delete ITP Policy.",
    errorCouldNotDeleteLicenseUsage: "Error: Could not delete license usage",
    errorCouldNotDeleteManagedBypassDomain: "Error: Could not delete managed bypass domain",
    errorCouldNotDeleteMspAdmin: "Error: Could not delete MSP admin",
    errorCouldNotDeleteOrg: "Error: Could not delete Org.",
    errorCouldNotDeleteRole: "Error: Could not delete Role.",
    errorCouldNotDeleteServiceTunnel: "Error: Could not delete service tunnel.",
    errorCouldNotDisableRole: "Error: Could not disable Role.",
    errorCouldNotDownloadZTScript: "Error: Could not download ZT script.",
    errorCouldNotEditAppConfig: "Error: Could not App Config.",
    errorCouldNotEditOrg: "Error: Could not edit Org.",
    errorCouldNotFetchAdmins: "Error: Could not fetch admins",
    errorCouldNotFetchAppConfig: "Error: Could not fetch App Config.",
    errorCouldNotFetchCloudResourceInventory: "Error: Could not fetch cloud resource inventory",
    errorCouldNotFetchOrg: "Error: Could not fetch Org.",
    errorCouldNotFetchOrgCustomizations: "Error: Could not fetch Org Customizations.",
    errorCouldNotFetchOrgDetails: "Error: Could not fetch Org Details.",
    errorCouldNotFetchOrgs: "Error: Could not fetch Orgs.",
    errorCouldNotFetchPolicy: "Error: Could not fetch policy.",
    errorCouldNotFetchServiceTunnelSettings: "Error: Could not fetch service tunnel settings",
    errorCouldNotFetchServiceTunnels: "Error: Could not fetch service tunnels.",
    errorCouldNotFetchUserOrgDetails: "Error: Could not fetch User Org Details.",
    errorCouldNotFindCloudResource: "Error: Could not find cloud resource",
    errorCouldNotFindServiceTunnel: "Error: Could not find service tunnel.",
    errorCouldNotGenerateDnsReport: "Error: Could not generate DNS report.",
    errorCouldNotGetAdminManagedBypassDomains: "Error: Could not get Admin managed bypass domains",
    errorCouldNotGetDeviceDiagnosticStatus: "Error: Could not get device diagnostic status",
    errorCouldNotGetDnsFilters: "Error: Could not get Dns filters.",
    errorCouldNotGetDnsReport: "Error: Could not get DNS report.",
    errorCouldNotGetEventTypes: "Error: Could not get Event types.",
    errorCouldNotGetGroups: "Error: Could not get Groups.",
    errorCouldNotGetItpPolicies: "Error: Could not get ITP Policies.",
    errorCouldNotGetMswLicenseUsage: "Error: Could not get MSW license usage",
    errorCouldNotGetPacFile: "Error: Could not get PAC file",
    errorCouldNotGetPolicyAttachments: "Error: Could not get policy attachments.",
    errorCouldNotGetRoles: "Error: Could not get Roles.",
    errorCouldNotGetSonicWallCseManagedBypassDomains:
        "Error: Could not get SonicWall CSE managed bypass domains",
    errorCouldNotImpersonate: "Error: Could not impersonate.",
    errorCouldNotReorderItpPolicies: "Error: Could not reorder ITP Policies.",
    errorCouldNotUpdateItpPolicy: "Error: Could not update ITP Policy.",
    errorCouldNotUpdateLicenseUsage: "Error: Could not update license usage",
    errorCouldNotUpdateManagedBypassDomain: "Error: Could not update managed bypass domain",
    errorCouldNotUpdateMspAdmin: "Error: Could not update MSP admin",
    errorCouldNotUpdatePacFile: "Error: Could not update PAC file",
    errorCouldNotUpdateRole: "Error: Could not update Role.",
    errorCouldNotUpdateUrlFilteringFlag: "Error: Could not update URL filtering flag",
    errorDeletingCustomApp: "Error Deleting Custom App",
    errorDetachingServiceFromPolicy: "Error detaching service from policy.",
    errorFailedToConfigureTrustedNetwork: "Error: Failed to configure trusted network",
    errorFailedToDeleteDeviceConfig: "Error: Failed to delete device config",
    errorFailedToFetchAccessTierServices: "Error: Failed to fetch Access Tier Services",
    errorFailedToFetchDeviceLogs: "Error: Failed to fetch device logs",
    errorFailedToRequestDeviceDiagnostics: "Error: Failed to request device diagnostics",
    errorFailedToUpdateDeviceProviderConfig: "errorFailedToUpdateDeviceProviderConfig",
    errorFailedToUpdateNrptConfig: "Error: Failed to update NRPT config",
    errorFailedToUpdateTrustedNetwork: "Error: Failed to update trusted network",
    errorFailedToUpdateUserConfig: "Error: Failed to update user config",
    errorFetchingVariables: "Error fetching variables",
    errorGeneratingCscAccessCode: "Error generating CSC access code",
    errorGettingUsers: "Error getting users",
    errorInitialAuthenticatorNotSet: "Error: Initial Authenticator not set.",
    errorItpPolicyNotFound: "Error: ITP Policy Not Found",
    errorLink: "Error Link",
    errorLinkDescription: "A URL for the “Get Help” link in the browser.",
    errorLinkTitle: "Error Link Title",
    errorLinkTitleDescription:
        "A custom message that endusers can see in the CSE App when they hover on the link.",
    errorLogsFailedDesc:
        "\nThere was a problem with your request to send logs from **{0}**.\n\nPlease try again later or contact SonicWall CSE Support",
    errorMessage: "Error Message",
    errorMessageDescription:
        "A custom message that endusers can see in the browser when they need help.",
    errorNetworkRequestFailed: "Error: Network request failed.",
    errorThereAreDuplicatesSomething: "There are duplicates {0}: {1}",
    errorThereWasAnIssueSettingTheUserAndDeviceInactivityInterval:
        "Error: There was an issue setting the user and device inactiviy interval.",
    errorTrustFactorNotFoundRefreshAndTryAgain:
        "Error: Trust Factor not found. Refresh and try again.",
    errorUnknownIntegrationType: "Error: Unknown integration type.",
    errorUpdatingOtpSkipRole: "Error updating otp skip role.",
    eventActivity: "Event Activity",
    eventChartSubtitle: "Showing an {0} time increment of events from {1} - {2}",
    eventId: "Event ID",
    eventKeyRateLimiting: "Event Key Rate Limiting",
    eventKeyRateLimitingDesc:
        "Enable rate limiting of Access Event generated based on a derived “key” value. Each key has a separate rate limiter, and events with the same key value are subjected to the rate limiter for that key.",
    eventSeverity: "Event Severity",
    eventType: "Event Type",
    events: "Events",
    eventsRateLimiting: "Events Rate Limiting",
    eventsRateLimitingDesc:
        "Enable rate limiting of Access Event generation based on a credit-based rate control mechanism.",
    exampleUrl: "E.g: http://www.example.com",
    exceptionList: "Exception List",
    exclude: "Exclude",
    excludedDevicesPolicyDescription:
        "Determines which devices are excluded from all ITP policies; it can't be removed or re-prioritized.",
    exclusions: "Exclusions",
    exemptSpecificRequestsDescription:
        "Exempt specific requests from the SonicWall CSE TrustCookie check. [Read more here]({0}).",
    exemptedCidrs: "Exempted CIDRs",
    exemptedCidrsOptional: "Exempted CIDRs (optional)",
    exemptedPaths: "Exempted Paths",
    exemptedPathsOptional: "Exempted Paths (optional)",
    exemptionInsecureMessage:
        "The current configuration exempts ALL requests. Please change a ruleset before continuing.",
    exemptionTemplate: "Exempt {0}requests {1} {2} {3} {4} {5}",
    exemptions: "Exemptions",
    exemptionsOptional: "Exemptions (optional)",
    expandAll: "Expand All",
    expandDeviceSecurityPostureCheckDesc:
        "Expand a device’s security posture check by including Trust Factors from your EDR solutions, MDM solutions, and more.",
    expandNav: "Expand Nav",
    expandOptionalConfigurationAndEnterSonicCoreHostname:
        "Expand optional configuration and enter the SonicCore Hostname",
    expiration: "Expiration",
    expired: "Expired",
    expiredAt: "Expired At",
    explain: "Explain",
    exploreSomeLicenseForEnhancedFeatures: "Explore **{0}** for enhanced features.",
    external: "External",
    externalApiKey: "External API Key",
    externalId: "External ID",
    externalJwtKey: "External JSON Web Token (JWT)",
    factorName: "Factor Name",
    factors: "Factors",
    failClosedDescription:
        "FAIL CLOSED - Restrict access to services when Workspace ONE UEM is offline",
    failMode: "Fail Mode",
    failOpenDescription: "FAIL OPEN - Allow access to services when Workspace ONE UEM is offline",
    failed: "Failed",
    failedToAssignSomething: "Failed To Assign {0}",
    failedToAttachPolicy: "Failed to attach policy.",
    failedToAttachSomething: "Failed To Attach {0}",
    failedToChangePassword: "Failed To Change Password",
    failedToCreateApiKey: "Failed to create api key",
    failedToCreateConnector: "Failed To Create a Connector",
    failedToCreateRegisteredDomain: "Failed to create registered domain",
    failedToCreateService: "Failed to create service.",
    failedToCreateServiceAccount: "Failed To Create Service Account",
    failedToCreateSomething: "Failed To Create {0}",
    failedToCreateTrustFactor: "Failed to create a Trust Factor.",
    failedToCreateTrustProfiles: "Failed to create a Trust Profile.",
    failedToCreateUpdatePolilcy: "Failed to create/update policy",
    failedToCreateUser: "Failed to create user",
    failedToDeleteAccessTier: "Failed to Delete AccessTier",
    failedToDeleteCloudResourceService: "Failed to delete cloud resource service",
    failedToDeleteConnector: "Failed To Delete a Connector",
    failedToDeleteDevices: "Failed to delete device(s)",
    failedToDeleteNetAgent: "Failed to delete net agent",
    failedToDeletePolicy: "Failed to delete policy",
    failedToDeleteRegisteredDomain: "Failed to delete registered domain",
    failedToDeleteScimToken: "Failed to delete SCIM token",
    failedToDeleteService: "Failed to delete service.",
    failedToDeleteServiceAccount: "Failed To Delete Service Account",
    failedToDeleteSomething: "Failed To Delete {0}",
    failedToDeleteTrustFactor: "Failed to delete the Trust Factor.",
    failedToDeleteTrustProfiles: "Failed to delete the Trust Profile.",
    failedToDeleteUsers: "Failed to delete users",
    failedToDetachSomething: "Failed To Detach {0}",
    failedToDownloadDevicesCSV: "Failed to download Devices CSV.",
    failedToDownloadTrustIntegrationSyncLogs: "Failed to download trust integration sync logs",
    failedToEditTheTrustEffect: "Failed to edit the Trust Effect.",
    failedToFetchAccessTier: "Failed to fetch AccessTier",
    failedToFetchAccessTiers: "Failed to fetch Access Tiers",
    failedToFetchAdvancedConfiguration: "Failed to fetch advanced configuration",
    failedToFetchApplication: "Failed to fetch application",
    failedToFetchApplications: "Failed to fetch applications",
    failedToFetchChallengeRecord: "Failed to fetch challenge record",
    failedToFetchConnectors: "Failed To Fetch the Connectors",
    failedToFetchConnectorsStats: "Failed to fetch connectors stats",
    failedToFetchCseAppVersions: "Failed to fetch CSE App Versions.",
    failedToFetchLatestVersions: "Failed to fetch latest version",
    failedToFetchPlatformConfiguration: "Failed to fetch platform configuration",
    failedToFetchRegisteredDomain: "Failed to fetch registered domain",
    failedToFetchRegisteredDomains: "Failed to fetch registered domains",
    failedToFetchTrustFactors: "Failed to fetch Trust Factors.",
    failedToFetchTrustProfiles: "Failed to fetch Trust Profiles.",
    failedToFetchTrustedNetworkConfiguration: "Failed to fetch Trusted Network Configuration.",
    failedToFetchUserAndDeviceInformation: "Failed to fetch user and device information",
    failedToFetchUserLogs: "Failed to fetch user logs",
    failedToGenerateChallengeRecord: "Failed to generate challenge record",
    failedToGenerateItpReport: "Failed to generate ITP Report.",
    failedToGenerateRefreshToken: "Failed To Generate Refresh Token",
    failedToGenerateScimCredentials: "Failed to generate SCIM credentials",
    failedToGetCountries: "Failed to get countries",
    failedToGetLicenseUsageInformation: "Failed to get license usage information",
    failedToGetOrgAnalytics: "Failed to get org analytics",
    failedToGetOrgLicense: "Failed to get org license",
    failedToGetOrgLicensesUsage: "Failed to get Org Licenses Usage",
    failedToGetScimCredentials: "Failed to get SCIM credentials",
    failedToGetSomething: "Failed To Get {0}",
    failedToGetSyncStatus: "Failed to get sync status",
    failedToGetTheTrustLevelExpiration: "Failed to get the Trust Level Expiration.",
    failedToGetUserGroup: "Failed to get user group",
    failedToGetVariables: "Failed to get variables",
    failedToLoadAccessActivity: "Failed to load access activity.",
    failedToLoadCluster: "Failed to load cluster.",
    failedToLoadDeleteCertificate: "Failed to Delete Issued Certificate",
    failedToLoadDeviceActivity: "Failed to load device activity.",
    failedToLoadIssuedCertificates: "Failed to Load Issued Certificates",
    failedToLoadLogs: "Failed to load logs.",
    failedToLoadPolicies: "Failed to load policies.",
    failedToLoadServiceBundles: "Failed to load Service Bundles.",
    failedToLoadServices: "Failed to load services.",
    failedToLoadSettings: "Failed to load settings.",
    failedToLoadUserActivity: "Failed to load user activity.",
    failedToLoginToOrganization: "Failed to login to the organization",
    failedToParseJson: "Failed to parse JSON.",
    failedToPublishResource: "Failed to Publish Resource",
    failedToRemoveSomething: "Failed To Remove {0}",
    failedToReorderTrustProfiles: "Failed to reorder Trust Profiles.",
    failedToRequestSomething: "Failed To Request {0}",
    failedToResetMultiFactorAuthentication: "Failed To Reset Multi-Factor Authentication",
    failedToRevokeRefreshToken: "Failed To Revoke Refresh Token",
    failedToSaveOperatingSystemSettings: "Failed to save operating system settings.",
    failedToSaveOrgSettings: "Failed To Save Org Settings",
    failedToSendAccessTierLogs: "Failed To Send Access Tier Logs",
    failedToSwitchToLocalIdp: "Failed to switch to Local IDP",
    failedToTestAccessTierConnection: "Failed to test the AccessTier Connection",
    failedToTestServiceConnection: "Failed to test the Service Connection",
    failedToUpdateAdvancedConfiguration: "Failed to updated advanced configuration",
    failedToUpdateAnOrg: "Failed to update an org",
    failedToUpdateApplicationStatus: "Failed to update application status",
    failedToUpdateArchivalDate: "Failed to update archival date",
    failedToUpdateConnector: "Failed To Update a Connector",
    failedToUpdateOrgLicense: "Failed to update org license",
    failedToUpdateOrgWideDeviceTrustVerification:
        "Failed to update Org Wide Device Trust Verification",
    failedToUpdateRemediation: "Failed to update Remediation.",
    failedToUpdateResourceStatus: "Failed to Update Resource Status",
    failedToUpdateSecurityAction: "Failed to update security action",
    failedToUpdateServiceAccount: "Failed To Update Service Account",
    failedToUpdateServiceStatus: "Failed to update service status.",
    failedToUpdateSomething: "Failed To Update {0}",
    failedToUpdateTheScimProvisioningStatus: "Failed to update SCIM provisioning status",
    failedToUpdateTheTrustLevelExpiration: "Failed to update the Trust Level Expiration.",
    failedToUpdateTrustFactor: "Failed to update Trust Factor.",
    failedToUpdateUrlFilteringStatus: "Failed to update URL Filtering status",
    failedToUpdateUser: "Failed to update user",
    failedToValidateRegisteredDomain:
        "The domain could not be validated. Please make sure the DNS configurations are set correctly. At times, it may take up to 10 minutes before DNS settings are propagated.",
    false: "False",
    faviconExplanation: "Your favicon will be pulled automatically from the URL.",
    fedora: "Fedora",
    fedoraOsVersionFormat: "Fedora OS version format: version (just a number e.g., 36)",
    fedoraOsVersionPlaceholder: "e.g., 36",
    file: "File",
    fileATicket: "File a Ticket",
    fileCheck: "File Check",
    fileCheckDescription: "Satisfied if file exists on a device",
    fileCheckRemediationText:
        "This device does not contain the files listed above. Please reach out to support.",
    fileIsIncludedInTrustProfile_one:
        '"{1}" is included in a Trust Profile. Deleting this File will remove it from the following Trust Profile:',
    fileIsIncludedInTrustProfile_other:
        '"{1}" is included in {0} Trust Profiles. Deleting this File will remove it from the following Trust Profiles:',
    fileName: "File Name",
    fileNameDescription:
        "The file check factor name will be displayed to endusers when it is not satisfied.",
    fileNamePlaceholder: "Name (ex. MDM Registration)",
    fileNotFound: "File not Found",
    filePath: "File Path",
    filePathDescriptionUnix:
        "Note the path can be set relative from root or the user's home directory.",
    filePathDescriptionWindows:
        "Note the path can be set relative from C:\\ or the user's home directory.",
    fileSize: "File Size",
    filterBy: "Filter by",
    filterByASpecificDate: "Filter by a specific date",
    filterByDateRange: "Filter by date range",
    filterByTemplate: "Filter by {0}",
    filterByTimeRange: "Filter by time range",
    firewall: "Firewall",
    firewallDescription: "Satisfied if enabled on the device",
    firewallMacOsRemediationText:
        "Please follow the steps to enable firewall for your macOS device. {0}",
    firewallWindowsRemediationText:
        "Please follow the steps to enable firewall for your Windows device. {0}",
    firstName: "First Name",
    fixedBackendDomain: "Fixed Backend Domain",
    followTheGuidedOvfDeploymentDescription:
        "Follow the guided OVF deployment instructions established by VMware. [Click here for system requirements]({0}).",
    forceAccessDesc:
        "Force access to this resource through a SonicWall CSE Service Tunnel. [Learn more]({0})",
    forgotYourOrgName: "Forgot your Org Name?",
    forgotYourPassword: "Forgot your password?",
    forwardTrustCookie: "Forward Trust Cookie",
    forwardTrustCookieDesc:
        "Forward the SonicWall CSE trust cookie to upstream servers. This may be enabled if upstream servers wish to make use of information in the SonicWall CSE trust cookie.",
    fqdn: "FQDN",
    fqdnIsInvalid: "FQDN format is invalid",
    fqdns: "FQDNs",
    fullName: "Full Name",
    gcp: "GCP",
    generalInformation: "General Information",
    generate: "Generate",
    generateApiToken: "Generate API Token",
    generateMdmDeploymentKey: "Generate MDM Deployment Key",
    generateRefreshToken: "Generate Refresh Token",
    generatedAt: "Generated At",
    generatingItpReport: "Generating ITP Report.",
    genericTcp: "Generic TCP",
    geolocationSettingUpdatedSuccessfully: "Geolocation Setting Updated Successfully",
    getAssistanceTroubleshootingDesc:
        "Get assistance troubleshooting or auditing **{0}** by sending logs recorded by this Access Tier to SonicWall CSE. See our [Privacy Policy]({1}).",
    getHelp: "Get Help",
    giveEndUsersTheAbilityToOverrideTheseSettings:
        "Give end users the ability to override these settings?",
    globalEdge: "Global Edge",
    globalEdgeNetwork: "Global Edge Network",
    globalView: "Global View",
    goAdvancedForMoreCoverage: "Go **Advanced** for more coverage.",
    goBack: "Go Back",
    goBackAndResolveIssues: "Go back and resolve issues",
    grant: "Grant",
    granted: "{0} granted",
    granularTrustMigrationEducationContent:
        "\n## What is changing in SonicWall CSE's Trust Scoring?\n\n* In the current model, all Trust Factors (when enabled) are weighted equally and, when calculated, result in a score out of 100.\n* After migration, admins will be able to designate the specific **Effect** a Factor has on a device's Trust Level.\n\nTrust Scoring configurations, which are currently found under **Settings** in the Command Center, will be located under **Secure Access**.\n\n## Why is SonicWall CSE introducing Granular Trust Scoring?\n\n* To allow admins to test Trust Factors before enforcing them.\n* To allow admins to require satisfaction of certain Trust Factors.\n* To improve transparency in SonicWall CSE's Trust Scoring process.\n\n## Why is migration required?\n\nSonicWall CSE has adopted a new Trust Scoring calculation that is incompatible with the current Trust Scoring model. Therefore, current Trust Factor settings cannot be transferred.\n\n## What do you need to do?\n\n1. Navigate to the **Trust Factors** page.\n2. Assign an **Effect** of each Factor.\n3. Verify that the calculated Trust Level on registered devices meets security expectations.\n",
    greaterThanOrEqualToSign: "≥",
    group: "Group",
    groupAlreadyAssigned: "Group already assigned",
    groupBy: "Group by",
    groupDelimiter: "Group Delimiter",
    groupDelimiterDescription:
        "(Optional) A group delimiter helps CSE identify user groups sent by your IDP. The value entered into this field (e.g., ' ; ') must align with the one already used by your IDP. Leave this field empty unless directed in our documentation",
    groupLevelNetworkSettings: "Group Level Network Settings",
    groups: "Groups",
    groupsAttr: "Groups Attribute",
    groupsDelimAttr: "(optional) Groups Delimiter",
    groupsDelimiter: "Groups Delimiter",
    groupsDescription:
        "This is the Identity Provider claim for the groups attribute. The attribute may be used to assign a role to a user and/or device in CSE",
    hasNetworkSettings: "Has Network Settings",
    headerName: "Header Name",
    helpDocs: "Help Docs",
    helpfulLinks: "Helpful Links",
    here: "here",
    hide: "Hide",
    hideCertificate: "Hide Certificate",
    hideClientSecret: "Hide Client Secret",
    hideConfig: "Hide Config",
    hideDeviceClientSecret: "Hide Device Client Secret",
    hidePublicKey: "Hide Public Key",
    hideSomething: "Hide {0}",
    high: "High",
    highTrustLevel: "High Trust Level",
    highTrustLevelsOnly: "High Trust Levels only",
    home: "Home",
    homePage: "Home Page",
    hostBasedNetagent: "Host-based (Netagent)",
    hostSchemePortRegex: "Enter a URL with a host, scheme & port.",
    hosted: "Hosted",
    hostedWebSummary:
        "**{0}** can be accessed at **{1}** which will route it to **{2}** via **{3}**",
    hostedWebsite: "Hosted Website",
    hostedWebsiteName: "Hosted Website Name",
    hostedWebsites: "Hosted Websites",
    hostname: "Hostname",
    hostnameFound: "Hostname found",
    hostnames: "Hostnames",
    hosts: "Hosts",
    hourly: "hourly",
    hours: "Hours",
    hoursUntilExpired: "Hours Until Expired",
    howAreTheServiceAccountCredentialsBeingSent:
        "How are the Service Account credentials being sent?",
    howDoWeUseTheData: "How do we use the data",
    howDoWeUseTheDataDescription:
        "The data is used to determine the end users geolocation from where they are accessing their protected resource. We use this data to also determine the trust factor of end users devices in embargoed countries.",
    howShouldIncomingConnectionsBeProxiedToTheBackend:
        "How should incoming connections be proxied to the backend?",
    howShouldSonicWallCseHandleUserConnectionsToThisSshService:
        "How should SonicWall CSE handle user connections to this service?",
    howShouldTheServiceAccountCredentialsBeSent:
        "How should the Service Account credentials be sent?",
    http: "HTTP",
    https: "HTTPS",
    iConfirmIWantToSendLogDataDesc:
        "I confirm I want to send log data to SonicWall CSE from this Access Tier",
    iHaveAccessToSonicWallCseInstallerDesc:
        "Access to the [SonicWall CSE installer Github repository]({0}) to download the CloudFormation stack",
    iHaveAwsAccountAndPermissionDesc:
        "An AWS account and permissions to launch EC2 instances, manage AutoScaling, and set Security Groups.",
    iOS: "iOS",
    iOsVersionFormat: "iOS version format: major.minor.patch",
    iOsVersionPlaceholder: "e.g., 14.8.1",
    iUnderstandThatBySendingDescription:
        "I understand that by sending these logs, I am sharing my data with SonicWall CSE",
    iaasDiscovery: "IaaS Discovery",
    iaasInventory: "IaaS Inventory",
    icmp: "ICMP",
    icon: "Icon",
    id: "ID",
    identityAndAccess: "Identity and Access",
    identityProvider: "Identity Provider",
    identityProviderConfig: "Identity Provider Config",
    identityProviderFederated: "Identity Provider Federated",
    identityProviderName: "Identity Provider Name",
    identityProviderProtocol: "Identity Provider Protocol",
    idpCaCertificate: "IDP CA Certificate",
    idpCaCertificateDescription:
        "Enter the SAML certificate from Identity Provider. If you have downloaded the certificate (base64),click Upload to select the certificate and  insert into this field",
    idpConfigConfirmDescription:
        "Are you sure you want to change the Identity Provider Config? Ensure your Identity Provider has been set up correctly to match these configurations.",
    idpIssuer: "IDP Issuer",
    idpMetadataMethod: "IDP Metadata Method",
    idpMetadataMethodDescription:
        "You can either enter the Identity Provider metadata URL, or manually enter values from your Identity Provider ",
    idpMetadataUrl: "IDP Metadata URL",
    idpMetadataUrlDescription:
        "Enter the Identity Provider metadata URL or manually enter values from your Identity Provider",
    idpMetadataUrlOrIdpRawMetadataMustBeSet: "IDP Metadata URL or IDP Raw Metadata must be set.",
    idpRawMetadataPlaceholder: "<Metadata></Metadata>",
    idpRawMetadataXML: "IDP Raw Metadata (XML)",
    idpRouted: "IDP Routed",
    idpRoutedDescription:
        "Secure SaaS Applications    by federating your Identity Provider to SonicWall CSE for Device Trust. Authentication will be completed by your organizations Identity Provider.",
    idpRoutedServiceName: "IDP Routed Service Name",
    idpSettings: "IDP Settings",
    idpSsoUrl: "IDP SSO Url",
    idpSsoUrlDescription: "Enter the SSO URL from the Identity Provider used for login",
    ifConnectivityCheckWasSuccessful:
        "If connectivity check was successful, click next. If it failed, please ensure your network is configured to allow connections specified in the [pre-requisite]({0}) checklist, then retry.",
    ifFactorIsNotSatisfiedTheEffectWillBe: "If **{0}** is not satisfied, the effect will be:",
    ifYourIssuingDescription:
        "If your Issuing CA is an Intermediate CA, upload your organization's Root CA certificate here so it can be distributed along with the Issuing CA.",
    ignore: "Ignore",
    ignoreTrustLevel: "Ignore Trust Level",
    ignored: "Ignored",
    image: "Image",
    importedTrustFactorAndApplicableDeviceTypes:
        "imported Trust Factor And Applicable Device Types",
    inProgress: "In Progress",
    inYourDeploymentToolCreateAVirtualMachineDescription:
        "In your deployment tool (e.g., ESXi, VSphere, VMware Fusion), create a virtual machine, and then select 'Deploy OVF Template'.",
    inactive: "Inactive",
    include: "Include",
    includeDomainEx: "e.g., www.public-domain.com",
    includeExclude: "Include / Exclude",
    includeIpEx: "e.g., 173.45.0.0/16",
    includeOnlyMdmManagedDevices: "Include only MDM-managed devices",
    includesAllSubdomainsEGGamblingComWouldIncludeBetsGamblingCom:
        "Includes all subdomains (e.g gambling.com would include bets.gambling.com)",
    incrementBy45Days: "Increment by 45 days",
    infra: "Infra",
    infrastructure: "Infrastructure",
    infrastructureForRemoteAccessUsingATcpBasedProtocol:
        "Infrastructure - for remote access using a TCP-based protocol",
    infrastructurePolicy: "Infrastructure Policy",
    infrastructurePolicyDescription: "Create a policy for user access to infrastructure services",
    infrastructureServices: "Infrastructure Services",
    infrastructureServicesWithTypes: "Infrastructure services (SSH, RDP, etc)",
    ingressPort: "Ingress Port",
    initialAuth: "Initial Auth",
    insertAUsersEmailAddressOrSelectAGroupThatConsistsOfAUserGroupThenAddTheUsersBelow:
        "Insert a user’s email address or select a group that consists of a user group, then add the users below.",
    insertTime: "Insert Time",
    install: "Install",
    installMethod: "Install Method",
    installationAndDeviceCertParameters: "Installation & Device Cert Parameters",
    installationInstructions: "Installation Instructions",
    installationParameter: "Installation Parameter",
    installationSteps: "Installation Steps",
    installingAccessTierUsing: "Installing the Access Tier using a {0}",
    installingTheConnectorUsingSomething: "Installing the Connector using {0}",
    instance: "Instance",
    instanceName: "Instance Name",
    instances: "Instances",
    instancesWithCount: "Instances ({0})",
    instructionsInTheDocs: "instructions in the docs.",
    integrationDetails: "Integration Details",
    integrationName: "Integration Name",
    integrationPartner: "Integration Partner",
    integrationType: "Integration Type",
    integrations: "Integrations",
    internal: "Internal",
    internalErrorOccurredRefreshTryAgain:
        "We're sorry, an internal error has occurred. Please refresh and try again.",
    internalOnly: "Internal Only",
    internalOwner: "Internal Owner",
    internalPersistent: "Internal Persistent",
    internalTemporary: "Internal Temporary",
    internet: "Internet",
    internetAccess: "Internet Access",
    internetAccessEvents: "Internet Access Events",
    internetThreatProtection: "Internet Threat Protection",
    invalidCidr: "Invalid CIDR",
    invalidCustomerId: "Invalid Customer ID.",
    invalidFqdns: "invalid FQDNs",
    invalidMacAddressDesc: "Invalid MAC Address.",
    invalidPortRange: "invalid port range",
    invalidPorts: "invalid ports",
    invalidToken: "Invalid Token",
    inventoryScreenDescription:
        "Automatically discover and publish infrastructure from IaaS providers to streamline onboarding and provide visibility into secured and unsecured resources.",
    inviteAdminUser: "Invite Admin User",
    inviteCode: "Invite Code",
    inviteCodeConfirmExplanation: "Are you sure you want to update the invite code?",
    inviteCodeRequiredDescription: "Invite code required by users registering devices to this Org:",
    inviteCodeUpdated: "Invite code updated.",
    invited: "Invited",
    invitedAt: "Invited At",
    ip: "IP",
    ipAddresses: "IP Addresses",
    ipRanges: "IP Ranges",
    ipRangesIps: "IP Ranges/IPs",
    ips: "IPs",
    isCompliant: "Is Compliant",
    isCompliantDescription: "Satisfied if the device is compliant",
    isCompliantRemediationText:
        "This device is not compliant with {0}. Please reach out to support.",
    issuedCertificates: "Issued Certificates",
    issuer: "Issuer",
    issuerUrl: "Issuer URL",
    issuerUrlDescription: "Enter the issuer URL provided by your Identity Provider",
    issuingCaCertificate: "Issuing CA Certificate",
    itCanBeDifficultForAdminsDesc:
        "It can be difficult for admins to know which resources need protection. SonicWall CSE providing this insight is one step further in an organization’s zero trust journey.",
    itpPolicies: "ITP Policies",
    itpPolicy: "ITP Policy",
    itpPolicyInvalidMessage: "At least one threat, category, or domain filter must be set.",
    itpReport: "ITP Report",
    jwksEndpoint: "JWKS Endpoint",
    key: "Key",
    keysMustBeUnique: "Keys must be unique.",
    kubeClusterNameDescription:
        "Creates an entry in the SonicWall CSE KUBE config file for this cluster name, and populates the associated configuration parameters.",
    kubeOidcProxyCAPublicKey: "Kube-OIDC-Proxy CA Public Key",
    kubernetes: "Kubernetes",
    kubernetesService: "Kubernetes Service",
    kubernetesServiceDescription:
        "Secure kubectl client access to cloud or on-premise Kubernetes API servers using MTLS and short-lived certificates. Supports integration Kubernetes OIDC authentication, Role Based Access Controls, and advanced audit logging.",
    kubernetesSettings: "Kubernetes Settings",
    last2Versions: "Last 2 versions",
    last3Versions: "Last 3 versions",
    lastAccessed: "Last Accessed",
    lastAuthAccess: "Last Auth Access",
    lastAuthorized: "Last Authorized",
    lastAuthorizedAt: "Last Authorized At",
    lastBatchSync: "Last Batch Sync",
    lastConnectTime: "Last connect time",
    lastEvaluatedForTrustLevel: "Last Evaluated for Trust Level",
    lastLogin: "Last Login",
    lastModified: "Last Modified",
    lastName: "Last Name",
    lastReported: "Last Reported",
    lastSeen: "Last Seen",
    lastSyncLogged: "Last Sync Logged",
    lastUnauthAccess: "Last Unauth Access",
    lastUpdated: "Last Updated",
    lastUpdatedBy: "Last Updated By",
    latestIpAddress: "Latest IP Address",
    latestVersion: "Latest version",
    lax: "Lax",
    learnMore: "Learn more",
    learnMoreAboutSonicWallCsesDifferentPlansDesc:
        "Learn more about SonicWall CSE's different plans and their features in our handy compare tool.",
    learnMoreAboutTheUpcomingChangesHere: "Learn more about the upcoming changes here.",
    learnMoreInDocs: "Learn More in Docs",
    leaveBlankToHideDescription: "Leave blank to hide from service lists shown to end users",
    legacyCompatibilityMode: "Legacy compatibility mode",
    lessThanOneMin: "< 1 min",
    letsEncrypt: "Let's Encrypt",
    license: "{0} License",
    licenseDataText_one: "One {2} {1} License",
    licenseDataText_other: "{0} {2} {1} Licenses",
    licenseDataText_zero: "No {0} Licenses",
    licenseInfo: "License Info",
    licenseType: "License Type",
    licenseUsage: "License Usage",
    licensed: "Licensed",
    licenses: "Licenses",
    licensesDescription:
        "These are the current license types for your organization. To view more information about our licenses, check out our [Licensing documentation]({0}). ",
    licensesInPlan: "Licenses in Plan",
    licensesInUse: "Licenses in Use",
    licensesLimitReached: "Licenses Limit Reached",
    licensesLimitReachedDescription:
        "You have reached the limit of paid Licenses on your account. Continuing without adding more Licenses may impact your End Users' ability to access Services or protect their Devices. To view your current License Usage, see [Licenses]({0}).",
    licensesWarning:
        "Your organization has consumed all purchased Licenses. Failure to add more licenses may result in limited functionality. Contact [sales@sonicwall.com]({0}) to purchase more Licenses.",
    licensing: "Licensing",
    limitTo: "Limit to",
    link: "Link",
    linkShownToEndUsersDescription: "ex. https://support.banyanops.com",
    linkShownToEndUsersOptional: "Link (shown to end users) (optional)",
    linkedCloudResource: "Linked Cloud Resource",
    linkedPolicies: "Linked Policies",
    linkedPoliciesTemplate: "Linked Policies    ({0})",
    linux: "Linux",
    linuxDocker: "Linux Docker",
    linuxTarball: "Linux Tarball",
    listTheAppsThatAreExceptionsToBlocking: "List the applications that are exceptions to blocking",
    listTheDomainsThatAreExceptionsToBlocking: "List the domains that are exceptions to blocking",
    llmAuthentication: "LLM Authentication",
    loading: "Loading",
    loadingAPIKeysToAddConnectors: "Loading API keys to add Connectors",
    loadingFile: "Loading File",
    loadingPropertyList: "Loading Property List",
    loadingRegistryCheck: "Loading Registry Check",
    loadingSomething: "Loading {0}",
    local: "Local",
    localAdminSettings: "Local Admin Settings",
    localAdminSettingsUpdateSuccessful: "Local Admin Settings update successful",
    location: "Location",
    login: "Login",
    loginToADifferentOrgSwitchOrg: "Login to a different Org? <0>Switch Org</0>",
    loginToOrganization: "Login to organization",
    loginViaSamlIdentityProvider: "Login via SAML Identity Provider",
    logo: "Logo",
    logoDescription:
        "Add your organization's logo to appear in browser message pages and in the CSE app. Logo will be scaled to fit within predefined height.",
    logoFileUploadInstruction: "Drag logo image here or browse",
    logoSupportedFormats: "Supported formats: SVG (recommended), PNG, JPG",
    logs: "Logs",
    logsSentDesc:
        "\nLogs from this Access Tier have been exported and sent.\n\nYou can now reference **{0}** logs with the SonicWall CSE Support team.",
    lookupDomain: "Lookup Domain",
    low: "Low",
    lowTL: "Low TL",
    lowTrustLevel: "Low Trust Level",
    lowTrustLevelDescription:
        "If this Factor is not satisfied, the device's Trust Level will be set to low. ",
    mTls: "mTLS",
    macAddressesHere: "MAC Address(es) here",
    macOS: "macOS",
    macOsVersionFormat: "Mac OS version format: major.minor.patch",
    macOsVersionPlaceholder: "e.g., 12.6.3",
    mainMenu: "Main Menu",
    manage: "Manage",
    manageAdmins: "Manage Admins",
    manageAdminsUpgradeDescription:
        "Upgrade to manage Local and SAML-Only administrators. Team edition customers can manage admins from Directory & Infrastructure -> Users.",
    managePACfile: "Manage PAC file",
    managePACfileDesc: "The PAC file is required to enable URL Filtering on a device.",
    manageServices: "Manage Services",
    mandatory: "Mandatory",
    mandatoryHeaders: "Mandatory Headers",
    mandatoryHeadersDescription: "Requests must have these headers to be exempted.",
    mandatoryHeadersOptional: "Mandatory Headers (optional)",
    mandatoryHeadersPlaceholder: "e.g., X-SECRET-AUTH",
    manual: "Manual",
    mdmManagedDevices: "MDM-Managed Devices",
    medium: "Medium",
    mediumOrHighTrustLevelsOnly: "Medium or High Trust Levels Only",
    mediumTL: "Medium TL",
    mediumTrustLevel: "Medium Trust Level",
    mediumTrustLevelDescription:
        "If this Factor is not satisfied, the device's Trust level will be set to medium.",
    message: "Message",
    metadataUrl: "Metadata URL",
    methods: "Methods",
    methodsDescription: "Requests with these Methods will be exempted.",
    metricsCollectionAddress: "Metrics Collection Address",
    metricsCollectionAddressDesc:
        "Address to send statsd messages: “hostname:port” for UDP, “unix:///path/to/socket” for UDS.",
    metricsCollectionAddressExample: "ex. 127.0.0.1:8125",
    mfaStateError:
        "Unable to generate the correct state for this page. Do not link directly to this page.",
    migrate: "Migrate",
    minusShowFewerDevices: "- show fewer devices",
    mixed: "Mixed",
    model: "Model",
    moderate: "Moderate",
    moderateAllowOnlyUsersAndDevicesWithACrowdStrikeZTAof65orHigher:
        "Moderate - allow only users and devices with a CrowdStrike ZTA of 65 or higher",
    modernCloudVpn: "Modern Cloud VPN",
    modernCloudVpnDescription:
        "Provide users with remote access to private and public resources via our modern WireGuard VPN.",
    modifyTheProfileNameThenChangeAssignmentCriteriaAndOrTrustFactors:
        "Modify the Profile Name, then change assignment criteria and/or Trust Factors.",
    mom: "MOM",
    momConsole: "MOM Console",
    monitor: "Monitor",
    more: "More",
    mostActiveUsersByServicesAccessed: "Most Active Users by Services Accessed",
    moveToPackageDirectoryAndRunNetworkConnectivityScript:
        "Move to package directory and run Network Connectivity Script.\n\n *If connections are successful, please proceed to the next step. If check is unsuccessful, please verify network configurations to allow for the connections specified in the [Pre-requisite Checklist]({0})*.",
    msp: "MSP",
    mspConsole: "MSP Console",
    mspOrg: "MSP Org",
    mspOrgAssignment: "MSP Org Assignment",
    mswAuthorizationError: "Admin is not authorized to access SonicWall CSE Command Center.",
    mswConnectingMessage:
        "Connecting to SonicWall CSE Command Center. Please wait as your account is being authenticated.",
    mswInternalError: "An error occurred internally. Reach out to SonicWall for support.",
    multiFactorAuthenticationDesc:
        "Multi-factor authentication normally relies on a password and a one-time passcode; with SonicWall CSE, you can also verify the trust of a device when authenticating.",
    mustSelectOneOrMoreRoles: "Must select one or more Roles.",
    myProfile: "My Profile",
    name: "Name",
    nameAndAssignRolesToYourItpPolicyBelow: "Name and assign Roles to your ITP Policy below.",
    nameDescribeAndAssignRolesToYourItpPolicyBelow:
        "Name, describe, and assign Roles to your ITP Policy below.",
    nameIdCustomAttribute: "NameID Custom Attribute",
    nameIdFormat: "Name ID Format",
    nameIdValueType: "Name ID Value Type",
    nameThisServiceTunnel: "Name this Service Tunnel",
    nameThisTunnelPolicy: "Name this Tunnel Policy",
    nameYourAccessGroup: "Name your Access Group",
    nameYourITPPolicy: "Name your ITP Policy",
    nameYourRole: "Name your Role",
    nameYourTrustProfile: "Name your Trust Profile",
    netAgent: "Netagent",
    netagentDetails: "Netagent Details",
    netagentInstance: "Netagent Instance",
    network: "Network",
    networkConfiguration: "Network Configuration",
    networkRequirementsForConnectorInstall: "Network Requirements for Connector Install",
    networkSettingOptional: "Network Settings (optional)",
    networkSettings: "Network Settings",
    networks: "Networks",
    newAccessGroup: "New Access Group",
    newAdmin: "New Admin",
    newApiKey: "New API Key",
    newPassword: "New Password",
    newRule: "New Rule",
    newSomething: "New {0}",
    newUser: "New User",
    next: "Next",
    no: "No",
    noAccessTierFoundSelectADifferentCluster: "No Access Tier found. Select a different cluster.",
    noAssignment: "No Assignment",
    noButFactorThisIntoTheTrustLevel: "No, but factor this into the Trust Level",
    noContentCategoriesAssociatedWithDomain:
        "No Content Categories are associated with this domain.",
    noDataToDisplay: "No data to display.",
    noEffect: "No Effect",
    noEffectDescription:
        "If this Factor is not satisfied, it will have no effect on the device's Trust Level, but will be displayed in the app. This Factor is primarily used for testing purposes.",
    noOfUsers: "{0} Users",
    noPolicy: "No Policy",
    noRemainingSiaLicenseToGrant:
        "No remaining SIA Licenses to grant. Contact sales@sonicwall.com to purchase Licenses.",
    noRemainingSpaLicenseToGrant:
        "No remaining SPA Licenses to grant. Contact sales@sonicwall.com to purchase Licenses.",
    noRemainingSpaOrSiaLicenseToGrant:
        "No remaining SPA or SIA Licenses to grant. Contact sales@sonicwall.com to purchase Licenses.",
    noRolesFoundPleaseCreateARole: "No roles found. Please create a role.",
    noRowstoShow: "No Rows To Show",
    noSmartResultsReturned: "Sorry, no results. Try rephrasing your question.",
    noStatusToShow: "No Status To Show",
    noSupportBundlesHaveYetToBeRequestedMessage:
        "No Support Bundles have yet to be requested. With them, you can review logs and diagnose issues in this Org.",
    noThreatCategoriesAssociatedWithDomain: "No Threat Categories are associated with this domain.",
    noTrustLevelIgnoreTrustScore: "No Trust Level - ignore TrustScore",
    noTrustProfileMatchesThisDevice: "No Trust Profile matches this Device",
    none: "None",
    notActiveThreat: "Not Active Threat",
    notActiveThreatDescription: "Satisfied if no threats are detected",
    notApplicableWillBeRedirectedToHTTPS: "N/A (will be redirected to HTTPS)",
    notEval: "Not Eval",
    notEvaluated: "Not Evaluated",
    notEvaluatedDescription:
        "This Factor will not be used in the Trust Level calculation. Also, the Factor will not be displayed in the app.",
    notJailbroken: "Not Jailbroken",
    notJailbrokenDescription: "Satisfied if not jailbroken",
    notLicensed: "Not Licensed",
    notes: "Notes",
    nrptConfigUpdatedSuccessfully: "NRPT Config updated successfully.",
    observe: "Observe",
    oci: "OCI",
    oidc: "OIDC",
    oidcDiscoveryEndpoint: "OIDC Discovery Endpoint",
    oidcSettings: "OIDC Settings",
    oidcSettingsDescription:
        "SonicWall CSE TrustProvider is a Federated OpenID Connect (OIDC) Provider that uses identity federation to defer user authentication to your organization’s Identity Provider. An authenticated user receives a SonicWall CSE TrustProvider-issued IDToken containing standard claims including the identity of the user, the identity of the device, which client app logged the user in, and when the token expires. [See our docs]({0}).",
    ok: "Ok",
    okta: "Okta",
    oldServiceAccountDescription:
        "Service Accounts are used to grant programmatic access from privileged applications to your SonicWall CSE registered services.",
    onPremGateway: "On-Prem Gateway",
    onceDeletedAllRelevantDataAboutThisTrustProfileWillBeRemovedFromSonicWallCse:
        "Once deleted, all relevant data about this Trust Profile will be removed from SonicWall CSE. This data is not recoverable. Are you sure you want to delete this Trust Profile?",
    oneLogin: "OneLogin",
    oneOrMoreOfTheSelectedPrivateCIDRsCollideRemoveANetworkToContinue:
        "One or more of the selected Private CIDRs collide. Remove a network to continue.",
    oneTimeKey: "One-time Key",
    oneTimeKeyExpiration: "One-time Key Expiration",
    oneTimePasscode: "One-Time Passcode",
    onlyAllowTheFollowingRoles: "Only allow the following role(s):",
    onlyAllowUsersAndDevicesWIthTheFollowingTrustLevels:
        "Only allow users and devices with the following Trust levels:",
    onlyIncludeDevicesThatHaveBeenRegisteredWithSonicWallCse:
        "Only include devices that have been registered with SonicWall CSE.",
    onlyIncludeDevicesWithCseAppDeployedByMDM: "Only include devices with CSE App deployed by MDM.",
    onlyIncludeDevicesWithTheFollowingProperties:
        "Only include devices with the following properties:",
    onlyUseTheSshCert: "Only use the SSH Cert",
    onlyUseTheTrustCert: "Only use the TrustCert",
    openIdConnectSettings: "OpenID Connect Settings",
    operatingSystemSelectionForAtLeastOneFactorIsRequired:
        "Operating system selection for at least one factor is required.",
    operatingSystemSelectionIsRequired: "Operating system selection is required.",
    operatingSystemVersion: "Operating System Version",
    operatingSystemVersionDescription: "Satisfied if version is running on the device",
    operatingSystemVersionFactorRequiresThatAllPlatformsBeConfigured:
        "Operating System Version factor requires that all platforms be configured.",
    operatingSystemVersionLinuxRemediationText:
        "Please follow the steps to update the Linux version on your device. {0}",
    operatingSystemVersionMacOsRemediationText:
        "Please follow the steps to update the macOS version on your device. {0}",
    operatingSystemVersionWindowsRemediationText:
        "Please follow the steps to update the Windows version on your device. {0}",
    operatingSystemVersionsDescription:
        "Select the Operating System Versions that the Trust Level algorithm should consider as up-to-date:",
    operatingSystems: "Operating Systems",
    operator: "Operator",
    or: "or",
    orGreater: "or greater",
    oracleLinux: "Oracle Linux",
    oracleLinuxOsVersionFormat: "Oracle Linux OS version format: major.minor",
    oracleLinuxVersionPlaceholder: "e.g., 8.3",
    orgData: "Org Data",
    orgDetails: "Org Details",
    orgId: "Org ID",
    orgIsProvisioningPleaseWait: "Org is provisioning please wait.",
    orgManagement: "Org Management",
    orgName: "Org Name",
    orgNameRequirements:
        "Org Name must be between 4 and 63 characters, begin with a letter, end with either a letter or a number, and contain no uppercase letters nor special characters excluding '-'",
    orgNameSelectDescription: "Please enter your Org Name (or URL)",
    orgSettingsUpdateSuccessful: "Org Settings update successful.",
    orgWideDeviceTrustVerificationSuccessfullyUpdated:
        "Org Wide Device Trust Verification successfully updated",
    organization: "Organization",
    organizationAdminDashboard: "Organization Admin Dashboard",
    organizationName: "Organization Name",
    organizationType: "Organization Type",
    organizationWithNoGlobalEdge:
        "Your organization is set up to use a Self-Hosted Private Edge via deploying Access Tier's. [Contact]({0}) support to enable a connector based deployment. Click [here]({1}) to learn about deployment models.",
    organizationWithNoPrivateEdge:
        "Your Organization is set up to use the Global Edge via deploying Connectors. [Contact]({0}) support to enable an Access Tier based deployment. Click [here]({1}) to learn about deployment models.",
    origin: "Origin",
    originDomain: "Origin Domain",
    originDomainPlaceholder: "ex. * or https://origin.my-domain.com:443",
    originHeader: "Origin Header",
    originHeaderDescription: "Requests with these origin headers will be exempted.",
    originHeaderPlaceholder: "e.g., https://domain-a.corp.internal.com:443",
    os: "OS",
    osVersion: "OS Version",
    other: "Other",
    otherExistingAccessTiersCannotBeAdded:
        "Other existing Access Tiers cannot be added to an Access Tier Group. See the details below to learn more.",
    otherTCP: "Other TCP",
    otherTCPService: "Other TCP Service",
    otherTCPServiceDescription:
        "Many internal infrastructure services run over TCP services. SonicWall CSE can secure communications to such “Generic” TCP services using MTLS tunnels and short-lived x509 certificates.",
    otpBasedEmailVerification: "OTP-based Email Verification",
    otpBasedEmailVerificationDescription:
        "Users are required to verify their email via a One-Time-Passcode (OTP) when registering a device using the CSE App. The following users are exempt from the OTP-based email verification, by Role:",
    otpSkipRoleUpdated: "OTP skip role updated.",
    overridden: "Overridden",
    overview: "Overview",
    overviewDetailsCard: "Overview Details Card",
    owner: "Owner",
    ownership: "Ownership",
    pacFileWasUploadedSuccessfully: "PAC file was uploaded successfully.",
    parameterName: "Parameter Name",
    partial: "Partial",
    partialSuccess: "Partial Success",
    partiallyReporting: "Partially Reporting",
    partiallyReportingExplanation:
        "The Connector has reported to the Command Center in the past 7 minutes, but it is disconnected from at least one POP.",
    password: "Password",
    passwordRequirementsDescription:
        "Please ensure your new password is at least 8 characters, and at least one number, uppercase letter, and lowercase letter.",
    passwordResetEmailExplanation:
        "Success! If your account is in our system, you will receive a password reset email. Check your inbox.",
    passwordSuccessfullyChanged: "Password successfully changed",
    passwordless: "Passwordless",
    passwordlessAuthentication: "Passwordless Authentication",
    passwordlessAuthenticationDescription:
        "Enable service level passwordless to authenticate the user via the SonicWall CSE certificate.",
    passwordlessDescription:
        "For Passwordless Authentication, SonicWall CSE TrustProvider creates a special App Client that issues an IDToken based solely on the trusted Device Certificate. Use SonicWall CSE’s Passwordless capability in conjunction with your Identity Provider’s MFA capability to provide users with a simpler login experience and improved usability.",
    passwordlessUpgradeDescription:
        "Enable users to seamlessly log in via your Identity Provider without entering a username/password",
    paths: "Paths",
    pathsDescription: "Requests targeting these paths will be exempted.",
    pathsOptional: "Paths (optional)",
    pathsPlaceholder: "e.g., /internal/api",
    pending: "Pending",
    pendingExplanation:
        "The Connector was created but has not reported into the Command Center to receive its configuration.",
    permissive: "Permissive",
    persistent: "Persistent",
    platform: "Platform",
    platforms: "Platforms",
    pleaseCheckDocumentForPreReqAndMoreDetails:
        "Please check [this document]({0}) for Pre-requisites and more details.",
    pleaseChooseBetweenOneOrBothOfFollowingOptions:
        "Please choose between one or both of the following options:",
    pleaseContactYourAdministratorIfYouHaveAnyQuestions:
        "Please contact your administrator if you have any questions.",
    pleaseDetachPoliciesAndThenTryAgain: "Please detach policies and then try again",
    pleaseEnterAValidPort: "Please enter a valid port",
    pleaseEnterYourEmailAddress: "Please enter your Email Address",
    pleaseFillOutOneOrBothOfTheseFields: "Please fill out one or both of these fields",
    pleaseFillOutThisField: "Please fill out this field",
    pleaseInstallTheCseAppAndRegisterYourDevice:
        "Please install the CSE App from https://getbanyan.app and register your device.",
    pleaseSelectACertificate: "Please select a certificate",
    pleaseSelectANetworkAbove: "Please select a Network above.",
    pleaseSelectATypeThatIsNotDeprecated: "Please select a type that is not deprecated.",
    pleaseSelectTheApiKeyWithSatelliteScopeYouWantToUseForThisInstallation:
        "Please select the API key (with satellite scope) you want to use for this installation.",
    plistIsIncludedInTrustProfile_one:
        '"{1}" is included in a Trust Profile. Deleting this Property List will remove it from the following Trust Profile:',
    plistIsIncludedInTrustProfile_other:
        '"{1}" is included in {0} Trust Profiles. Deleting this Property List will remove it from the following Trust Profiles:',
    plusNumberMore: "+ {0} more",
    policies: "Policies",
    policiesProvideAccessToUsersAndDevicesDescription:
        "Policies provide access to users and devices with minimum Trust Level requirements.",
    policy: "Policy",
    policyActions: "Policy Actions",
    policyActionsDesc: "Block, Allow, or Remove your app from the selected policy.",
    policyAttached: "Policy Attached",
    policyAuthor: "Policy Author",
    policyDefinition: "Policy Definition",
    policyDeleteDisabledDescription:
        "To delete this policy, it must be detached from all services.",
    policyDescription:
        "SonicWall CSE Policies determine which user or workload entities have authorized access to resources of a Service, based on their Role. Choose a type to create below.",
    policyDetails: "Policy Details",
    policyEnforcing: "Policy Enforcing",
    policyName: "Policy Name",
    policyNotFound: "Policy Not Found",
    policyPermissive: "Policy Permissive",
    policyResourceDescription:
        "A list of applications level resources. Each resource can have a wildcard prefix, suffix, or both. Use a prefix of “!' to DENY which overrides any other rule that allows access",
    policyResourcePlaceholder: "ex. * or !/wp-login*",
    policyStatus: "Policy Status",
    policyType: "Policy Type",
    port: "Port",
    ports: "Ports",
    postAuthRedirectPath: "Post Auth Redirect Path",
    postAuthRedirectPathDescription: "Redirect the user to the following path after authentication",
    preRequirementChecklist: "Pre-requirement checklist",
    preferredAppsExclusionDescription:
        "This Trust Factor is applied to the following device ownership(s):",
    premiumFeature: "premium feature",
    preventUsersFromChoosingServiceTunnelToConnectOnLogin:
        "Prevent users from choosing a Service Tunnel to Connect on Login",
    preventUsersFromChoosingServiceTunnelToConnectOnLoginDesc:
        "Enabling will prevent users from changing the Service Tunnel selected to Connect on Login (via their CSE app).",
    primaryColor: "Primary Color",
    primaryColorDescription:
        "This color will appear on the top bar of message pages displayed to endusers.",
    prioritizeItpPolicies: "Prioritize ITP Policies",
    prioritizeTrustProfiles: "Prioritize Trust Profiles",
    priority: "Priority",
    privateAccess: "Private Access",
    privateAccessEvents: "Private Access Events",
    privateAddress: "Private Address",
    privateCIDRs: "Private CIDRs",
    privateCertificateAuthorityCA: "Private Certificate Authority (CA)",
    privateCertificateAuthorityCADesc:
        "A private Certificate Authority (CA) issues all certificates for your devices, clients, and Hosts. We provide a fully-managed Public Key Infrastructure (PKI) for your organization and    SSH CA functionality.",
    privateDomain: "Private Domain",
    privateDomainName: "Private Domain Name",
    privateDomains: "Private Domains",
    privateEdge: "Private Edge",
    privateEdgeNetwork: "Private Edge Network",
    privateEdgeShield: "Private Edge Shield",
    privateInfo: "Private Info",
    privateIp: "Private IP",
    privateKeyFilePath: "Private Key File Path",
    privateKeyFilePathPlaceholder: "e.g., /path/to/privatekey.pem",
    privateNetworkPlaceholder: "[Private Network(s)]",
    privateResource: "Private Resource",
    privateResourceDiscovery: "Private Resource Discovery",
    privateResources: "Private Resources",
    privateSearchDomains: "Private Search Domains",
    privateSearchDomainsDesc:
        "Search Domains are added in priority order, with the first Search Domain provided as priority 1. See Operating System guidance for limitations",
    privateUrlPlaceholder: "[Private URL]",
    proceedWithUnvalidatedAccessTier: "Proceed with unvalidated Access Tier",
    processName: "Process Name",
    processNames: "Process Names",
    production: "Production",
    profile: "Profile",
    profilePriority: "Profile Priority",
    profiles: "Profiles",
    prompt: "Prompt",
    propertyList: "Property List",
    propertyListCheck: "Property List Check",
    propertyListKey: "Property List Key",
    propertyListKeyPlaceholder: "Key (ex. Active)",
    propertyListNamePlaceholder: "Name (ex: Auto-Timezone)",
    propertyListNotFound: "Property List not Found",
    propertyListPathPlaceholder: "Path (ex. Library/Preferences/com.apple.timezone.auto.plist)",
    propertyListValue: "Property List Value",
    propertyListValuePlaceholder: "Value (ex. 1)",
    protectUsersFromPhishingAttacksMaliciousWebsitesOrRansomwareByFilteringDomains:
        "Protect users from phishing attacks, malicious websites, or ransomware by filtering domains.",
    protectYourInfraServicesDesc:
        "Protect your infrastructure services via zero-trust access and continuous authorization.",
    protectYourOrganizationUpgradeToAccessThisFeature:
        "Protect your organization: Upgrade to instantly access this feature",
    protectYourWebServicesDesc:
        "Protect your web services via zero-trust access and continuous authorization.",
    protected: "Protected",
    protocol: "Protocol",
    protocols: "Protocols",
    provideAtLeastOneProcessNameToEnableAnApplicationCheckForZoom:
        "Provide at least one Process Name to enable an Application Check for {0}",
    provideGranularProtectionForSpecificURLPathsWithinADomain:
        "Provide granular protection for specific (URL) paths within a domain.",
    provideMethodsThatLimitDesc:
        "Provide methods that limit which devices can access SonicWall CSE-protected resources, without downloading the app and registering the device to SonicWall CSE.",
    provideNetworkMacAddresses: "Provide network MAC address(es)",
    provideTheMacAddressOfYourCorporateNetworkDesc:
        "Provide the MAC address of the trusted network(s) separated by commas. (E.g. 12-34-56-78-AB-12 , 12:34:56:78:AB:12)",
    provideTheMacAddressValidationError:
        "Provide the MAC address(s) separated by commas. (E.g. 12-34-56-78-AB-12 , 12:34:56:78:AB:12)",
    providerName: "Provider Name",
    providerNameDescription:
        "Select Identity Provider for end user authentication (e.g. Microsoft Entra ID, Okta)",
    providerProtocol: "Provider Protocol",
    providerProtocolDescription: "Select the Identity Provider protocol",
    providesAListOfAllDiscoveredIaasResourcesDesc:
        "Provides a list of all discovered IaaS resources that SonicWall CSE can protect, by publishing them as a service.",
    provisioning: "Provisioning",
    provisioningFailed: "Provisioning Failed",
    publicAddress: "Public Address",
    publicAddressOfAccessTier: "Public Address of Access Tier",
    publicAddressOfAccessTierDesc:
        "The canonical domain name of the server where you installed the Access Tier. It’ll be a public domain name or public IP reachable by the internet.",
    publicAddressOfAccessTierPlaceholder:
        "Enter public domain or pubic IP address (e.g, corp.example.com)",
    publicAddressReachableOnIPAndPort: "Public Address is reachable on selected IP and Port",
    publicAddressResolved: "Public Address is resolved",
    publicAddressShouldNotContainAnySpaces: "Public address should not contain any spaces",
    publicCidrPlaceholder: "Enter address in CIDR format (ex. 35.225.191.40/30)",
    publicDomainName: "Public Domain Name",
    publicDomains: "Public Domains",
    publicExcludeInfo: "Public Exclude Info",
    publicIncludeInfo: "Public Include Info",
    publicIp: "Public IP",
    publicIpRanges: "Public IP Ranges",
    publicResourcesDesc: "View an inventory and status of connected applications",
    publicUrlDomain: "Public URL Domain",
    publicUrlForThisService: "Public URL for this service",
    publicUrlPlaceholder: "[Public URL]",
    publicUrlSubdomain: "Public URL Subdomain",
    publishAsService: "Publish as a Service",
    publishNewService: "Publish New Service",
    publishSaasApplication: "Publish SaaS Application",
    published: "Published",
    publishedFromResource: "Published from Resource: {0}",
    publishingHostedWebsiteDescription:
        "Publishing a Hosted Website provides private or public web service access to a set of end users via the CSE app.",
    queryParameter: "Query Parameter",
    rdp: "RDP",
    rdpPropertiesAvailableForTheRdpFileCanBeFoundHere:
        "RDP properties available for the RDP file can be found [here]({0})",
    rdpService: "RDP Service",
    rdpServiceDescription:
        "Secure access to an individual RDP Server or a collection of RDP servers using MTLS and short-lived certificates. Supports integrating with RD Gateway for providing access to many RDP servers with one SonicWall CSE service definition.",
    reachability: "Reachability",
    reachable: "Reachable",
    readMoreAboutEnforcingVsPermissive: "Read more about [Enforcing vs Permissive]({0}) Policies.",
    readOnly: "Read Only",
    recommendations: "Recommendations",
    records: "Records",
    recordsTargeted: "Records Targeted",
    redirectLink: "Redirect Link",
    redirectUri: "Redirect URL",
    redirectUrl: "Redirect URL",
    redirectUrlDescription:
        "This URL/URI redirects users to the Cloud Secure Edge upon successful authentication (via your IDP)",
    redirectUrlSamlAcs: "Redirect URL (SAML ACS)",
    refresh: "Refresh",
    refreshToken: "Refresh Token",
    refreshTokenDescriptionForLocalUser:
        "Need a long-lived credential for scripts or testing? Generate a personal Refresh Token, store it securely, and use it to [access the SonicWall CSE API]({0}).",
    refreshTokenDescriptionForSamlUser:
        "Need a long-lived credential for scripts or testing? Create a local SonicWall CSE admin account to generate a personal Refresh Token, store it securely, and use it to [access the SonicWall CSE API]({0}).",
    refreshTokenOnceExplanation:
        "This is the only time you will be shown this token. Please copy and save this token in a secure location. YOU WILL NOT BE ABLE TO ACCESS THIS TOKEN AGAIN.",
    refreshTokenPreviousExplanation:
        "Your refresh token has been previously generated for this organization.",
    refreshTokenPreviousWarningForSamlUser:
        "Please note that you ***will not*** be able to create a new refresh token under this account if you revoke it.",
    regenerate: "Regenerate",
    regenerationOfScimApiToken: "Regeneration of SCIM API Token",
    regenerationOfScimApiTokenDesc:
        "Regenerating your SCIM API token will revoke your existing token, thus terminating all current SCIM connections. To maintain SCIM provisioning, you will need to update your SCIM providers with the new API Token.",
    region: "Region",
    register: "Register",
    registerAHostedWebsite: "Register a Hosted Website",
    registerApp: "Register App",
    registerCseFederatedSaasService: "Register CSE Federated SaaS Service",
    registerIdpRoutedService: "Register IDP Routed Service",
    registerNewService: "Register New Service",
    registerService: "Register Service",
    registerYourFirstHostedWebsite: "Register Your First Hosted Website",
    registerYourFirstHostedWebsiteDesc:
        "Create a Hosted Website to provide access to a private web application in your network. Click [here]({0}) to learn more.",
    registered: "Registered",
    registeredDevice: "Registered Device",
    registeredDomain: "Registered Domain",
    registeredDomainExample: "e.g., mydomain.mycompany.com or *.mycompany.com",
    registeredDomainNotFound: "Registered Domain Not Found",
    registeredDomains: "Registered Domains",
    registeredServiceDescription:
        "A registered service listens on a specified IP & port. It will then accept, reject, or proxy those requests based on its configuration. A policy can be attached to a service to enforce access control. Choose a type to create below.",
    registeredServices: "Registered Services",
    registeredWith: "Registered With",
    registeredWithDescription: "Satisfied if the device is enrolled",
    registeredWithRemediationText:
        "This device is not registered with {0}. Please reach out to support.",
    registryCheck: "Registry Check",
    registryCheckDescription: "Satisfied if registry keys match expected values",
    registryCheckIsIncludedInTrustProfile_one:
        '"{1}" is included in a Trust Profile. Deleting this Registry Check will remove it from the following Trust Profile:',
    registryCheckIsIncludedInTrustProfile_other:
        '"{1}" is included in {0} Trust Profiles. Deleting this Registry Check will remove it from the following Trust Profiles:',
    registryCheckNamePlaceholder: "Name (e.g., Windows Build Number)",
    registryCheckNotFound: "Registry Check not Found",
    registryCheckRemediationText:
        "This device does not match the values for the registry keys listed above. Please reach out to support.",
    registryKey: "Registry Key",
    registryKeyDescription:
        "Values can be matched using regex if the input starts and ends with a forward slash (e.g., /regex/). Value matching is case-insensitive.",
    registryKeyPath: "Registry Key Path",
    registryKeyPathDescription:
        "This is the Windows OS path that refers to the key location. Use the absolute path containing the root key.",
    registryKeyPathPlaceholder:
        "Registry Key Path (e.g., SOFTWARE\\Microsoft\\Windows NT\\CurrentVersion)",
    registryKeyPlaceholder: "Registry Key (e.g., CurrentBuildNumber)",
    registryKeyValuePlaceholder: "Value (e.g., 22621)",
    reissueCertificate: "Reissue Certificate",
    relativePathRegex: "Enter a relative path that starts with a forward-slash (e.g., /my/path).",
    relevantHelpDocs: "Relevant Help Docs:",
    remediation: "Remediation",
    remediationMessagingForDesktopApps: "Remediation messaging for CSE apps",
    remediationMessagingForDesktopAppsDescription:
        "The following Trust Attribute remediation instructions appear on SonicWall CSE's App. You can customize the remediation text and support links.",
    remoteDiagnosticLogsCollectedTimestamp: "Remote Diagnostic Logs Collected Timestamp",
    remoteDiagnosticRequestTimestamp: "Remote Diagnostic Request Timestamp",
    remoteDiagnostics: "Remote Diagnostics",
    remoteDiagnosticsDesc:
        "Run advanced diagnostics on this specific device to collect log data for debugging.",
    remoteDiagnosticsPendingMsg:
        "The Run Diagnostics script has been initiated and is being executed.",
    remoteDiagnosticsSuccessMsg: "The Run Diagnostics script was executed successfully.",
    remove: "Remove",
    removeAccessGroup: "Remove Access Group",
    removeAll: "Remove all",
    removeAttribute: "Remove Attribute",
    removeDeviceDisabledExplanation: "You must select one or more devices to remove",
    removeDevices: "Remove Devices(s)",
    removeFromOrganization: "Remove from Organization",
    removeMultipleUsersExplanation: "Are you sure you want to remove the following users",
    removeNetwork: "Remove Network",
    removeOrgFromMspConfirmationMessage:
        "Are you sure you want to remove this Organization from its current MSP Organization assignment?",
    removeRule: "Remove Rule",
    removeSomething: "Remove {0}",
    removeUserAttributeFromPrivateDomainsToTestTheConnection:
        "Remove user attribute from Private Domain to test the connection",
    removeUserAttributeFromPrivateUrlToTestTheConnection:
        "Remove user attribute from private URL to test the connection",
    removeUserDisabledExplanation: "You must select one or more users to remove",
    removeUserExplanation: "Are you sure you want to remove {0}?",
    removeUsers: "Remove User(s)",
    removeValue: "Remove {0}",
    reorder: "Reorder",
    reorderingOfTrustProfilesCanTakeUpTo10MinutesToShowOnDevices:
        "Reordering of Trust Profiles can take up to 10 minutes to show on devices.",
    reporting: "Reporting",
    reportingExplanation:
        "The Connector has reported to the Command Center within the past 7 minutes and it has a connection established with each POP.",
    requestSomething: "Request {0}",
    requestedAt: "Requested At",
    resendCode: "Resend Code",
    resetMfa: "Reset MFA",
    resetMultiFactorAuthentication: "Reset Multi-Factor Authentication",
    resetMultiFactorAuthenticationMessage:
        "Are you sure you want to reset this User's Multi-Factor Authentication?",
    resetPassword: "Reset Password",
    resetPasswordExplanation:
        "Enter the email address that you use to sign in. We'll send you an email with a link to reset your password.",
    resetSuccessful: "Reset Successful",
    resetYourPassword: "Reset Your Password",
    "resource(s)": "Resource(s)",
    resourceIps: "Resource IPs",
    resourceName: "Resource Name",
    resourceServiceDeletionExplanation:
        "Are you sure you want to delete this Resource-Service association? You cannot undo this action.",
    resourceStatus: "Resource Status",
    resourceType: "Resource Type",
    responseRedirect: "302 Redirect",
    responseType: "Response Type",
    responseUnauthorized: "401 Unauthorized",
    retry: "Retry",
    returnToSonicWall: "Return to SonicWall",
    revoke: "Revoke",
    revokeMdmDeploymentKey: "Revoke MDM Deployment Key",
    revokeRefreshToken: "Revoke Refresh Token",
    revokeRefreshTokenExplanation:
        "Any applications or scripts using this token will no longer be able to access the SonicWall CSE API. You cannot undo this action.",
    revoked: "Revoked",
    rfc1918Addresses: "RFC 1918 Addresses",
    rfcAddressCidrPlaceholder:
        "Enter a network within the RFC 1918 address space in CIDR format (ex: 10.0.0.0/8)",
    role: "Role",
    roleAttributes: "Role Attributes",
    roleAttributesDescription:
        "A role will only be assigned to a device if all of these attributes match.",
    roleName: "Role Name",
    roleNameMustBeAlphanumeric: "Role Name must be alphanumeric, '_' or '-'",
    roles: "Roles",
    rolesAssociatedWithDevice: "Roles Associated With Device:",
    rolesDescription:
        "Roles help to manage the different levels of access that groups of entities have to a particular service. [Learn more]({0}).",
    rolesMustHaveAtLeastOneAttribute: "Roles must have at least one attribute.",
    rootCaCertificate: "Root CA Certificate",
    "rules)ForProtocolsCIDRsAndPorts": "rules) for Protocols, CIDRs, and Ports",
    runDiagnostics: "Run Diagnostics",
    runNetagentInstallScript: "Run the Netagent install script",
    saas: "SaaS",
    saasApp: "SaaS App",
    saasAppServiceId: "SaasApp ServiceId (UUID)",
    saasApplicationName: "SaaS Application Name",
    saasApplications: "SaaS Applications",
    saasApps: "SaaS Apps",
    saasClientApplicationConfiguration: "SaaS Client Application Configuration",
    saasRegistrationDescription:
        "To complete registration for this SaaS Application, please [see the docs]({0}). You'll need the config information shown below.",
    saasScreenDescription:
        "Enable Zero Trust policy-based access controls for your SaaS applications when you upgrade.",
    saasUrl: "SaaS Url",
    saasUrlPlaceholder: "Enter SaaS Url",
    saml: "SAML",
    samlAcs: "SAML ACS",
    samlLoginDescription:
        "Your organization has SAML Authentication enabled. After you login with your Identity Provider you will be redirected back to your SonicWall CSE dashboard.",
    samlOnly: "SAML-Only",
    samlOrganizationDescription:
        "Your organization is configured for Single Sign On using SAML 2.0.",
    samlProxyUrl: "SAML Proxy URL",
    satisfiedIfPropertyListExistsAndOrkeysMatchExpectedValues:
        "Satisfied if Property List exists and/or keys match expected values.",
    satisfyTrustFactor: "Satisfy Trust Factor",
    satisfyTrustFactorIfDeviceLocationCannotBeDetermined:
        "Satisfy the Trust Factor if the Device’s location cannot be determined",
    save: "Save",
    saveAndContinue: "Save and Continue",
    saveAndExit: "Save & Exit",
    saveAndValidate: "Save & Validate",
    saveChanges: "Save Changes",
    saveUpdates: "Save Updates",
    scimProvisioning: "SCIM Provisioning",
    scimProvisioningDesc:
        "Ensure that an Identity Provider has been configured to authenticate your end users. Once these settings are configured, SonicWall CSE's local user management will no longer be available to use.",
    scope: "Scope",
    screenLock: "Screen Lock",
    screenLockDescription: "Satisfied if enabled on the device",
    scriptRetrievalFailure:
        "Error: Diagnostic logs were uploaded, but additional diagnostic scripts could not be run.",
    scriptUploadFailure: "Error: The client failed to upload the diagnostic logs.",
    seamlessJourneyToServicesModel: "Seamless Journey to Services Model",
    seamlessJourneyToServicesModelDescription:
        "Migrate workloads from your VPN to a service protected by zero-trust architecture.",
    search: "Search",
    searchByName: "Search by Name",
    searchByOrgName: "Search by Org Name",
    searchFilter: "Search filter",
    searchResult: "Search Result",
    searchUserPlaceHolder: "Type to search and select user",
    secret: "Secret",
    secretKey: "Secret Key",
    secureInternetAccessPlans: "Secure Internet Access (SIA) Plans",
    secureNetworkAccess: "Secure Network Access",
    secureNetworkAccessDescription: "Manage how your cloud VPN is deployed to end users.",
    securePrivateAccessPlans: "Secure Private Access (SPA) Plans",
    secureThisResourceDesc:
        "Secure this resource by SonicWall CSE's IDP Federation policies. [Learn more]({0})",
    securityActions: "Security Actions",
    securityActionsCannotBeAppliedIfAppIsIgnored:
        "Security Actions cannot be applied if the application is ignored",
    securityCode: "Security Code",
    securityGroupInboundConnectionDesc:
        "A security group that exposes the following ports to the internet, so the Access Tier can accept inbound connections from the internet:",
    seeOurDocs: "See our docs",
    seeStepsYouNeedToTakeForTheMigrationToTheNewTrustScoringModel:
        "See [steps you need to take]({0}) for the migration to the new Trust Scoring model. Required to complete by {1}",
    seeTheDocs: "see the docs",
    selectACertificateToDelete: "You must select a certificate to delete",
    selectAFactor: "Select a Factor:",
    selectANetwork: "Select a Network",
    selectANetworkDescription:
        "Private traffic will be tunneled to these networks. Public traffic can be configured on the next page.",
    selectAPolicyToAttach: "Select a Policy to attach",
    selectAProfile: "Select a Profile:",
    selectAServiceTunnel: "Select a Service Tunnel",
    selectAUnregisteredDeviceToDelete: "You must select a device to delete",
    selectAccessTiersOrAnAccessTierGroup: "Select Access Tiers or an Access Tier Group",
    selectAccessTiersToAttachToThisGroup: "Select Access Tiers to attach to this Group",
    selectAll: "Select All",
    selectAnAccessTier: "Select an Access Tier",
    selectAnIcon: "Select an Icon",
    selectAnInstallationMethod: "Select an Installation Method",
    selectAnItpPolicy: "Select an ITP Policy",
    selectAnOption: "Select an option",
    selectApiKey: "Select API Key",
    selectApplications: "Select Applications",
    selectAppsToFilter: "Select Apps",
    selectBrowserVersionMsg: "Select which browser version a device is allowed to use",
    selectCategoriesForFiltering: "Select categories for filtering",
    selectCategoriesToFilter: "Select categories to filter",
    selectCountries: "Select countries",
    selectCountriesInWhichTheDeviceWillFailTheTrustFactor:
        "Select countries in which the Device will fail the Trust Factor",
    selectNameIdValue: "Select Name ID Value",
    selectOneOrMoreDeviceOwnershipOptions: "Select one or more Device Ownership options",
    selectOneOrMorePlatforms: "Select one or more Platforms",
    selectOneOrMoreRolesDesc: "Select one or more roles for this Access Group.",
    selectOneOrMultipleServiceAccounts: "Select one or multiple service accounts.",
    selectOperatingSystemMsg: "Select which operating system versions a device is allowed to use",
    selectOperatingSystems: "Select Operating System(s)",
    selectRegisteredDomain: "Select Registered Domain",
    selectRoles: "Select Role(s)",
    selectServiceAccount: "Select Service Account(s)",
    selectTargetedOperatingSystemsForThisFactor:
        "Select targeted operating systems for this factor",
    selectTheMinimumRequiredTrustLevelForAccess:
        "Select the minimum required Trust Level for access",
    selectTrustLevel: "Select Trust Level",
    selectType: "Select Type",
    selectTypeOfServiceAccountToCreate: "Select type of Service Account to Create",
    selectWetherPoliciesHaveAutorun:
        "Select whether the policies should have autorun functionality.",
    selectWhichCseAppVersionADeviceIsAllowedToUse:
        "Select which CSE App Version a Device is allowed to use.",
    selected: "Selected",
    sendLogsFrom: "Send logs from",
    sendLogsFromAccessTier: "Send Logs from '{0}'",
    sendLogsToSonicWallCseSupport: "Send logs to SonicWall CSE support",
    sendRecoveryEmail: "Send Recovery Email",
    sendToSonicWallCse: "Send to SonicWall CSE",
    sensitivity: "Sensitivity",
    sentinelone: "SentinelOne",
    serialNumber: "Serial Number",
    serialNumbers: "Serial Numbers",
    serverInboundConnectionDesc:
        "A server that exposes the following ports to the internet, so the Access Tier can accept inbound connections from the internet:",
    serverOutBoundConnectionList:
        "\n* [Shield]({2}) - tcp://{0}\n* [Command Center]({3}) - {5}\n* [Trust Provider]({4}) - https://{1}.trust.banyanops.com\n",
    serverOutboundConnectionDesc: "A server that can make outbound connections to the following:",
    servers: "Servers",
    service: "Service",
    serviceAccount: "Service Account",
    serviceAccountBasedRoles: "Service Account Based Roles",
    serviceAccountDescription:
        "Service Accounts are used to grant programmatic access from privileged applications to your SonicWall CSE registered services. [Learn more]({0})",
    serviceAccountName: "Service Account Name",
    serviceAccounts: "Service Accounts",
    serviceAttached: "Service Attached",
    serviceAttributes: "Service Attributes",
    serviceAuthor: "Service Author",
    serviceBundleDescription:
        "Service Bundles are shown to users on CSE Desktop App as a group of services that belong to the same project or category. They can be used to organize services for browsing or for bulk connecting to several services simultaneously.",
    serviceBundleNotFound: "Service Bundle Not Found",
    serviceBundles: "Service Bundles",
    serviceConnectionSettings: "Service Connection Settings",
    serviceDeleteDisabledDescription:
        "To delete a service, it must be disabled and have no attached policy.",
    serviceDetails: "Service Details",
    serviceDomainName: "Service Domain Name ",
    serviceDomainNameDescription: "The external-facing address of this service.",
    serviceDomainNameIsResolvableAndRoutesToAccessTier:
        "Service Domain Name is resolvable and routes to Access Tier",
    serviceMetadata: "Service Metadata",
    serviceMustHaveAnId: "Service must have an ID.",
    serviceName: "Service Name",
    serviceNamePlaceholder: "[Service Name]",
    serviceNameRegexDescription: "Alphanumeric with -_=., only",
    serviceNotFound: "Service Not Found",
    serviceSpecCouldNotBeParsed: "Service spec could not be parsed.",
    serviceStatus: "Service Status",
    serviceTunnel: "Service Tunnel",
    serviceTunnelActions: "Service Tunnel Actions",
    serviceTunnelActionsDesc:
        "Include, Exclude, or Remove your app from the selected Service Tunnel.",
    serviceTunnelCidrRanges: "Service Tunnel CIDR Ranges",
    serviceTunnelNoClusterNameError:
        "Error: Attempted to set up Service Tunnel with connector infra, without cluster name.",
    serviceTunnelNoPeersError:
        "Error: Attempted to set up Service Tunnel without AT peers, without Global Edge.",
    serviceTunnelScreenDescriptionPartOne:
        "Enable Service Tunnels to provide encrypted network connectivity to network segments such as VLANs, VPCs, and subnets. Click ",
    serviceTunnelScreenDescriptionPartTwo: " for more details.",
    serviceTunnelSpecCouldNotBeParsed: "Service Tunnel spec could not be parsed.",
    serviceTunnels: "Service Tunnels",
    serviceType: "Service Type",
    services: "Services",
    servicesAccessed: "Services Accessed",
    servicesAccessedByUniqueUsers: "Services Accessed by Unique Users",
    servicesAccessedByUnregisteredDevices: "Services Accessed by Unregistered Devices",
    servicesWithCount: "Services ({0})",
    setAnExpirationTime: "Set an expiration time for your issued trust token",
    setAnExpirationTimeDesc:
        "Adjust an App level expiration timer for your SonicWall CSE-issued TrustToken (JWT id_token), your TrustCert (X.509 client certificate), and your SSHCert (SSH client certificate). Select within a range of 8 hours to 240 hours, in 1 hour increments.",
    setByEmailOrGroup: "Set by Email or Group",
    setExpirationTimeForDeviceTrustLevel:
        "Set expiration time for device Trust Level. When expired, the device Trust Level will be set to **Always Deny**. If the value is 0, the Trust Level will not expire.",
    setPassword: "Set Password",
    setPasswordAndLaunchSonicWallCse: "Set password and launch SonicWall CSE",
    setToDiscovered: "Set to Discovered",
    setToSyncEvery: "Set to sync every",
    setTrustEffectForFactor: "Set Trust Effect for {0} Factor",
    setYourPassword: "Set Your Password",
    setZtaScoreSeverity: "Set ZTA Score severity",
    settings: "Settings",
    sha256Hash: "SHA-256 Hash",
    sha256HashDescription: "If set, the factor is only satisfied if the hash matches.",
    sha256Placeholder: "ex. dd5920d5d3e76e3610ecc33bc4abd583a048a8052d248bb7e2d923401dcb3625",
    sharedAddress: "Shared Address",
    sharedAddressDescription:
        "Contact your DNS provider to create a DNS record as your Access Tier Group's Shared Address. Your End Users’ requests will point to this Shared Address first and re-route to the address of the next Access Tier for DNS resolution.",
    shield: "Shield",
    shieldAddress: "Shield Address",
    shieldId: "Shield ID",
    shieldVersion: "Shield Version",
    shouldTheDesktopAppUpdateTheSshConfigFileOnTheDevice:
        "Should the CSE Desktop App update the SSH config file on the device?",
    shouldThisUserHaveAccessToSonicWallCseCommandCenter:
        "Should this user have access to the SonicWall CSE Command Center?",
    show: "Show",
    showCertificate: "Show Certificate",
    showClientSecret: "Show Client Secret",
    showConfig: "Show Config",
    showDeviceClientSecret: "Show Device Client Secret",
    showInCseAppServicesCatalog: "Show in CSE App services catalog",
    showMeHowTo: "Show me how to",
    showPublicKey: "Show Public Key",
    showServiceInServiceCatalogInCseApp: "Show Service in Service Catalog within the CSE App",
    showSomething: "Show {0}",
    showUniqueUsersAccessingAServiceWith: "Show Unique Users accessing a Service with",
    showingEvents_one: "Showing {1} event",
    showingEvents_other: "Showing {1} events",
    showingLogs_one: "Showing {1} log",
    showingLogs_other: "Showing {1} logs",
    sia: "SIA",
    signOnMethod: "Sign-On Method",
    signOnSettings: "Sign-on Settings",
    signOnSettingsConfigureDescription:
        "Configure to enable admin single sign-on to the SonicWall CSE Command Center with an Identity provider.\n\n\nYou will no longer be able to use SonicWall CSE local user management once sign-on settings are configured.",
    signOnSettingsUpgradeDescription:
        "Upgrade to enable SSO authentication to the SonicWall CSE Command Center using SAML",
    signOut: "Sign Out",
    signUp: "Sign up",
    singleSignOnSaml20: "Single Sign On - SAML 2.0",
    siteBasedAccessTier: "Site-based (Access Tier)",
    skip: "Skip",
    smartSearchHistory: "Smart Search History",
    smartSearchResults: "Smart Search Results",
    sniDescription:
        "The external-facing address of this service. This service will reject all non-TLS connections. It will only forward TLS connections where the SNI matches the following domain(s).",
    someConnectivityScript: "{0} Connectivity Script",
    someDay_one: "{0} day",
    someDay_other: "{0} days",
    someEdition: "{0} Edition",
    someEnvironment: "{0} Environment",
    someHr_one: "{0} hr",
    someHr_other: "{0} hrs",
    someMin_one: "{0} min",
    someMin_other: "{0} mins",
    somethingAssignment: "{0} Assignment",
    somethingColonSomething: "{0}: {1}",
    somethingCount: "{0} ({1})",
    somethingDeprecated: "{0} (deprecated)",
    somethingEllipsis: "{0}...",
    somethingHasBeenSuccessfullyAdded: '{0} "{1}" has been successfully added.',
    somethingHasBeenSuccessfullyDeleted: '{0} "{1}" has been successfully deleted.',
    somethingHasBeenSuccessfullyUpdated: '{0} "{1}" has been successfully updated.',
    somethingLast_ordinal_few: "{0}rd {1}",
    somethingLast_ordinal_one: "{0}st {1}",
    somethingLast_ordinal_other: "{0}th {1}",
    somethingLast_ordinal_two: "{0}nd {1}",
    somethingLearnMore: "{0} [Learn more]({1})",
    somethingManagedBypassDomain: "{0} Managed Bypass Domain",
    somethingName: "{0} Name",
    somethingNamedAlreadyExists: '{0} named "{1}" already exists. Enter a new name.',
    somethingNotFound: "{0} not found",
    somethingOptional: "{0} (optional)",
    somethingPublicInfoCantBeEmpty: "{0} public exclude/include info can't be empty",
    somethingRecommended: "{0} (recommended)",
    somethingRevoked: "{0} Revoked",
    somethingToExclude: "{0} to Exclude",
    somethingToExcludeFromThisNetwork: "Which {0} do you want to exclude from this network?",
    somethingToInclude: "{0} to Include",
    somethingToRouteThroughThisNetwork: "Which {0} do you want to route through this network?",
    somethingTypeAQuestion: "{0}... Type a question",
    somethingWentWrongDescription: "Something went wrong. Please contact your Org's Administrator.",
    sonicWallCse: "SonicWall CSE",
    sonicWallCseBecomesAnAuthenticationFactorDesc:
        "SonicWall CSE becomes an authentication factor to the app that can validate the device’s posture and Trust Level, to determine if a device is allowed access.",
    sonicWallCseClient: "SonicWall CSE Client",
    sonicWallCseGeneratedKey: "SonicWall CSE Generated Key",
    sonicWallCseIdp: "SonicWall CSE IDP",
    sonicWallCseLogo: "SonicWall CSE logo",
    sonicWallCseManagedBypassDomains: "SonicWall CSE Managed Bypass Domains",
    sonicWallCsePki: "SonicWall CSE PKI",
    sonicWallCseRecommendsUsingRegisteredDomainDesc:
        "SonicWall CSE recommends using a Registered Domain since it does not validate unregistered domains. [Learn More]({0})",
    sonicWallCseStatus: "SonicWall CSE Status",
    sonicWallCseUsernamePassword: "SonicWall CSE - Username & Password",
    sonicWallCsesNewTrustScoringModel: "SonicWall CSE's new Trust Scoring model",
    sonicWallCurtain: "SonicWall Curtain",
    sonicWallCurtainToggleButton: "SonicWall Curtain Toggle Button",
    sonicWallProvisioned: "SonicWall Provisioned",
    source: "Source",
    sourceCidrs: "Source CIDRs",
    sourceCidrsDescription: "Requests from these IP ranges will be exempted.",
    sourceCidrsOptional: "Source CIDRs (optional)",
    spExpires: "SP Expires",
    spIssuer: "SP Issuer",
    spMetadataUrl: "SP Metadata URL",
    spSigningCertificate: "SP Signing Certificate",
    spa: "SPA",
    specification: "Specification",
    ssh: "SSH",
    sshCaPublicKey: "SSH CA Public Key",
    sshDesktopAppSettings: "SSH Desktop App Settings",
    sshHostDirective: "SSH Host Directive",
    sshHostDirectiveDescription:
        "Creates an entry in the SSH config file using the Host keyword. Wildcards are supported such as '192.168.*.?'. Defaults to the service name.",
    sshHostDirectivePlaceholder: "ex. my-service-name, *.co.uk, 192.168.*.?",
    sshService: "SSH Service",
    sshServiceDescription:
        "Secure access to an individual SSH server or a collection of SSH servers using MTLS and short-lived certificates. Supports SSH certificate authentication and just-in-time user provisioning.",
    ssoUrl: "IDP SSO URL",
    stackName: "Stack Name",
    staging: "Staging",
    standardWebsite: "Standard Website",
    standardWebsiteDescription:
        "Secure access to HTTPS applications accessed through a web browser. Supports defining single domain and multi domain services.",
    startInstallerExe: "Start installer.exe.",
    state: "State",
    status: "Status",
    statusReporting: "Status Reporting",
    step: "Step {0}",
    stepsToMigrateToGranularTrust: "Steps to Migrate to Granular Trust",
    strict: "Strict",
    strictAllowOnlyUsersAndDevicesWithACrowdStrikeZTAof75orHigher:
        "Strict - allow only users and devices with a CrowdStrike ZTA of 75 or higher",
    strictTransportSecurity: "Strict Transport Security",
    subjects: "Subjects",
    submit: "Submit",
    success: "Success",
    successUserAndDeviceeInactivityIntervalSaved:
        "Success: User and Device inactivity interval saved.",
    successfullyConfiguredTrustedNetwork: "Successfully configured trusted network",
    successfullyDeletedDeviceConfig: "Successfully deleted device config",
    successfullyDisabledScim: "Successfully Disabled SCIM",
    successfullyEnabledScim: "Successfully Enabled SCIM",
    successfullyRemovedTheFollowingUsers: "Successfully removed the following users",
    successfullySavedOperatingSystemSettings: "Successfully saved Operating System Settings.",
    successfullySentLogsToSonicWallCse: "Successfully sent logs to SonicWall CSE",
    successfullyUpdatedApplicationCheck: "Successfully updated Application Check.",
    successfullyUpdatedArchivalDate: "Successfully updated Archival Date",
    successfullyUpdatedNrptConfig: "Successfully updated NRPT Config.",
    successfullyUpdatedRemediation: "Successfully updated Remediation",
    successfullyUpdatedTrustedNetwork: "Successfully updated trusted network",
    successfullyUpdatedUserConfig: "Successfully updated user config",
    summary: "Summary",
    superAdmin: "SuperAdmin",
    support: "Support",
    supportBundle: "Support Bundle",
    supportBundles: "Support Bundles",
    supportBundlesAreBeingGeneratedMessage:
        "Support Bundles are being generated. This process may take a few minutes.",
    supportBundlesAreReadyForDownload: "Support Bundles are ready for download.",
    supportDesc: "Connect with a member of SonicWall CSE's customer success team.",
    supportExample:
        "e.g., If you need assistance, please contact ithelpdesk@acmecorp.com or click the link below.",
    supportLinkExample: "e.g., https://support.acmecorp.com or mailto:ithelpdesk@acmecorp.com",
    supportSite: "Support Site",
    suppressDeviceTrustVerification: "Suppress Device Trust Verification",
    suppressDeviceTrustVerificationDescription:
        "If device trust verification is suppressed, mobile devices will not be able to pass the device trust check.",
    syncEta: "Sync ETA",
    syncInProgress: "Sync currently in progress",
    syncLog: "Sync Log",
    syncTargetRecords: "Sync Target: {0} Record(s)",
    systemLogs: "System Logs",
    tagName: "Tag Name",
    tags: "Tags",
    takeSecurityMeasuresByPerformingActionsDesc:
        "Take security measures by performing actions for this application. After an action is taken the status indicator will update.",
    tarball: "Tarball",
    tarballInstaller: "Tarball Installer",
    targetDomains: "Target Domains",
    targetDomainsDescription:
        "Requests targeting these subdomains will be exempted. Remove this rule for single-domain web services.",
    targetDomainsOptional: "Target Domains (optional)",
    targetDomainsPlaceholder: "e.g., https://domain-a.corp.internal.com:443",
    tcp: "TCP",
    tcpService: "TCP Service",
    team: "Team",
    terminated: "Terminated",
    terminatedExplanation:
        "The Connector has not reported to the Command Center within the past 7 minutes.",
    test: "Test",
    testConnection: "Test Connection",
    testConnectionAndValidateAccessTier: "Test Connection and Validate Access Tier",
    testing: "Testing",
    testingAndInternalOrgsAreNotSupportedMsg:
        "Testing and Internal orgs are no longer supported. Please select other type.",
    thanksForTheFeedback: "Thanks for the feedback",
    theConnectorIsSuccessfullyReportingClickDoneToReturnToTheConnectorPage:
        "The Connector is successfully **Reporting**. Click Done to return to the Connector page.",
    theEmailAddressOrPasswordYouHaveEnteredIsIncorrect:
        "The Email address or Password you have entered is incorrect.",
    theExpectedValueIsAlwaysCheckedAsAStringForExampleATrueValueIsEvaluatedAs1:
        'The expected value is always checked as a string, for example a true value is evaluated as "1". If value is unset, this factor will only check for the existence of the key.',
    theFactorNameWillBeDisplayedToEndusers:
        "The factor name will be displayed to endusers when it is not satisfied.",
    theFollowingInputIsBothInExcludeIncludeSomeThing:
        "The following input appears in both the include and exclude fields, which is not allowed: {0}",
    theItpReportHasBeenGeneratedSuccessfully: "The ITP Report has been generated successfully.",
    theMigrationToTheNewTrustScoringModelCannotBeReverted:
        "The migration to the new Trust Scoring model cannot be reverted. Are you ready to complete the migration process?",
    thePasswordsEnteredDoNotMatch: "The passwords entered do not match",
    theRecoveryEmailWasSent: "The recovery email was sent",
    theRecoveryEmailWasSentDescription:
        "If the email you provided is in our system, you'll receive an email from us containing the next steps required to log into SonicWall CSE.",
    theServiceDomainMustMatchOneOfTheFollowing:
        "The service domain must match one of the following:",
    theUserNameOrPasswordYouHaveEnteredIsIncorrect:
        "The Username or Password you have entered is incorrect.",
    thereAreCurrentlyNoRolesAssociatedWithThisDevice:
        "There are currently no Roles associated with this Device. [Learn more about Device Roles]({0}) on our docs page.",
    thereAreCurrentlyNoUsersAssociatedWithThisDevice:
        "There are currently no Users associated with this Device. [Learn more about setting up a User Directory]({0}) on our docs page.",
    thereSeemsToBeAProblemWithTheConnectorTryAgainInAFewMinutesIfTheProblemPersistsPleaseContactSonicWallCseSupport:
        "There seems to be a problem with the Connector. Try again in a few minutes. If the problem persists, please contact SonicWall CSE Support.",
    thereWasAnErrorLookingUpTheDomain: "There was an error looking up the domain.",
    thereWasAnIssueDeletingTheIntegration: "There was an issue deleting this integration.",
    theseAccessTiersAreCurrentlyAvailableForAttachment:
        "These access tiers are currently available for attachment.",
    theseAreTheNetworksThatWillProtectThisHostedWebsiteYouMaySelectOneOrMore:
        "These are the Networks that will protect this Hosted Website. You may select one or more.",
    thisAllowsEndUsersToContinueTheirUseOfFQDNWithinTheServiceTunnel:
        "This allows end users to continue their use of FQDNs within the Service Tunnel.",
    thisCanBeCidrRangeOrIPAddressInCidrFormat:
        "This can be a CIDR range or an IP address in CIDR format.",
    thisDeviceDoesNotHaveTheCorrectValuesForThePlistsListedAbovePleaseReachOutToSupport:
        "This device does not have the correct values for the plists listed above. Please reach out to support.",
    thisFieldRepresentsFullyQualifiedDomainNameDesc:
        "This field represents Fully Qualified Domain Name(s). We strongly recommend FQDN(s) that resolve to the same addresses from different geolocations and do not change very frequently. Wildcard domains are not allowed.",
    thisIsTheAccessTierConnectorWhichWillProtectTheWebServiceYouMaySelectMoreThanOneAccessTier:
        "This is the Access Tier / Connector which will protect the web service. You may select one or more.",
    thisIsTheBackendServerYouWillBeProtectingAccessToLearnMoreAboutMultiDomainService:
        "This is the backend server you will be protecting access to. Learn more about [multi-domain service]({0}).",
    thisIsTheDescriptionDisplayedInCseApp:
        "This is the description displayed in the CSE app for end users.",
    thisIsTheIconDisplayedDescription:
        "This is the Icon displayed in the service catalog of the CSE app for end users.",
    thisIsTheLinkDisplayedDescription:
        "This is the link displayed in the service catalog of the CSE app for end users.",
    thisIsTheNameDisplayedInTheCseAppDirectoryForEndUsers:
        "This is the name displayed in the service catalog of the CSE App for end users.",
    thisIsTheNameDisplayedInTheCseAppForEndUsers:
        "This is the name displayed in the CSE App for end users.",
    thisIsTheUrlEndUsersWillTypeDesc:
        "This is the URL end users will type in the browser to access the web service.",
    thisIsUsedToProvideCredentialsDescription:
        "This is used to provide credentials that authenticate a user agent with a server.",
    thisMessageWillBeDisplayedOnALandingPageWhenAUserIsDeniedAccessToARestrictedDomainInTheITPPolicy:
        "This message will be displayed on a landing page when a user is denied access to a restricted domain in the ITP Policy.",
    thisPolicyContainsCountDeviceDesc_one:
        "This policy contains {0} Device(s). [Click to see a list of devices in a new window.]({1})",
    thisPolicyContainsCountDeviceDesc_other:
        "This policy contains {0} Device(s). [Click to see a list of devices in a new window.]({1})",
    thisServiceTunnelContainsCountActiveConnection_one:
        "This Service Tunnel contains {0} Active Connection(s). [Click to see a list of devices in a new window.]({1})",
    thisServiceTunnelContainsCountActiveConnection_other:
        "This Service Tunnel contains {0} Active Connection(s). [Click to see a list of devices in a new window.]({1})",
    thisTrustIntegrationWasLastSyncedOn: "This Trust Integration was last synced on:",
    thisUser: "this user",
    threatCategories: "Threat Categories",
    threatProtection: "Threat Protection",
    timestamp: "Timestamp",
    tls: "TLS",
    tlsDescription: "Indicates the backend server uses TLS.",
    tlsInsecure: "TLS Insecure",
    tlsInsecureDescription: "Indicates the backend server does not validate the TLS certficate.",
    tlsSni: "TLS SNI",
    tlsSniDescription: "Server Name Indication (SNI) Address for TLS connections.",
    to: "to {0}",
    toAccessTier: "to Access Tier",
    toBackend: "to Backend",
    toDisableScimEnsureAllActiveTokensAreDeleted:
        "To disable the SCIM, ensure all active tokens are deleted",
    toGetStartedTypeAQuestionInTheSearchBarAtTheTopOfThePage:
        "To get started, type a question in the search bar at the top of the page.",
    toLearnMoreAboutLicensingCheckOutHelpDocs:
        "To learn more about licensing check out our [Help Docs]({0}).",
    toLogInWithALocalAccountClickHere: "To log in with a local account, click here.",
    toModifyIdpConfigurationDisableScim: "To modify IDP configurations, disable SCIM provisioning.",
    toModifyIdpConfigurationUpdateEndUserConfig:
        "To modify IDP configuration, update End User configuration first.",
    toUpdateSCIMProvisioningStatusDesc:
        "To update SCIM Provisioning, ensure User IDP is configured and saved",
    tokenEndpoint: "Token Endpoint",
    tokenIsAvailableOnlyOnceDesc:
        "The API token is presented to your end user only once; Ensure that they copy and safely store their token for later use.",
    tokenUrl: "Token Url",
    tokenUrlPlaceholder: "Enter Token Url",
    top: "Top",
    topServicesAccessedByUniqueUsers: "Top Services Accessed by Unique Users",
    totalActiveUsersOver90Days: "Total Active Users over 90 days",
    traffic: "Traffic",
    transient: "Transient",
    trial: "Trial",
    true: "True",
    trust: "Trust",
    trustCookiePath: "Trust Cookie Path",
    trustCookiePathDescription:
        "Scope the SonicWall CSE trust cookie to specific paths for situations where the entire domain is not protected by SonicWall CSE",
    trustCookieSameSitePolicy: "Trust Cookie SameSite Policy",
    trustCookieSameSitePolicyDescription:
        "Restrict the scope of a cookie, limiting its usage to only the domain it originated from.",
    trustFactor: "Trust Factor",
    trustFactors: "Trust Factors",
    trustFactorsEnabled: "Trust Factors Enabled",
    trustIntegration: "Trust Integration",
    trustIntegrationNotFound: "Trust Integration Not Found.",
    trustIntegrationSyncLogs: "Trust-Integration-Sync-Logs",
    trustIntegrationSyncLogsNotFound:
        "No Sync has occurred. Please enable an associated trust factor to start batch sync.",
    trustLevel: "Trust Level",
    trustLevelExpiration: "Trust Level Expiration",
    trustLevelExpirationDialog:
        "Setting an expiration time (in hours) will reset Trust Levels to 'Always Deny' once this time is exceeded. Applying an **Hours Until Expired** will immediately set the **Trust Level Expiration** for devices, which could interfere with end user access. To bypass the expiration setting, set the **Hours Until Expired** to '0'.",
    trustLevels: "Trust Levels",
    trustProfile: "Trust Profile",
    trustProfileDescription:
        "An optional field shown in the web console to Admin users only, for your own internal notes.",
    trustProfileNotFound: "Trust Profile not Found",
    trustScore: "TrustScore",
    trustScoreExpiry: "Trust Score Expiry",
    trustScoreSettings: "Trust Score Settings",
    trustedNetworks: "Trusted Networks",
    trustscore: "Trustscore",
    tunnel: "Tunnel",
    tunnelEndpointType: "Tunnel Endpoint Type",
    tunnelForRemoteAccessIntoYourPrivateNetwork:
        "Tunnel - for remote access into your private network",
    tunnelPolicy: "Tunnel Policy",
    tunnelPolicyDescription:
        "Create a policy for user access to network locations based on CIDR ranges, ports, and protocols",
    tunnelSessionExpirationTime:
        "Tunnel session expiration time is the same as **User and Device Inactivity** expiration time. If Admins do not configure an expiration time, a default expiration time will be used. [See here for more information]({0})",
    twoTokensAllowedDesc:
        "A maximum of two active tokens is allowed at any given time; Please delete the existing one to be able to generate new token",
    type: "Type",
    typeYourQuestionOrChoosePrompt: "Type your question or choose a prompt template below:",
    ubuntu: "Ubuntu",
    ubuntuOsVersionFormat: "Ubuntu OS version format: major.minor.build",
    ubuntuOsVersionPlaceholder: "e.g., 22.04",
    udp: "UDP",
    unabletoConfirmSecurityCode: "Unable to confirm security code",
    unarchive: "Unarchive",
    unauthorized: "Unauthorized",
    unauthorizedMessage: "Unauthorized Message",
    unavailableAccessTiers: "Unavailable Access Tiers",
    unavailableAccessTiersMessage:
        "These Access Tiers have conditions that prohibit them from being added to a group.",
    unevaluated: "Unevaluated",
    unexpectedVolumeOfRequests:
        "We're sorry, there has been an unexpected volume of requests. Please try again in a few minutes.",
    uniqueDevices: "Unique Devices",
    uniqueUsers: "Unique Users",
    uniqueUsersDevices: "Unique Users/Devices",
    unixFilePathPlaceholder: "path/to/file",
    unknown: "Unknown",
    unlimited: "Unlimited",
    unlinkFollowingRoleBeforeDeleting_one: "Unlink the following Role before deleting: {1}",
    unlinkFollowingRoleBeforeDeleting_other: "Unlink the following Roles before deleting: {1}",
    unlockTheSonicWallCseDashboardByAccessingYourFirstService:
        "Unlock the SonicWall CSE dashboard by accessing your first service.",
    unregister: "Unregister",
    unregisterUser: "Unregister User",
    unregistered: "Unregistered",
    unregisteredDevices: "Unregistered Devices",
    unregisteredDevicesDescription:
        "SonicWall CSE's Command Center's TrustProvider component only responds to registered devices that have a trusted Device Certificate installed. Unregistered devices without a Device Certificate cannot even establish a connection to TrustProvider. For some scenarios (such as incremental rollout of the CSE App or exposing some services to unregistered devices) you can relax the Device Certificate requirement.",
    unregisteredDevicesSuccessfullyUpdated: "Unregistered Devices successfully updated.",
    unregisteredDevicesUpgradeDescription:
        "Enable access to SonicWall CSE protected services from unregistered devices when you upgrade",
    unregisteringUserFromThisDeviceWillRevokeTheDevicesCertificate:
        "Unregistering {0} from this Device will revoke the Device's certificate. Are you sure you want to continue?",
    unspecified: "Unspecified",
    unsureAboutWhatPlanIsRightForYou: "Unsure about what plan is right for you?",
    upToDate: "Up to Date",
    upcomingProductEnhancementGranularTrustScoring:
        "\n## Upcoming Product Enhancement: Granular Trust Scoring\n\nTrust Scoring is now calculated as a Trust Level rather than a score out of 100. Admins now have fine-grained control over how Trust Factors affect a device's Trust Level. You'll have until {0} to migrate to our new Trust Scoring model.\n",
    update: "Update",
    updateAccessTier: "Update Access Tier",
    updateAccessTierApiKeyMessage:
        "Please note updating API key requires Access Tier redeployment.",
    updateAdvancedSettingsDescription:
        "Please note applying these changes requires Access Tier restart",
    updateApiConfig: "Update API Config",
    updateConfigYamlWithSonicWallCseVariables: "Update config.yaml with SonicWall CSE variables",
    updateConnectorConfigYamlWithSonicWallCseVariables:
        "Update connector-config.yaml with SonicWall CSE variables",
    updateDeviceCert: "Update Device Cert",
    updateDeviceRegistrationProviderConfig: "Update Device Registration Provider Config",
    updateInviteCode: "Update Invite Code",
    updatePassword: "Update Password",
    updateSignOnSettings: "Update Sign-on Settings",
    updateSignOnSettingsDescription:
        "Are you sure you want to update the sign-on settings? Make sure the Identity Provider has been configured correctly.",
    updateSomething: "Update {0}",
    updateUserPool: "Update User Pool",
    updatedBy: "Updated By",
    upgrade: "Upgrade",
    upgradeForSomething: "Upgrade for {0}",
    upgradeIllustration: "Upgrade Illustration",
    upgradeNow: "Upgrade Now",
    upgradeToday: "Upgrade Today!",
    upload: "Upload",
    upsellDescription:
        "Choose from  Secure Internet Access (SIA) or Secure Private Access (SPA) licenses across our ‘Basic’ and ‘Advanced’ Packages. All ‘Advanced’ plans include ‘Basic’ capabilities. Please contact a sales representative at SonicWall to upgrade your plan to enable immediate access to this feature.",
    url: "URL:",
    urlBasedWebFiltering: "URL Based Web Filtering",
    urlBasedWebFilteringDescription:
        "Protect against internet threats and enforce acceptable use policies at a URL level.",
    urlFilteringInfo: "A PAC file must be uploaded to SonicWall CSE to enable URL filtering.",
    urlFilteringPreviewOnlyFeature:
        "URL Filtering is a preview-only feature. It is not advised to use this feature in production orgs. Use caution and consult with SonicWall CSE if considering this feature.",
    useADeploymentKeyToEnableAZeroTouchDeployment:
        "Use a [Deployment Key]({0}) to enable a 'Zero-Touch' deployment of the CSE App via your Device Manager.",
    useBothTheTrustCertAndTheSshCert: "Use both the TrustCert and the SSHCert",
    useGeolocation: "Use Geolocation",
    useGeolocationDescription:
        "This feature allows you to see the location of end users. This helps administrators monitor end users better by being able to detect any suspicious international activity.  ",
    useInternetThreatProtectionDesc:
        "Use Internet Threat Protection policies to block this resource for groups of users and devices.",
    useNrptRulesForWindowsDnsResolution: "Use NRPT rules for Windows DNS resolution",
    useNrptRulesForWindowsDnsResolutionDesc:
        "The Name Resolution Policy Table (NRPT) in Windows Server 2012 and Windows Server 2012 R2 enables you to enforce name resolution policies on security-aware DNS clients. Only recommended when Cisco Umbrella is also installed on the device. \n\nOnce enabled, the NRPT functionality will take up to 1 hour to come into effect.",
    usePassthroughNameId: "Use passthrough Name ID",
    useTheCseDesktopDescription:
        "Use the CSE Desktop and Mobile Apps to register devices that can access corporate applications. See [installation instructions]({0}) for your users.",
    useUnregisteredDomain: "Use Unregistered Domain",
    usedByFollowingTypesOfServicesViaSonicWallCse:
        "Used By The Following Types of Services via SonicWall CSE",
    user: "User",
    userAccessActivity: "User Access Activity",
    userAndDeviceInactivity: "User and Device Inactivity",
    userAndDeviceInactivityDescription:
        "User and Device inactivity threshold: If set and a user or device is inactive for this period of time, they will automatically be archived. If zero or unset, users will not be automatically archived.",
    userBasedRole: "User Based Role",
    userCannotBeDeletedDesc: "User cannot be deleted due to SCIM being enabled",
    userCount: "User Count",
    userEmail: "User Email",
    userEmails: "User Emails",
    userFacing: "User Facing",
    userGroups: "User Groups",
    userGroupsAreCorrelatedWithThoseSentToSonicWallCseViaIdpConfigurations:
        "User Groups are correlated with those sent to SonicWall CSE via IDP configurations.",
    userIdentityProvider: "User Identity Provider",
    userIdentityProviderConfigurationUpdated: "User Identity Provider configuration updated.",
    userIdentityProviderDescription:
        "Connect Cloud Secure Edge to your own Identity Provider. See [step-by-step instructions]({0}) in our help docs. Export current IDP configuration to a file on your device. This file can be imported and automatically restored if required again in the future.",
    userIdentityProviderExplanation:
        "Connect SonicWall CSE to your own Identity Provider. See step-by-step [instructions in the docs]({0}).",
    userIdentityProviderUpgradeDescription:
        "Configure your own Identity Provider (e.g, OKTA, One Login, etc.) for end user authentication.\n\n\nYou will no longer be able to use SonicWall CSE local user management once user identity provider settings are configured.",
    userInvitedTemplate: "User {0} has been invited to this org.",
    userLicenseStatus: "User License Status:",
    userName: "User Name",
    userNotFound: "User not found",
    userPool: "User Pool",
    userPoolClient: "User Pool Client",
    userPoolDomain: "User Pool Domain",
    userPoolId: "UserPool ID",
    userSlashDevice: "User/Device",
    userUpdateSuccessful: "User update successful.",
    userinfoEndpoint: "UserInfo Endpoint",
    username: "Username",
    usernameAttr: "Username Attribute",
    users: "Users",
    usersAccessingServicesWithUnregisteredDevices:
        "Users Accessing Services with Unregistered Devices",
    usersAssociatedWithDevice: "Users Associated With Device:",
    usersThatAreAbleToAccessCommandCenterWillBePlacedInAllAdminsRole:
        'Users that are able to access the command center will be automatically placed in the "AllAdmins" role.',
    validMethods: "Valid Methods",
    validUrlError: "Please add a valid URL.",
    validate: "Validate",
    validateAndTest: "Validate & Test",
    validateRegisteredDomainSuccessMessage: "This domain was successfully validated.",
    validateText:
        "Before configuring your Access Tier, it’s good practice to make sure the installation was successful. We’ll need your Public Address to run connectivity tests and validate the new Access Tier.",
    value: "Value",
    valueSuccessfullyCopied: '"{0}" successfully copied.',
    valuesMustBeUnique: "Values must be unique",
    verified: "Verified",
    verifyThatTheCommandCenterUrlDescription:
        "Verify that the Command Center URL in your deployment tool matches the value below. If it does not match, copy and paste the Command Center URL into its corresponding field.",
    version: "Version",
    versionNotSupported: "Version not supported",
    versionNumber: "Version Number",
    viewAllDetails: "View All Details",
    viewAsSomething: "View as {0}",
    viewCurrentStatus: "View Current Status",
    viewDocs: "View Docs",
    viewFullResultsAndMoreOptions: "View full results & more options",
    viewResults: "View Results",
    viewResultsForSomething: 'View results for "{0}"',
    viewingDetailsFor: "Viewing details for",
    viewingDetailsForSomething: 'Viewing details for "{0}"',
    virtualAppliance: "Virtual Appliance",
    vmware: "VMware",
    waitingOnSupportBundleRequestedAtSomething: "Waiting on Support Bundle requested at {0}",
    warning: "Warning",
    warningThisCannotBeReversed: "Warning: This cannot be reversed",
    wasThisResponseHelpful: "Was this response helpful?",
    weEncounteredAProblemOpeningTheCaptureSecurityCenter:
        "We encountered a problem opening the Capture Security Center.",
    weRecommendYouChooseANameThatRepresentsTheUsersAssignedToThisITPPolicy:
        "We recommend you choose a name that represents the users assigned to this ITP Policy.",
    weRecommendYouChooseANameThatRepresentsTheUsersAssignedToThisTrustProfile:
        "We recommend you choose a name that represents the users assigned to this Trust Profile.",
    web: "Web",
    webForAccessingHttpServicesViaWebBrowser: "Web - for accessing HTTP services via web browser",
    webPolicy: "Web Policy",
    webPolicyDescription:
        "Create a policy for user access to hosted web services and SaaS applications",
    webService: "Web Service",
    welcomeToSmartSearch: "Welcome to Smart Search",
    whatBeingCollected: "What’s being collected",
    whatBeingCollectedDescription:
        "The system collects IP addresses from the end user. Whenever an end user accesses a protected service, we will use their IP to determine their geolocation.",
    whatDomainWouldYouLikeToUse: "What domain would you like to use?",
    whatDomainsExistOnNetworkProvidedAbove: "What domains exist on the network provided above?",
    whatDomainsExistOnNetworkProvidedAboveDesc:
        "This allows end users to continue their use of FQDNs within the Service Tunnel.",
    whatIsTheBestWayTo: "What is the best way to",
    whatIsThePrivateHostnameOrIpOfYourWebService:
        "What is the private hostname or IP of your web application?",
    whatModeShouldThePolicyBeIn: "What mode should the policy be in?",
    whatNetworkWouldYouLikeToMakeAvailable:
        "What network would you like to make available? (optional)",
    whatNetworkYouWouldLikeToMakeAvailable: "What network would you like to make available?",
    whatNetworksShouldBeAccessibleViaThisTunnel:
        "What network(s) should be accessible via this tunnel?",
    whatPrivateNetworkIsYourServiceLocatedOn: "What private network is your service located on?",
    whatShouldTheBlockPageMessageSay: "What should the Block Page message say?",
    whatTypeOfServiceIsThisPolicyIntendedFor: "What type of service is this policy intended for?",
    whatTypesOfTrafficDoYouWantToPreventEndUsersFromAccessing:
        "What types of traffic do you want to prevent end users from accessing?",
    whatWouldYouLikeToNameTheService: "What would you like to name the service?",
    whenDeviceRegistrationIsChallengingDesc:
        "When device registration is challenging, you can leverage these controls to secure access to resources while complying with zero-trust architecture.",
    whenServicesAreInitializedSelectSonicCoreXAndPressEnter:
        "When services are initialized, select SonicCoreX and press Enter.",
    whenToggledONDevicesAssociatedWithThisITPPolicyWillBeBlockedFromAccessingSitesCategorizedAsThisTypeOfThreat:
        "When toggled **ON**, devices associated with this ITP Policy will be blocked from accessing sites categorized as this type of threat.",
    whereWillYouInstallAccessTier: "Where will you install this Access Tier?",
    whereWillYouInstallAccessTierDesc:
        "Help us optimize your set up experience by telling us what platform or environment you’re installing Access Tier on.",
    whereWillYouInstallThisConnector: "Where will you install this Connector?",
    whereWillYouInstallThisConnectorDescription:
        "Help us optimize your setup experience by telling us what platform or environment you’re installing the Connector on.",
    whichAppsShouldBypassThisITPPolicy: "Which applications should bypass this ITP Policy?",
    whichAppsWouldYouLikeToBlockAccessTo: "Which applications would you like to block access to?",
    whichAuthenticationProtocolWillThisIdpRoutedApplicationUse:
        "Which authentication protocol will this IDP Routed Application use?",
    whichAuthenticationProtocolWillThisSaasApplicationUse:
        "Which authentication protocol will this SaaS Application use?",
    whichConnector: "Which Connector?",
    whichDomainsShouldBypassThisITPPolicy: "Which domains should bypass this ITP Policy?",
    whichDomainsWouldYouLikeToBlockAccessTo: "Which domains would you like to block access to?",
    whoShouldHaveAccessToThisHostedWebsite: "Who should have access to this Hosted Website?",
    whoShouldThisITPPolicyBeAssignedTo: "Who should this ITP Policy be assigned to?",
    whyDoINeedTo: "Why do I need to",
    willYouBeSecuringServiceTunnelWithAccessTier:
        "Will you be securing a Service Tunnel with this Access Tier?",
    windows: "Windows",
    windowsExecutable: "Windows Executable",
    windowsFilePathPlaceholder: "path\\to\\file",
    windowsOsVersionFormat: "Windows OS version format: major.minor.build",
    windowsOsVersionPlaceholder: "e.g., 10.0.19043",
    windowsServer: "Windows Server",
    wireguard: "WireGuard",
    workspaceOneUEM: "Workspace ONE UEM",
    workspaceone: "WorkspaceOne",
    workspaceoneApiEndpointPlaceholder: "ex. http//api.workspaceone.com",
    writeABlockPageMessageHere: "Write a block page message here.",
    xDays: "{0} Days",
    xHours: "{0} hours",
    xHrs: "{0} Hrs",
    xMinutes: "{0} minutes",
    yes: "Yes",
    yesDisableScim: "Yes, Disable SCIM",
    yesFinishMigration: "Yes, Finish Migration",
    yesRegerate: "Yes, Regenerate",
    yesRemove: "Yes, Remove",
    yesRestrictAccessIfThisAppIsNotRunning:
        "Yes, restrict access if this Application is not running",
    youAreNotAuthorizedToPerformThisAction: "You are not authorized to perform this action.",
    youAskedSomething: 'You asked: "{0}"',
    youCanContinueOnceYouHaveRunTheInstallationStepsAndTheConnectorStatusReadsReporting:
        "You can continue once you've run the installation Steps and the Connector status reads **Reporting**.",
    youCurrentlyHaveCountPolicyActiveForThisApp_one:
        "You currently have {0} Policy active for this app.",
    youCurrentlyHaveCountPolicyActiveForThisApp_other:
        "You currently have {0} Policies active for this app.",
    youCurrentlyHaveCountServiceTunnelsActive_one:
        "You currently have {0} Service Tunnel active for this app.",
    youCurrentlyHaveCountServiceTunnelsActive_other:
        "You currently have {0} Service Tunnels active for this app.",
    youDoNotHaveALicenseSubscriptionForThisPlan:
        "You do not have a license subscription for this plan.",
    yourAccountDoesNotHavePermissionToViewThisPage:
        "Your account does not have permission to view this page",
    yourAdminProfileDoesNotPermitThisAFunctionality:
        "Your Admin Profile does not permit this functionality.",
    yourGlobalOpenIdConnectSettings: "Your Global OpenID Connect Settings",
    yourHostedWebsiteConnectionWasNotSuccessfulDesc:
        "Your Hosted Website connection was not successful. You can review your configurations, or continue to save this Hosted Website.",
    yourIdp: "Your IDP",
    yourOrganizationRequiresOneTimePasscodeDescription:
        "Your organization requires you set up a one-time passcode. For help setting this up, [click here]({0}).",
    yourPreviousSmartSearches: "Your Previous Smart Searches",
    yourProfileDoesNotPermitActionAsItDoesNotHaveAdminPrivilege:
        "Your profile does not permit this action as it does not have Admin privileges.",
    zeroTouchDeploymentUsingADeviceManager: "Zero-Touch Deployment Using a Device Manager",
    zeroTouchNetworkAccess: "Zero Touch Network Access",
    zeroTouchNetworkAccessDescription:
        "Provide users in your org with secure access to individual protected resources.",
    ztaScore: "ZTA Score",
    ztaScoreDescription: "Satisfied if the severity is met on the device",
    ztaScoreRemediationText:
        "{0} has evaluated this device's ZTA score is below the threshold required. Please reach out to support.",
    ztaScoreSeverity: "ZTA Score Severity",
} as const

export type LanguageDictionary = typeof enUSLanguage
export type LanguageKey = keyof LanguageDictionary
