import React from "react"
import { useHistory, useParams } from "react-router-dom"

import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../components/button/Button.component"
import {
    ConfirmationModalWithoutRef,
    ModalApi,
} from "../../../components/modal/ConfirmationModal.component"
import { PageHeading } from "../../../components/page-heading/PageHeading.component"
import { useFeatureFlags } from "../../../hooks/useFeatureFlags.hook"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { decodeID } from "../../../pre-v3/utils/Url.util"
import { ROUTE } from "../../../routes"
import { Container } from "../../../v3/components/container/Container.component"
import { Loader } from "../../../v3/components/loader/Loader.component"
import {
    KeyValuePairItems,
    OverviewTopContainer,
    getStatusType,
} from "../../../v3/components/overview/OverviewTopContainer/OverviewTopContainer.component"
import { TabBar, TabProps } from "../../../v3/components/tab-bar/TabBar.component"
import { Tooltip } from "../../../v3/components/tooltip/Tooltip.component"
import {
    AccessTierDetails as AccessTier,
    EdgeType,
    Status,
    statusLabelDict,
    statusTypeDict,
    useDeleteAccessTier,
    useGetAccessTierById,
} from "../../../v3/services/AccessTier.service"
import { Banner, Variant } from "../../../components/banner/Banner.component"
import { ErrorToast, ToastApi } from "../../../components/toast/Toast.components"
import { TestConnectionModal } from "../shared/TestConnectionModal.component"
import { SendLogsModal } from "./SendLogsModal.component"
import { AccessTierConfigurationTab } from "./AccessTierConfigurationTab.component"
import { AccessTierInstallTab } from "./AccessTierInstallTab.component"
import { AccessTierInstancesTab } from "./AccessTierInstancesTab.component"
import {
    AccessTierServicesTab,
    Api as AccessTierServicesTabApi,
} from "./AccessTierServicesTab.component"
import styles from "./AccessTierDetails.module.scss"

interface Props {
    enableAccessTierGroups: boolean
}

export function AccessTierDetails(props: Props): JSX.Element {
    const history = useHistory()
    const params = useParams<{ id: string }>()
    const localization = useServiceLocalization()

    const tabPanelRef = React.useRef<TabPanelApi>(null)
    const testConnectionModalRef = React.useRef<ModalApi<void>>(null)
    const sendLogsModalRef = React.useRef<ModalApi<void>>(null)
    const deleteAccessTierModalRef = React.useRef<ModalApi<void>>(null)
    const errorToastRef = React.useRef<ToastApi>(null)

    const [isEditMode, setIsEditMode] = React.useState(false)
    const [currentTab, setCurrentTab] = React.useState(Tab.CONFIGURATION)

    const {
        data: accessTier,
        refetch: refetchAccessTier,
        status: accessTierStatus,
        error: accessTierError,
    } = useGetAccessTierById(decodeID(params.id), props.enableAccessTierGroups)

    const { mutateAsync: deleteAccessTier } = useDeleteAccessTier({
        onSuccess: () => history.push(ROUTE.ACCESS_TIERS),
        onError: (error) =>
            typeof error === "string"
                ? errorToastRef.current?.openToast(error)
                : console.error(error),
    })

    const {
        data: featureFlags,
        refetch: refetchFeatureFlags,
        status: featureFlagsStatus,
    } = useFeatureFlags()

    const refetch = () =>
        Promise.all([refetchAccessTier(), refetchFeatureFlags(), tabPanelRef.current?.refresh()])

    if (accessTierStatus === "error" || featureFlagsStatus === "error") {
        return (
            <Page
                heading={localization.getString("accessTier")}
                headerRight={<RefreshButton onRefresh={refetch} />}
            >
                <Banner label={String(accessTierError)} variant={Variant.ERROR} />
            </Page>
        )
    }

    if (accessTierStatus === "loading" || featureFlagsStatus === "loading") {
        return (
            <Loader
                center
                medium
                title={localization.getString(
                    "loadingSomething",
                    localization.getString("accessTier")
                )}
            />
        )
    }

    const onTestConnection: React.MouseEventHandler = (e) => {
        e.preventDefault()
        testConnectionModalRef.current?.open()
    }

    const onCloseTestConnectionModal: React.MouseEventHandler = (e) => {
        e.preventDefault()
        testConnectionModalRef.current?.dismiss()
    }

    const onSendLogs: React.MouseEventHandler = (e) => {
        e.preventDefault()
        sendLogsModalRef.current?.open()
    }

    const onEdit: React.MouseEventHandler = (e) => {
        e.preventDefault()
        setCurrentTab(Tab.CONFIGURATION)
        setIsEditMode(true)
    }

    const onClose = () => setIsEditMode(false)

    const onOpenDeleteModal: React.MouseEventHandler = (e) => {
        e.preventDefault()
        deleteAccessTierModalRef.current?.open()
    }

    const mapTab = (tab: Tab): TabProps<Tab> => ({
        label: localization.getString(tab),
        id: tab,
        ariaControls: tabPanelId(tab),
    })

    const onDelete = async () => await deleteAccessTier(accessTier)

    const locale = localization.getLocale()

    const testConnectionLabel = localization.getString("testConnection")
    const sendLogsLabel = localization.getString("sendLogsToSonicWallCseSupport")
    const editLabel = localization.getString("edit")
    const deleteLabel = localization.getString("delete")

    const isAccessTierReporting = accessTier.status === Status.REPORTING

    const statusItems: KeyValuePairItems[] = [
        {
            label: localization.getString("accessTierName"),
            value: accessTier.name,
        },
        {
            label: localization.getString("clusterName"),
            value: accessTier.cluster.name,
        },
    ]

    const listItems: KeyValuePairItems[] = [
        {
            label: localization.getString("lastUpdated"),
            value: accessTier.lastUpdatedAt.toLocaleString(locale),
        },
        {
            label: localization.getString("lastUpdatedBy"),
            value: accessTier.lastUpdatedBy,
        },
        {
            label: localization.getString("createdAt"),
            value: accessTier.createdAt.toLocaleString(locale),
        },
        {
            label: localization.getString("createdBy"),
            value: accessTier.createdBy,
        },
    ]

    return (
        <Page
            heading={accessTier.name}
            headerRight={
                <div className={styles.actionButtons}>
                    <Tooltip title={testConnectionLabel}>
                        <Button
                            icon={IconType.CHECK}
                            onClick={onTestConnection}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            aria-label={testConnectionLabel}
                        />
                    </Tooltip>
                    <Tooltip title={sendLogsLabel}>
                        <Button
                            icon={IconType.FILE_EXPORT}
                            onClick={onSendLogs}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            aria-label={sendLogsLabel}
                        />
                    </Tooltip>
                    {featureFlags.adminConsole.canEditAccessTier &&
                        accessTier.edgeType === EdgeType.PRIVATE_EDGE && (
                            <Tooltip title={isEditMode ? "" : editLabel}>
                                <div>
                                    <Button
                                        icon={IconType.PEN}
                                        onClick={onEdit}
                                        asElement={ButtonElement.BUTTON}
                                        buttonType={ButtonType.SECONDARY}
                                        aria-label={editLabel}
                                        disabled={isEditMode}
                                    />
                                </div>
                            </Tooltip>
                        )}
                    {featureFlags.adminConsole.canDeleteAccessTier && (
                        <Tooltip
                            title={
                                isAccessTierReporting
                                    ? localization.getString("cannotDeleteAccessTierMsg")
                                    : deleteLabel
                            }
                        >
                            <div>
                                <Button
                                    icon={IconType.TRASH}
                                    onClick={onOpenDeleteModal}
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.SECONDARY}
                                    aria-label={deleteLabel}
                                    disabled={isAccessTierReporting}
                                />
                            </div>
                        </Tooltip>
                    )}
                    <RefreshButton onRefresh={refetch} />
                </div>
            }
        >
            <OverviewTopContainer
                statusType={getStatusType(statusTypeDict[accessTier.status])}
                statusLabel={localization.getString(statusLabelDict[accessTier.status])}
                statusItems={statusItems}
                listItems={listItems}
            />
            <TabBar
                tabs={Object.values(Tab).map(mapTab)}
                selectedTabId={currentTab}
                onChange={setCurrentTab}
            />
            <TabPanel
                ref={tabPanelRef}
                accessTier={accessTier}
                currentTab={currentTab}
                isEditMode={isEditMode}
                canAccessAccessTierGroups={props.enableAccessTierGroups}
                onClose={onClose}
                canAccessAccessTierInstallStep={
                    featureFlags.adminConsole.canAccessAccessTierInstallStep
                }
            />
            <TestConnectionModal
                ref={testConnectionModalRef}
                accessTier={accessTier}
                footer={
                    <Button
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.PRIMARY}
                        onClick={onCloseTestConnectionModal}
                    >
                        {localization.getString("done")}
                    </Button>
                }
            />
            <SendLogsModal accessTier={accessTier} ref={sendLogsModalRef} />
            <ConfirmationDialog
                id={Id.DELETE_ACCESS_TIER_DIALOG}
                title={localization.getString(
                    "deleteSomething",
                    localization.getString("accessTier")
                )}
                confirmButtonLabel={localization.getString("delete")}
                onConfirm={onDelete}
                isDestructive
                ref={deleteAccessTierModalRef}
            >
                {localization.getString("deleteAccessTierDescription")}
            </ConfirmationDialog>
            <ErrorToast ref={errorToastRef} />
        </Page>
    )
}

interface PageProps {
    heading: string
    headerRight?: React.ReactNode
    children?: React.ReactNode
}

function Page(props: PageProps): JSX.Element {
    return (
        <Container as="section" aria-labelledby={Id.HEADING} className={styles.container}>
            <header className={styles.header}>
                <PageHeading id={Id.HEADING}>{props.heading}</PageHeading>
                {props.headerRight}
            </header>
            {props.children}
        </Container>
    )
}

interface RefreshButtonProps {
    onRefresh(): void
}

function RefreshButton(props: RefreshButtonProps): JSX.Element {
    const localization = useServiceLocalization()

    const onRefresh: React.MouseEventHandler = (e) => {
        e.preventDefault()
        props.onRefresh()
    }

    const refreshLabel = localization.getString("refresh")

    return (
        <Tooltip title={refreshLabel}>
            <Button
                icon={IconType.REDO}
                onClick={onRefresh}
                asElement={ButtonElement.BUTTON}
                buttonType={ButtonType.SECONDARY}
                aria-label={refreshLabel}
            />
        </Tooltip>
    )
}

interface TabPanelProps {
    accessTier: AccessTier
    currentTab: Tab
    isEditMode: boolean
    canAccessAccessTierGroups: boolean
    canAccessAccessTierInstallStep: boolean
    onClose(): void
}

interface TabPanelApi {
    refresh(): Promise<void>
}

const TabPanel = React.forwardRef<TabPanelApi, TabPanelProps>((props, ref) => {
    const accessTierServicesTabRef = React.useRef<AccessTierServicesTabApi>(null)

    React.useImperativeHandle(ref, () => ({
        refresh: async () => {
            await accessTierServicesTabRef.current?.refresh()
        },
    }))

    switch (props.currentTab) {
        case Tab.CONFIGURATION:
            return (
                <AccessTierConfigurationTab
                    id={tabPanelId(props.currentTab)}
                    accessTier={props.accessTier}
                    isEditMode={props.isEditMode}
                    canAccessAccessTierGroups={props.canAccessAccessTierGroups}
                    onClose={props.onClose}
                />
            )

        case Tab.INSTANCES:
            return (
                <AccessTierInstancesTab
                    id={tabPanelId(props.currentTab)}
                    accessTier={props.accessTier}
                />
            )

        case Tab.SERVICES:
            return (
                <AccessTierServicesTab
                    id={tabPanelId(props.currentTab)}
                    ref={accessTierServicesTabRef}
                    accessTier={props.accessTier}
                />
            )

        case Tab.INSTALL:
            return (
                <AccessTierInstallTab
                    id={tabPanelId(props.currentTab)}
                    accessTier={props.accessTier}
                    canAccessAccessTierInstallStep={props.canAccessAccessTierInstallStep}
                />
            )
    }
})

TabPanel.displayName = "TabPanel"

enum Id {
    HEADING = "heading",
    DELETE_ACCESS_TIER_DIALOG = "deleteAccessTierDialog",
}

enum Tab {
    CONFIGURATION = "configuration",
    INSTANCES = "instances",
    SERVICES = "services",
    INSTALL = "install",
}

function tabPanelId(tab: Tab): string {
    return `tab-panel-${tab}`
}

const ConfirmationDialog = React.forwardRef(ConfirmationModalWithoutRef)
