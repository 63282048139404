import React from "react"

interface RefCollectionResult<Value, Key = string> {
    getAllRefContents(): Value[]
    getRef(key: Key): React.RefObject<Value>
}

export function useRefCollection<Value, Key = string>(): RefCollectionResult<Value, Key> {
    const { current: collection } = React.useRef<Map<Key, React.RefObject<Value>>>(new Map())

    const getAllRefContents = React.useCallback<
        RefCollectionResult<Value, Key>["getAllRefContents"]
    >(
        () =>
            Array.from(collection.values())
                .map((refObject) => refObject?.current)
                .filter((value): value is Value => value !== null),
        [collection]
    )

    const getRef = React.useCallback<RefCollectionResult<Value, Key>["getRef"]>(
        (key: Key) => {
            const refObject = collection.get(key)

            if (refObject) return refObject

            const newRef = React.createRef<Value>()

            collection.set(key, newRef)

            return newRef
        },
        [collection]
    )

    return {
        getAllRefContents,
        getRef,
    }
}
