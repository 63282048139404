import classnames from "classnames/bind"
import * as qs from "query-string"
import React from "react"
import { useHistory, useLocation } from "react-router-dom"

import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { useAlphaSort } from "../../../../hooks/useAlphaSort.hook"
import { ROUTE } from "../../../../routes"
import { PolicyEditDeleteDialog } from "../../../../v3/views/policy/form/delete-dialog/PolicyEditDeleteDialog.component"
import {
    PolicyAttr,
    PolicyMetadata,
    PolicyServiceTypeRes,
    PolicySpec,
} from "../../../api/Secure.api"
import { useServiceLocalization } from "../../../services/localization/Localization.service"
import {
    PolicySecure,
    PolicyType,
    RoleSecure,
    RoleType,
    useServiceSecure,
} from "../../../services/Secure.service"
import {
    Form,
    FormLabel,
    FormRow,
    ErrorBanner,
    FormSection,
    PageBreak,
    FormColumns,
    Tooltip,
} from "../../../components"
import { WebPolicyForm } from "./WebPolicyForm"
import { TunnelPolicyForm } from "./TunnelPolicyForm"
import { CustomPolicyForm } from "./CustomPolicyForm"
import { TcpPolicyForm } from "./TcpPolicyForm"
import styles from "./PolicyForm.module.scss"
import { Input } from "../../../../v3/components/input/Input.component"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"

interface Props {
    edit?: PolicySecure
    onSubmit: (data: {
        type: PolicyType
        attr: PolicyAttr
        metadata: PolicyMetadata
    }) => void | Promise<void>
    onCancel?: () => void
    submitText?: string
    cancelText?: string
    disabled?: boolean
    className?: string
}

export function PolicyForm({
    edit,
    onSubmit,
    submitText,
    cancelText,
    onCancel,
    disabled,
    className,
}: Props) {
    // grab the service references
    const localization = useServiceLocalization()
    const secureService = useServiceSecure()

    // grab route information
    const location = useLocation()
    const history = useHistory()

    // component state
    const [loading, setLoading] = React.useState(true)
    const [error, setError] = React.useState<string | null>(null)
    const [roles, setRoles] = React.useState<string[]>([])
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false)

    // form state
    const [name, setName] = React.useState(edit?.name || "")
    const [description, setDescription] = React.useState(edit?.description || "")
    const [attr, setAttr] = React.useState(secureService.getNullPolicyAttr())

    // pull the policy type and subtype from the url
    const { policyType, subType } = React.useMemo(() => {
        // if there is an edit target, use its type and subtype
        if (edit) {
            const parsed = JSON.parse(edit.spec) as PolicySpec

            // the policy type is set in the template tag
            const policyType = parsed.metadata.tags.template as PolicyType

            let subType = PolicyServiceTypeRes.TUNNEL
            const hasL4AccessRule: boolean = JSON.stringify(parsed.spec.access).includes(
                "l4_access"
            )
            if (parsed.spec.options) {
                if (parsed.spec.options.l7_protocol === "http") {
                    subType = PolicyServiceTypeRes.WEB
                } else {
                    if (hasL4AccessRule) {
                        subType = PolicyServiceTypeRes.TUNNEL
                    } else {
                        subType = PolicyServiceTypeRes.TCP
                    }
                }
            }

            return {
                policyType,
                subType,
            }
        }

        const qsObj = qs.parse(location.search)

        // pull the type of policy from the url
        let qsPolicyType = qsObj.type as PolicyType
        if (!PolicyType[qsPolicyType]) {
            qsPolicyType = PolicyType.CUSTOM
        }

        return {
            policyType: qsPolicyType,
            subType: qsObj.subtype as PolicyServiceTypeRes,
        }
    }, [location.search, edit])

    const alphaSort = useAlphaSort({ ignoreCase: true })

    // load the data
    React.useEffect(() => {
        secureService
            .getRoles()
            .then((roles: RoleSecure[]) => {
                if (policyType) {
                    roles = roles.filter((r) => r.type === (policyType as unknown as RoleType))
                }
                const roleNames: string[] = roles.map((r) => r.name).sort(alphaSort)
                roleNames.unshift("ANY")
                setRoles(roleNames)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [secureService, policyType])

    // the function to validate the policy data
    const validatePolicy = useValidatePolicy()

    // the function to call when the form is submitted
    const submitForm = async () => {
        setLoading(true)
        // build up the meta data object
        const metadata: PolicyMetadata = {
            name,
            description,
            tags: {
                template: policyType,
            },
        }

        // make sure all of the information is correct
        const error = validatePolicy({ type: policyType, attr, subType, metadata })
        if (error) {
            setError(error)
            return
        }

        // trigger the submit handler
        try {
            await onSubmit({ type: policyType, attr, metadata })
        } catch (e) {
            setError((e as Error).message)
        }

        setLoading(false)
    }

    // the function to close the delete dialog
    const closeDeleteDialog = (success: boolean): void => {
        if (success) {
            history.push(ROUTE.ACCESS_POLICIES)
        } else {
            setDeleteDialogOpen(false)
        }
    }

    // a reference to the submit button
    const submitButton = React.useRef<HTMLButtonElement | null>(
        null
    ) as React.MutableRefObject<HTMLButtonElement | null>

    // compute the title based on the policy type/subtype
    let title = localization.getString("customPolicyJson")
    if (policyType === PolicyType.WORKLOAD) {
        title = localization.getString("basicAuthorizationPolicyForWorkloads")
    } else if (policyType === PolicyType.USER) {
        if (subType === PolicyServiceTypeRes.WEB) {
            title = localization.getString("webPolicy")
        } else if (subType === PolicyServiceTypeRes.TCP) {
            title = localization.getString("infrastructurePolicy")
        } else if (subType === PolicyServiceTypeRes.TUNNEL) {
            title = localization.getString("tunnelPolicy")
        }
    }

    return (
        <div className={classnames(styles.container, className)}>
            {!disabled && (
                <header className={styles.header}>
                    <PageHeading>{edit ? edit.name : title}</PageHeading>
                </header>
            )}
            <Form display="grid" labelWidth={150} onSubmit={submitForm}>
                {!disabled && (
                    <FormSection
                        title={localization.getString("policyDetails")}
                        className={classnames(styles.attributeContainer, {
                            [styles.slimSection]: disabled,
                        })}
                        hideTitle={disabled}
                    >
                        <button
                            ref={(c) => (submitButton.current = c)}
                            type="submit"
                            className={styles.hiddenSubmitButton}
                        />
                        <FormLabel
                            title={localization.getString("policyName")}
                            htmlFor={Id.NAME}
                            slim
                        >
                            <Input
                                id={Id.NAME}
                                className={styles.formInput}
                                required
                                placeholder={localization.getString("policyName")}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                disabled={!!edit}
                            />
                        </FormLabel>
                        <FormLabel
                            title={localization.getString(
                                "somethingOptional",
                                localization.getString("description")
                            )}
                            htmlFor={Id.DESCRIPTION}
                            slim
                        >
                            <Input
                                id={Id.DESCRIPTION}
                                className={styles.formInput}
                                placeholder={localization.getString("description")}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </FormLabel>
                        {edit && (
                            <FormColumns
                                right={
                                    edit.attachments.length > 0 ? (
                                        <Tooltip
                                            title={localization.getString(
                                                "policyDeleteDisabledDescription"
                                            )}
                                            placement="right"
                                        >
                                            <span>
                                                <Button
                                                    disabled
                                                    buttonType={ButtonType.PRIMARY}
                                                    asElement={ButtonElement.BUTTON}
                                                >
                                                    {localization.getString("delete")}
                                                </Button>
                                            </span>
                                        </Tooltip>
                                    ) : (
                                        <Button
                                            onClick={() => setDeleteDialogOpen(true)}
                                            buttonType={ButtonType.PRIMARY}
                                            asElement={ButtonElement.BUTTON}
                                            type="button"
                                        >
                                            {localization.getString("delete")}
                                        </Button>
                                    )
                                }
                            />
                        )}
                    </FormSection>
                )}
                <FormSection
                    title={localization.getString("policyDefinition")}
                    className={classnames(styles.attributeContainer, {
                        [styles.slimSection]: disabled,
                    })}
                    hideTitle={disabled}
                >
                    {/* tunnel policies */}
                    {subType === PolicyServiceTypeRes.TUNNEL && (
                        <TunnelPolicyForm
                            setAttr={setAttr}
                            roles={roles}
                            edit={edit}
                            disabled={disabled}
                        />
                    )}
                    {/* web policies */}
                    {subType === PolicyServiceTypeRes.WEB &&
                        policyType !== PolicyType.CUSTOM &&
                        policyType !== PolicyType.WORKLOAD && (
                            <WebPolicyForm
                                roles={roles}
                                setAttr={setAttr}
                                setError={setError}
                                edit={edit}
                                loading={loading}
                                disabled={disabled}
                            />
                        )}
                    {/* web policies */}
                    {subType === PolicyServiceTypeRes.TCP &&
                        policyType !== PolicyType.CUSTOM &&
                        policyType !== PolicyType.WORKLOAD && (
                            <TcpPolicyForm
                                roles={roles}
                                setAttr={setAttr}
                                setError={setError}
                                edit={edit}
                                loading={loading}
                                disabled={disabled}
                            />
                        )}
                    {/* custom or workload policies */}
                    {(policyType === PolicyType.CUSTOM || policyType === PolicyType.WORKLOAD) && (
                        <CustomPolicyForm
                            setAttr={setAttr}
                            edit={edit}
                            disabled={disabled}
                            setError={setError}
                        />
                    )}
                </FormSection>
                <PageBreak />
                {error && (
                    <FormRow>
                        <ErrorBanner className={styles.policyAttribute}>{error}</ErrorBanner>
                    </FormRow>
                )}
                {!disabled && (
                    <FormRow className={styles.buttons}>
                        {onCancel && (
                            <Button
                                onClick={onCancel}
                                loading={loading}
                                className={styles.leftButton}
                                buttonType={ButtonType.SECONDARY}
                                asElement={ButtonElement.BUTTON}
                            >
                                {cancelText || localization.getString("cancel")}
                            </Button>
                        )}
                        <Button
                            onClick={() => submitButton.current?.click()}
                            loading={loading}
                            buttonType={ButtonType.PRIMARY}
                            asElement={ButtonElement.BUTTON}
                        >
                            {submitText || localization.getString("submit")}
                        </Button>
                    </FormRow>
                )}
            </Form>

            {edit && (
                <PolicyEditDeleteDialog
                    policy={edit}
                    open={deleteDialogOpen}
                    onClose={closeDeleteDialog}
                />
            )}
        </div>
    )
}

function useValidatePolicy() {
    const localization = useServiceLocalization()

    return ({
        type,
        attr,
        metadata,
        subType,
    }: {
        type: PolicyType
        attr: PolicyAttr
        metadata: PolicyMetadata
        subType: PolicyServiceTypeRes
    }): string | null => {
        if (type === PolicyType.USER) {
            if (attr.access[0].roles.length < 1) {
                return localization.getString("aRoleIsRequired")
            }
            if (subType !== PolicyServiceTypeRes.TUNNEL && !attr.options) {
                return localization.getString("aServiceTypeIsRequired")
            }
            if (!attr.access[0].rules.conditions) {
                return localization.getString("aTrustLevelIsRequired")
            }
        } else if (type === PolicyType.WORKLOAD) {
            if (!Array.isArray(attr.access[0].roles)) {
                return localization.getString("aRoleIsRequired")
            }
        }

        return null
    }
}

enum Id {
    NAME = "name",
    DESCRIPTION = "description",
}
